import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
// import Navbar from '../../navbar/navbar';

//images
// import Video from '../../../assets/images/icons/Video.png';
// import Students from '../../../assets/images/icons/students.png';
// import Discussion from '../../../assets/images/icons/Discussion.png';

import bannerImage from '../../images/examBanner.jpg';


class ExamBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { exam } = this.props;
        return (
            <div className="course-dhashboard" style={{ backgroundImage: `url('${bannerImage}'), linear-gradient(to right, #00A9E0, #00A9E0)`, backgroundPosition: "right top", backgroundRepeat: "no-repeat", minHeight: "140px", maxHeight: "140px", backgroundSize: '100%', }}>
                <div className="uk-grid">
                    <div className= {localStorage.LANG=='ar'? "examBanner_ar": "examBanner"  } >
                        <h3 className="uk-light  uk-text-bold uk-text-white" style={{textShadow: "rgb(39, 38, 52) 3px 3px 16px"}}> {exam.examName}</h3>
                        {/* <p className="uk-light uk-text-bold uk-text-white" style={{textShadow: "rgb(39, 38, 52) 3px 3px 16px"}}>{exam.description}</p> */}
                    </div>
                </div>
            </div>

            // <div className="course-dhashboard" style={{ backgroundImage: "linear-gradient(to left, #0166CA 65% ,#0166CA 66%,#0166CA 67%,#0166CA 68%,rgba(255,0,0,0) ),url(" + module.bannerIconUrl + ")", backgroundPosition: "left top", backgroundRepeat: "no-repeat", minHeight: "140px", maxHeight: "140px" }}>
            //     <div className="uk-grid">
            //         <div style={{ paddingLeft: "25%" }}>
            //             <h3 className="uk-light  uk-text-bold uk-text-white"> {module.moduleTitle}</h3>
            //             <p className="uk-light uk-text-bold">{module.moduleHelperText}</p>
            //         </div>
            //     </div>
            // </div>
        )
    }
}

export default ExamBanner; 