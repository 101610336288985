import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//queries
import { GET_TOOLS } from '../../../queries/admin_queries';


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ManageTools extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }
        this.state = {
            loading: false,
            pagination: {
                isActive: true,
                lang: "en",
            },
            tools: [],
        }
    }

    componentDidMount() {
        this.get_all_tools(this.state.pagination).then(result=>{
            console.log(result)
            this.setState({tools:result.tools, count:result.count})
        })
    }
    componentDidUpdate(){ window.scrollTo(0,0)}

    addNewTool() {
        this.props.history.push('/admin_add_tool');
    }

    editTool(id) {
        this.props.history.push('/admin_add_tool?id=' + id);
    }

    get_all_tools = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_TOOLS,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        return result.data.getTools;
    };



    render() {
        const { tools, loading} = this.state;
        return (
            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-tools fa-2x uk-margin-right"></i>
                    {/* icon-large  */}
                    <h4 className="uk-margin-remove adminSectionTitles"> Tools </h4>

                </div>
                <div className="uk-flex-inline uk-float-right">
                    <button className="uk-button uk-button-primary admin-btn" onClick={() => this.addNewTool()}>Add Tool</button>
                </div>
              <br />
              <br />

                <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                        <thead>
                            <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                                {/* <th>Num</th> */}
                                <th style={{ width: "35%" }}>Tool Image</th>
                                <th style={{ width: "30%" }}>Title</th>
                                <th style={{ width: "15%" }}>Link</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tools.map((tool, i) => (
                                    <tr key={i}>
                                        {/* <td> { i+ 1 } </td> */}
                                        <td> <img src={tool.image} />  </td>
                                        <td> {tool.title}  </td>
                                        <td> {tool.url? <a onClick={() => window.open(tool.url) }>View</a> : null } </td>
                                        <td >
                                            <button disabled={loading ? (true) : (false)} className="uk-button uk-button-warning admin-table-btn" uk-tooltip="Edit" onClick={() => this.editTool(tool.id)} ><i class='fas fa-pencil-alt'></i></button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ManageTools))); 