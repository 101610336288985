import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus } from "../../actions/user-actions";
import { Form, Message } from "semantic-ui-react";
//ui-kit components

import { Button } from "react-uikit";

//Spinner
import Loader from "react-loader-spinner";
import { UPDATE_EXAM } from "../../queries/admin_queries";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
  };
};

class sblDeactivateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: this.props.show,
    };
    props.setFormStatus({ status: false, title: "", message: "" });
  }
  

  updateExam = async (id, status) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_EXAM,
      variables: {
        examId: this.props.id,
        lang: "en",
        isActive: status,
      },
    });
    console.log(result.data);
    return result.data.updateExam;
  };

  changeStatus = (id, status) => {
    console.log(id, status);
    this.updateExam(id, status).then((result) => {
      console.log(result);
      this.get_all_exams().then((result) => {
        if (result.length > 0) {
          this.setState({
            exams: result,
          });
        }
      });
    });
  };

  closeModal = () => {
    this.setState({showModal: false});
  }

  render() {
    const { id, show } = this.props;

    // console.log(formErrorStatus)

    return (
      <div>
        {show === true ? (
          <React.Fragment>
            <div style={styles.background}>
              <div style={styles.background1}>
                Are you sure you want to deactivate this SBL?
                {id}
                <br />
                <br />
                <Button
                  className="uk-button submitFeedbackButton1"
                  type="button"
                  style={styles.confirmButton}
                  // //   disabled={rating === ""}
                  onClick={() => this.closeModal()}
                >
                  Confirm delete
                </Button>
                <Button
                  className="uk-button uk-button-primary submitFeedbackButton"
                  type="button"
                  style={styles.cancelButton}
                  // disabled={rating === ""}
                  // onClick={() => this.handleReviewSubmit()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
const styles = {
  background: {
    backgroundColor: "#0000005e",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  background1: {
    backgroundColor: "white",
    position: "absolute",
    // top: '0',
    right: "0",
    width: "40%",
    padding: "1rem",
    zindex: "100",
    marginRight: "20%",
    marginTop: "15%",
    borderRadius: "5px",
    textAlign: "center",
  },

  cancelButton: {
    width: "7rem",
    float: "right",
    marginRight: "0.8rem",
  },

  confirmButton: {
    width: "10rem",
    float: "right",
  },
};
export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(sblDeactivateModal))
);
