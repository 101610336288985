import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
// import ReactStars from "react-rating-stars-component";
import Button from "react-uikit/lib/components/Button";
import { setModalStatus } from "../../actions/user-actions";
import {
  CREATE_USER_RATING,
  UPDATE_USER_RATING,
} from "../../queries/user_queries";
import { _generate_email } from "../../middleware/index";

//generics
import {
  ippfauthtoken,
  USER_ID,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  USER_TYPE,
} from "../../constants/genarics";
import { GET_USER_RATINGS } from "../../queries/common_queries";
import { withNamespaces } from "react-i18next";


const mapStateToProps = (state, ownProps) => {
  return {
    ratingModalOpen: state.custom_reducer.ratingModalOpen,
    ratingModalType: state.custom_reducer.ratingModalType,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setModalStatus: (status) => {
      dispatch(setModalStatus(status));
    },
  };
};

class RatingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: "",
      color: "",
      modalEventType: "",
      commentText: "",
      openSecondModal: false,
      ratingEnglish: ""
    };
  }

  componentDidMount() {
    this.getUserRatings().then((result) => {
      console.log(result);
      // this.setState({
      //   openSecondModal: true,
      // });
      // this.props.setModalStatus(false);
    });
  }

  createUserRating = async () => {
    const result = await this.props.client.mutate({
      mutation: CREATE_USER_RATING,
      variables: {
        rating: this.state.ratingEnglish,
        review: this.state.commentText,
      },
    });
    console.log(result.data);
    return result.data.createUserRating;
  };

  getUserRatings = async () => {
    const result = await this.props.client.mutate({
      mutation: GET_USER_RATINGS,
      variables: {
        createdBy: localStorage.USER_ID,
      },
    });
    console.log(result.data);
    return result.data.getUserRatings;
  };

  updateUserRating = async (id) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_USER_RATING,
      variables: {
        ratingId: id,
        rating: this.state.ratingEnglish,
        review: this.state.commentText,
      },
    });
    console.log(result.data);
    return result.data.updateUserRating;
  };

  getRating = (rating, color, ratingEnglish) => {
    console.log(rating);
    this.setState({
      rating: rating,
      color: color,
      ratingEnglish: ratingEnglish
    });
    // document.ratingForm.commentText.focus();
  };

  handleNotNow = () => {
    this.props.setModalStatus(false);
    if (this.props.ratingModalType === "logout") {
      setTimeout(() => {
        localStorage.removeItem(ippfauthtoken);
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(EMAIL);
        localStorage.removeItem(FIRST_NAME);
        localStorage.removeItem(LAST_NAME);
        localStorage.removeItem(USER_TYPE);
        {/* login route changed */}
        this.props.history.push("/");
      }, 500);
    }
    else{
      localStorage.RATING_LATER = true;
      localStorage.RATING_POPPED = 'popped';
    }
  };

  handleReviewSubmit = () => {
    this.getUserRatings().then((result) => {
      if (result.userRatings.length > 0) {
        this.updateUserRating(result.userRatings[0].id).then((result) => {
          this.setState({
            openSecondModal: true,
          });
          // this.props.setModalStatus(false);
        });
      } else {
        this.createUserRating().then((result) => {
          console.log(result);
          this.setState({
            openSecondModal: true,
          });

          const rating = result.rating;
          const review = result.review != "" ? result.review : "-";
          const user = result.user.firstName + " " + result.user.lastName;

          const personalizations = {
            personalizations: [
              {
                to: [
                  {
                    email: "rishi@fpasrilanka.org",
                    name: "Rishikeshan Thiyagaraja",
                  },
                  {
                    email: "manjula@fpasrilanka.org",
                    name: "Manjula Chathuranga Liyanage",
                  },
                  {
                    email: "narthana.se@gmail.com",
                    name: "Narthana Selvachothy",
                  },
                ],
                dynamic_template_data: {
                  comment: review,
                  rating: rating,
                  userName: user,
                  url: "http://seippf.org/admin_user_feedback"
                },
                subject: "Social Enterprise Academy – Feedback received",
              },
            ],
            template_id: "d-9c5e7be03ba6477e8971d0e9fb5bae98",
          };
          try {
            const data = _generate_email(
              " https://seippf.org/mail/mail.php ",
              personalizations
            );
            console.log(data);
          } catch (error) {
            console.error(error);
          }
        });
      }
    });
  };

  handleChange = (e) => {
    this.setState({
      commentText: e.target.value,
    });
  };

  render() {
    const { nextSubModuleDisabled, ratingModalOpen, t } = this.props;
    const { rating, color, commentText, openSecondModal } = this.state;
    console.log(localStorage.LANG)
    return (
      <div className="ratingsModalDiv">
        <div
          className="ratingsModal1"
          style={{ display: openSecondModal != true ? "none" : "block" }}
        >
          <div className="ratingsModalHeader">{t('rating_msg')}</div>
          {this.props.ratingModalType != "logout" ? (
            <Button
              className="uk-button uk-button-primary"
              style={{ marginTop: "2rem" }}
              type="button"
              disabled={rating === ""}
              onClick={() => this.handleNotNow()}
            >
             {t('close')}
            </Button>
          ) : (
            <Button
              className="uk-button uk-button-primary"
              style={{ marginTop: "2rem" }}
              type="button"
              disabled={rating === ""}
              onClick={() => this.handleNotNow()}
            >
              {t('logout')}
            </Button>
          )}
        </div>
        <form
          name="ratingForm"
          style={{ display: openSecondModal === true ? "none" : "block" }}
        >
          <div className="ratingsModal">
            <div className="ratingsModalHeader">
             {t('rate_title')}
            </div>
            <div className="ratingsModalStarsDiv">
              <fieldset class={localStorage.LANG=='ar'? "rating ratingAr": "rating" }  >
                <input
                  type="radio"
                  id="star5"
                  name="rating"
                  value="5"
                  onClick={() => this.getRating(t('excellent'), "#0eab00", "Excellent")}
                />
                <label
                  class="full"
                  for="star5"
                  uk-tooltip={`title: ${t('excellent')}; delay: 300; pos: top; animation: uk-animation-scale-up`}
                ></label>
                <input
                  type="radio"
                  id="star4"
                  name="rating"
                  value="4"
                  onClick={() => this.getRating(t('very_good'), "#00ab9a", "Very good")}
                />
                <label
                  class="full"
                  for="star4"
                  uk-tooltip={`title: ${t('very_good')}; delay: 300; pos: top; animation: uk-animation-scale-up`}
                ></label>
                <input
                  type="radio"
                  id="star3"
                  name="rating"
                  value="3"
                  onClick={() => this.getRating(t('good'), "#0080ab", "Good")}
                />
                <label
                  class="full"
                  for="star3"
                  uk-tooltip={`title: ${t('good')}; delay: 300; pos: top; animation: uk-animation-scale-up`}
                ></label>
                <input
                  type="radio"
                  id="star2"
                  name="rating"
                  value="2"
                  onClick={() => this.getRating(t('fair'), "#ebb000", "Fair")}
                />
                <label
                  class="full"
                  for="star2"
                  uk-tooltip={`title: ${t('fair')}; delay: 300; pos: top; animation: uk-animation-scale-up`}
                ></label>
                <input
                  type="radio"
                  id="star1"
                  name="rating"
                  value="1"
                  onClick={() => this.getRating(t('need_improvement'), "#e36200", "Needs improvement")}
                />
                <label
                  class="full"
                  for="star1"
                  uk-tooltip={`title:${t('need_improvement')}; delay: 300; pos: top; animation: uk-animation-scale-up`}
                ></label>
              </fieldset>
              <br />
              <text className="ratingsModalRating" style={{ color: color }}>
                {this.state.rating}
              </text>
            </div>
            <textarea
              value={commentText || ""}
              className="uk-textarea commentTextArea"
              rows="3"
              id="commentText"
              name="commentText"
              disabled={rating === ""}
              onChange={(e) => this.handleChange(e)}
              placeholder={t('type_feedback')}
            ></textarea>
            <Button
              className="uk-button submitFeedbackButton1"
              type="button"
              //   disabled={rating === ""}
              onClick={() => this.handleNotNow()}
            >
              {t('later')}
            </Button>
            <Button
              className="uk-button uk-button-primary submitFeedbackButton"
              type="button"
              disabled={rating === ""}
              onClick={() => this.handleReviewSubmit()}
            >
              {t('submit')}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withApollo(withNamespaces()(RatingModal)))
);
