import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { Image } from 'semantic-ui-react';
import LogoEncyte from '../../assets/images/logo.png'
import { isLoggedIn } from "../../services/CacheOperations";
import english from '../../languages/en.json'
import CloseIcon from '../../images/close.png';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom'
import { withNamespaces } from 'react-i18next';

import logo1 from '../../images/1_SE_IPPF_logo_1.png';
import logo2 from '../../images/2_SE_IPPF_logo_old_edited_color.png';

// var __html = require('../../components/sitemap/sitemap.html');
// var template = { __html: __html };

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blurrModalOpen: false,
        };

    }
    navigate = (path) => {
        console.log(path);
        this.props.history.push(path);
    }

    openSiteMap = (status) => {
        this.setState({ blurrModalOpen: status })
    }

    render() {
        const { t } = this.props;
        const { blurrModalOpen } = this.state;
        console.log(this.props.language)
        return (
            <div >
                <div className="foot fontFamily uk-margin-medium-top" >
                    {/* uk-section-small */}
                    <hr className="uk-margin-remove" />
                    <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative">
                        {this.props.language=="ar"? (
                            <div className="uk-grid" >
                           
                            <div className="uk-width-expand@m uk-width-1-1@s uk-flex  uk-flex-left">
                                <ul style={{ fontSize: "14px" }} className="uk-list tm-footer-list">
                                    <li>
                                        {/* login route changed */}
                                        <a target="_blank" href="/#aboutus_section">
                                            {t("about")}
                                        </a>
                                    </li>
                                    <li>
                                  
                                        <a name="manual" 
                                        href="https://seippf.org/userguide/ar" 
                                        // href="https://seippf.org/userguide/"
                                        target="_blank"> {t("user_manual")}  </a>
                                    </li>
                                    {/* {isLoggedIn?( */}
                                    <li>
                                        <a
                                            name="sitemap"
                                        >
                                            <div onClick={() => this.openSiteMap(true)}>
                                                {t("site_map")}
                                            </div>
                                        </a>
                                    </li>
                                    {/* ):("")} */}
                                    <li>
                                        <a href="/contact" target="_blank">
                                            {t("contact")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://drive.google.com/file/d/1bVElETZIbuqv0IsQ-ULbltCh7xaLblqQ/view?usp=sharing" target="_blank">
                                            {t("privacy")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="uk-width-2-3@m uk-width-1-2@s uk-first-column uk-margin-auto" uk-height-match="target: SELECTOR" >
                                <p style={{float:"left"}} className="uk-link-heading uk-text-lead uk-text-bold">
                                    {/* <Image style={{float:"left"}} className="ui fluid image uk-display-inline-block ippf-logo" src={require('../../images/IPPFC&FxSocialEnterprise_CMYK.jpg')} fluid /> */}
                               {/* //added on 3rd Feb 2022 */}
                                    <Image style={{marginTop:"14.5px"}} className="ui fluid image uk-display-inline-block ippf-logo" 
                                    // src={require('../../images/1_SE_IPPF_logo_1.png')} 
                                    src={logo1}
                                    fluid 
                                    />
                                    <Image style={{float:"right", paddingBottom:"2px"}} className="ui fluid image uk-display-inline-block ippf-logo" 
                                    // src={require('../../images/2_SE_IPPF_logo_old_edited_color.png')}
                                    src={logo2}
                                     fluid />
                    
                                </p>
                            </div>
                        </div>
                        ):(
                            <div className="uk-grid" >
                            <div className="uk-width-2-3@m uk-width-1-2@s uk-first-column uk-margin-auto" uk-height-match="target: SELECTOR" >
                                <p  className="uk-link-heading uk-text-lead uk-text-bold">
                                    
                                    <Image  className="ui fluid image uk-display-inline-block ippf-logo" 
                                    // src={require('../../images/1_SE_IPPF_logo_1.png')}
                                    src={logo1}
                                     fluid />
                                    <Image  float="right" className="ui fluid image uk-display-inline-block ippf-logo"
                                    //  src={require('../../images/2_SE_IPPF_logo_old_edited_color.png')} 
                                    src={logo2}
                                     fluid />
                    
                                </p>
                            </div>
                            <div className="uk-width-expand@m uk-width-1-1@s uk-flex  uk-flex-right">
                                <ul style={{ fontSize: "14px" }} className="uk-list tm-footer-list">
                                    <li>
                                        {/* login route changed */}
                                        <a target="_blank" href="/#aboutus_section">
                                            {t("about")}
                                        </a>
                                    </li>
                                    <li>
                                        <a name="manual" 
                                        href={localStorage.LANG=="fr"? "https://seippf.org/userguide/fr" : localStorage.LANG=="sp"? "https://seippf.org/userguide/es" : "https://seippf.org/userguide/en" } 
                                        // href="https://seippf.org/userguide/"
                                        target="_blank"> {t("user_manual")}  </a>
                                    </li>
                                    {/* {isLoggedIn?( */}
                                    <li>
                                        <a
                                            name="sitemap"
                                        >
                                            <div onClick={() => this.openSiteMap(true)}>
                                                {t("site_map")}
                                            </div>
                                        </a>
                                    </li>
                                    {/* ):("")} */}
                                    <li>
                                        <a href="/contact" target="_blank">
                                            {t("contact")}
                                        </a>
                                    </li>
                                    <li>
                                    {/* href="https://www.ippf.org/privacy" */}
                                        <a  href= {localStorage.LANG=='sp'? "https://drive.google.com/file/d/17MF3-PtiGTQH03wriceSxx6jJc5mb-mt/view?usp=sharing": localStorage.LANG=='fr'? "https://drive.google.com/file/d/1BHAd9w7PAU4ZCXFHAh2mqEB7JxjdI_A9/view?usp=sharing": "https://drive.google.com/file/d/1t56KrWgscRbhCW7Q4PZPn8cdqiKLuZDi/view?usp=sharing" }   target="_blank">
                                            {t("privacy")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        )}
                        
                    </div>
                    {/*                     
                    <p style={{textAlign:"center"}} className="uk-text-small uk-position-bottom-center  uk-width-2-2@s uk-width-2-2@xs"><i className="fas fa-copyright"></i> 2020 <span className="uk-text-bold">IPPF</span> . All rights reserved.</p>
                    
                    <p style={{textAlign:"center"}} className=" uk-position-bottom-center  uk-width-2-2@s uk-width-2-2@xs"
                        uk-tooltip="title: Visit Our Site; pos: top-center"> Powered By <a href="https://encyte.io" target="_blank" className="uk-text-bold uk-link-reset"> Encyte </a></p>
                 */}
                </div>
                <div className=" fontFamily footerCopyright uk-section-small" >
                    <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative" >
                    {localStorage.LANG=="ar"? (
                         <div style={{ fontSize: "14px" }} className="uk-grid uk-margin-small" uk-grid="true">
                             <div className="image uk-width-expand@m uk-width-1-2@s">
                             <p style={{ textAlign: "right" }}> {t("powered_by")} <a href="https://encyte.io" target="_blank"> <img src={LogoEncyte} /> </a></p>
                         </div>
                         <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                             <p> {t("version")} 1 . 3 . 0</p>
                             {/* <p> {t("version")} 1 . 1 . 0</p> */}

                         </div>
                         <div style={{ display: "flex" }} className="copyRightText uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                             {t("copyright")}
                             <div style={{ marginLeft: "3px", marginRight: "3px" }} className="uk-text-bold">{t("IPPF")}</div>
                             {t("All_rights_reserved")}
                         </div>   
                     </div>
                    ):(
                         <div style={{ fontSize: "14px" }} className="uk-grid uk-margin-small" uk-grid="true">
                         <div style={{ display: "flex" }} className={localStorage.LANG=="sp"? "copyRightText_spanish uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto" : "copyRightText uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto" } >
                             {t("copyright")}
                             <div style={{ marginLeft: "3px", marginRight: "3px" }} className="uk-text-bold">{t("IPPF")}</div>
                             {t("All_rights_reserved")}
                         </div>
                         <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                             <p> {t("version")} 1 . 3 . 0</p>
                             {/* <p> {t("version")} 1 . 1 . 0</p> */}

                         </div>
                         <div className="image uk-width-expand@m uk-width-1-2@s">
                             <p style={{ textAlign: "right" }}> {t("powered_by")} <a href="https://encyte.io" target="_blank"> <img src={LogoEncyte} /> </a></p>
                         </div>
                     </div>
                    )
                    }
                       
                    </div>
                </div>
                {blurrModalOpen === true ? (
                    <div style={styles.background}
                    // onClick={() => this.openSiteMap(false)}
                    >
                        <div style={styles.background1}>
                            <h3 className={localStorage.LANG=='ar'? "float": "" }>{t('title_site_map')}</h3>
                            <img onClick={() => this.openSiteMap(false)} className={localStorage.LANG=='ar'? " close_sitemap_ar leftFloat":"close_sitemap"}  src={CloseIcon} />
                            <br />
                            <br />
                            <div className="fitCenterDiv">
                                <InnerImageZoom src={localStorage.LANG=='ar'? require("../../images/sitemap_v_3_arabic.svg") : localStorage.LANG=='fr'? require("../../images/sitemap_v_3_french_v2.svg"): localStorage.LANG=='sp'? require("../../images/sitemap_v_3_spanish.svg") : require("../../images/sitemap_v_3.svg")} />
                                {/* <img style={{height: 'auto', width: '100%', margin: '0 auto'}} src={SiteMap} /> */}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withRouter(withApollo(withNamespaces()(Footer)));

const styles = {
    background: {
        backgroundColor: "#0000005e",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        paddingLeft: '2rem'
    },
    background1: {
        backgroundColor: "white",
        width: "90%",
        padding: "1rem",
        borderRadius: "5px",
        textAlign: "left",
        overflow: 'auto',
        margin: '0 auto',
        marginTop: '3.5rem'
    },
    cancelButton: {
        width: "7rem",
        float: "right",
        marginRight: "0.8rem",
    },

    confirmButton: {
        width: "7rem",
        float: "right",
    },
};