import * as React from "react";
import { renderButton, checkSignedIn } from "../../utilities/utils";
import SessionDuration from "./googleAnalytics/sessionDuration";
import AccessedUsers from "./googleAnalytics/accessedUsers";
import RepeatUsers from "./repeatUserStats";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
  return {};
};

class ExamCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
      loading: false,
    };
  }

  componentDidMount() {

    setTimeout(() => {
      window.gapi.load("auth2", this.init()); //(1)
    }, 2000);
    
  }

  stopLoading = () => {
    this.setState({
      loading: false,
    })
  }

  updateSignin = (signedIn) => {
    //(3)
    this.setState({
      isSignedIn: signedIn,
    });

    if (!signedIn) {
      renderButton();
    }
  };

  init = async () => {
    //(2)
    checkSignedIn()
      .then((signedIn) => {
        this.updateSignin(signedIn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { loading } = this.props;
    const { isSignedIn } = this.state;
    return (
      <div className="App">
        {!isSignedIn ? (
          <div id="signin-button"></div>
        ) : (
          <div>
              <table style={{ width: "100%", backgroundColor: 'white', borderRadius: '5px', padding: '1rem', }}>
              <tbody>
                <tr>
                  <td style={{width: '50%', backgroundColor: '#72A3F6', color: 'white'}}>
                    <div>
                      <SessionDuration />
                    </div>
                  </td>
                  <td style={{width: '50%', backgroundColor: '#4285F4', color: 'white'}}>
                  <RepeatUsers />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                  <div>
                      <AccessedUsers />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>            
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(ExamCard)));
