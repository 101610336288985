import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import ConfirmationModal from "../../../components/adminModals/confirmationModal";
//queries
import { CREATE_TOOL, UPDATE_TOOL } from "../../../queries/admin_queries";
import { GET_TOOL } from "../../../queries/common_queries";

//redux-form
import { Field, FieldArray, reduxForm } from "redux-form";

//Image Uploader
import Images from "../Image/Images";
import { Button } from "react-uikit";
import { Input } from "semantic-ui-react";

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
    languageKeys: state.custom_reducer.languageKeys,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class AddTool extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");

    if (id == null) {
      this.state = {
        data: null,
        toolId: "",
        title: "",
        image: "",
        url: "",
        isActive: true,
        formAction: "ADD",
        loading: false,
        imageUrl: "",
        formData: "",
        file: "",
        imagePreviewUrl: "",
        uploads: [],
        fileUrl: null,
        fileRef: null,
        isUploading: false,

        formBannerImageData: "",
        bannerImageFileRef: null,
        isBannerImageUploading: false,
        supportingLanguages: [],
        supportingLangErrorMsg: "",
        confirmationModal: false,
      };
    } else {
      this.state = {
        data: null,
        toolId: id,
        title: "",
        image: "",
        url: "",
        isActive: true,
        formAction: "EDIT",
        loading: false,
        getToolParameter: { toolId: id, lang: "en" },
        imageUrl: "",
        image_file: "",
        formData: "",
        file: "",
        uploads: [],
        fileUrl: null,
        fileRef: null,
        isUploading: false,
        formBannerImageData: "",
        bannerImageFileRef: null,
        isBannerImageUploading: false,
        formLanguage: "en",
        supportingLanguages: [],
        supportingLangErrorMsg: "",
        confirmationModal: false,
      };
    }

    this.handleChange = this.handleChange.bind(this);

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.state.formAction == "EDIT") {
      this.getSingleTool();
    } else {
      this.setState({ loading: false });
    }
    //supporting languages array
    if (this.state.formAction === "ADD") {
      const supportingLanguages = this.props.translatingLanguages;
      this.setState({
        supportingLanguages: supportingLanguages,
      });
    }
  }

  documentUploadOnClick = (event) => {
    this.setState({
      isUploading: true,
    });
    console.log(this.state.formData);
    this._startDocUpload(this.state.formData);
  };


  documentUploadOnChange = (event) => {
    this.setState({
      formData: event.target.files[0],
    });
  };

  _startDocUpload = async (file) => {
    var intDate = Number(new Date());
    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    if (result.status) {
      this.setState({
        isUploading: false,
        fileRef:
          "uploads/" + localStorage.FIRST_NAME + intDate + "/" + file.name,
        image: result.url,
        imageUrl: result.url,
        isUploading: false,
      });
    }
  };


  _deleteFile = async (path) => {
    console.log(path);
    const deleteRef = await deleteFile(path);
    this.setState({ imageUrl: "", image: "" });
    return deleteRef;
  };

  getSingleTool() {
    this.get_tool(this.state.getToolParameter).then((result) => {
      console.log(result);
      this.setState({
        toolId: result.id,
        title: result.title,
        image: result.image,
        imageUrl: result.image,
        url: result.url,
        loading: false,
        languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true
      });
    });
  }

  onEditorChange(event, editor, summaries) {
    console.log(summaries);
    this.setState({
      summaries: summaries,
    });
    // console.log(data)
  }

  handleChange(event) {
    console.log(event.target.value);
    this.setState({ [event.target.id]: event.target.value });
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleFormSubmit = (event) => {
    console.log(event);
    // var err = false;
    this.props.setFormStatus({ status: false, title: "", message: "" });
    this.props.setSuccessStatus({ status: false, title: "", message: "" });
    this.setState({ loading: true });

    const {
      title,
      image,
      url,
    } = this.state;
    if (title == "") {
      // err = true;
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a title for the tool.",
      });
    } else if (image == "") {
      // err = true;
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please upload an image for the tool.",
      });
    } else if (url == "") {
      // err = true;
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please add a URL!",
      });
    }
    else {
      if (this.state.formAction == "ADD") {
        this.createTool().then((result) => {
          console.log(result)
          this.props.setSuccessStatus({
            status: true,
            title: "",
            message: "Tool added successfully!",
          });

          this.setState({
            data: null,
            loading: false,
            title: "",
            image: "",
            imageUrl: "",
            url: "",
            supportingLanguages: this.props.translatingLanguages,
          });
        })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message:
                <div>
                  An error occurred while trying to create the tool!
                <br />
                Please try again.
              </div>
            });
          });
      } else if (this.state.formAction == "EDIT") {
        this.get_tool(this.state.getToolParameter).then((result) => {
          this.updateTool().then((result) => {
            console.log(result
            )
            this.getSingleTool();
            this.props.setSuccessStatus({
              status: true,
              title: "",
              message: "Tool updated successfully!",
            });
          })
            .catch((error) => {
              console.log(error);
              this.setState({ loading: false });
              this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                  <div>
                    An error occurred while trying to update the tool!
                  <br />
                  Please try again.
                </div>
              });
            });
        });
      }
    }
  };

  createTool = async () => {
    const { title, image, url, isActive } = this.state;
    const supportingLangs = { languages: [] };
    const tempArr = this.state.supportingLanguages.filter(
      (obj) => obj.supporting === true
    );
    if (tempArr.length > 0) {
      tempArr.map((lang) =>
        lang.key != "en" ? supportingLangs.languages.push(lang.key) : null
      );
    }
    console.log(supportingLangs);
    const result = await this.props.client.mutate({
      mutation: CREATE_TOOL,
      variables: { title, url, image, isActive, supportingLangs },
    });
    return result.data.createTool;
  };

  updateTool = async () => {
    const { toolId, title, image, url, isActive, formLanguage, languageEnabled } = this.state;
    const result = await this.props.client.mutate({
      mutation: UPDATE_TOOL,
      variables: { toolId, title, image, url, isActive, lang: formLanguage, languageEnabled },
    });
    return result.data.updateTool;
  };

  get_tool = async (getToolParameter) => {
    console.log(getToolParameter);
    const result = await this.props.client.query({
      query: GET_TOOL,
      variables: getToolParameter,
      fetchPolicy: "network-only",
    });
    return result.data.getTool;
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/admin_home");
    } else if (path == "MANAGETOOL") {
      this.props.history.push("/admin_manage_tools");
    } else {
      return false;
    }
  };

  changeFormLanguage = (language) => {
    const id = this.state.toolId;
    var tempToolParameter = this.state.getToolParameter;
    tempToolParameter.lang = language;
    this.get_tool(tempToolParameter).then((result) => {
      this.setState({
        title: result.title,
        image: result.image,
        imageUrl: result.image,
        url: result.url,
        loading: false,
        getToolParameter: { toolId: id, lang: language },
        formLanguage: language,
        languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true
      });
    });
  };

  handleLanguageClick = (lang, supported) => {
    if (lang != "English") {
      const tempArray = this.state.supportingLanguages;
      const index = this.state.supportingLanguages.findIndex(
        (obj) => obj.language === lang
      );
      tempArray[index].supporting = supported;
      this.setState({
        supportingLanguages: tempArray,
        supportingLangErrorMsg: "",
      });
    } else {
      this.setState({
        supportingLangErrorMsg: "*English language support is mandatory",
      });
    }
  };

  handleCheckBoxChange = (status) => {
    this.setState({ languageEnabled: status });
  }


  render() {
    const { loading, formAction, imageUrl, supportingLanguages, formLanguage } = this.state;
    const { handleSubmit, formErrorStatus, formSuccessState, translatingLanguages } = this.props;
    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>Home</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("MANAGETOOL")}>
                Manage Tools
              </a>
            </li>
            {formAction == "ADD" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("ADDTOOL")}>
                  Add Tool
                </a>
              </li>
            ) : formAction == "EDIT" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("EDITTOOL")}>
                  Edit Tools
                </a>
              </li>
            ) : null}
          </ul>
        </div>

        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-tools fa-2x uk-margin-right"></i>

          {formAction == "ADD" ? (
            <h4 className="uk-margin-remove"> Add New Tool </h4>
          ) : formAction == "EDIT" ? (
            <h4 className="uk-margin-remove"> Edit Tool </h4>
          ) : null}
        </div>

        <div className="uk-margin-medium">
          <form className="ui form">
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="fas fa-tools uk-margin-small-right"></i>{" "}
                    Tool Details
                    {
                      formAction == "EDIT" ?
                        translatingLanguages.length > 0 ?
                          <div className="formLanguageSwitcherDiv">
                            {
                              translatingLanguages.map(languageTab => (
                                <span circular icon="download" className={formLanguage === languageTab.key ? "formLanguageSwitcherActive" : "formLanguageSwitcher"} onClick={() => this.changeFormLanguage(languageTab.key)}>
                                  {languageTab.language}
                                </span>
                              ))
                            }
                          </div>
                          : null
                        : null
                    }

                  </div>
                  <div className="uk-card-body uk-padding-remove-top">

                  {
                    this.state.formAction === "EDIT" ?
                        this.state.formLanguage != "en" ?
                            <div className="disableLanguageVersionDiv">
                                <input type="checkbox" id="languageEnabled" name="languageEnabled" checked={this.state.languageEnabled} onChange={() => this.handleCheckBoxChange(!this.state.languageEnabled)} />
                                <label>{this.props.languageKeys[this.state.formLanguage]} version enabled</label>
                            </div>
                        : null
                    :null
                  }

                    <div className="uk-form-label adminSectionTextFieldLabel"> Tool Title </div>
                    <input
                      value={this.state.title || ""}
                      className="uk-input uk-form-width-huge"
                      id="title"
                      name="title"
                      placeholder="Title"
                      type="text"
                      onChange={this.handleChange}
                    />

                  

                    {formAction === "ADD" ? (
                      <div>
                        <div className="uk-form-label">
                          {" "}
                          <text>Supporting Languages</text>
                          <text className="langErrorMessage">
                            {this.state.supportingLangErrorMsg}
                          </text>{" "}
                        </div>
                        {supportingLanguages
                          ? supportingLanguages.length > 0
                            ? supportingLanguages.map((lang) => (
                              <span
                                className={
                                  lang.supporting === true
                                    ? "supportingLanguageActive"
                                    : "supportingLanguage"
                                }
                                onClick={() =>
                                  this.handleLanguageClick(
                                    lang.language,
                                    !lang.supporting
                                  )
                                }
                              >
                                {lang.language}
                              </span>
                            ))
                            : null
                          : null}
                      </div>
                    ) : null}

                    <div className="uk-form-label"> Upload Image </div>

                    <div>
                      <div className="">
                        {/* File Component */}
                        {this.state.imageUrl !== null &&
                          this.state.imageUrl !== undefined &&
                          this.state.imageUrl !== "" ? (
                            <div className="file-upload-group uk-card uk-card-default uk-card-body">
                              <div className="uk-text-center uk-grid ">
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="download"
                                    class="uk-button uk-button-primary uk-button-small"
                                    onClick={() => {
                                      window.open(this.state.imageUrl);
                                    }}
                                  >
                                    Download
                                </span>
                                </div>
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="trash alternate outline"
                                    class="uk-button uk-button-secondary uk-button-small"
                                    onClick={() =>
                                      this.setState({ confirmationModal: true })
                                    }
                                  >
                                    Delete
                                </span>
                                </div>
                              </div>
                              <br />
                              <Images
                                image={imageUrl}
                                removeImage={this.removeImage}
                              />
                            </div>
                          ) : (
                            <div className="uk-text-center uk-grid">
                              <div className="uk-width-auto@m">
                                <Input
                                  onChange={this.documentUploadOnChange}
                                  name="upload"
                                  id="upload"
                                  className="uk-input uk-form-width-huge"
                                  placeholder="Upload here"
                                  type="file"
                                  multiple
                                />
                              </div>
                              <div className="uk-width-auto@m">
                                <span
                                  class="uk-button uk-button-primary"
                                  onClick={this.documentUploadOnClick}
                                >
                                  {loading ? (
                                    <span
                                      data-uk-spinner=""
                                      style={{ color: "#o6a7e3" }}
                                    />
                                  ) : (
                                      "Upload Image"
                                    )}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* File Component */}
                      </div>
                      <br />
                      {this.state.isUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                              Just a moment… <br />
                            We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>

                    <div className="uk-form-label adminSectionTextFieldLabel"> Tool URL </div>
                    <input
                      value={this.state.url || ""}
                      className="uk-input uk-form-width-huge"
                      id="url"
                      name="url"
                      placeholder="URL"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <Button
                  className="uk-button uk-button-primary uk-margin"
                  style={{ height: 40 }}
                  type="button"
                  disabled={loading ? true : false}
                  onClick={handleSubmit((val) => this.handleFormSubmit(val))}
                >
                  {loading ? (
                    <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                  ) : (
                      this.state.formAction === "ADD" ?
                        "Save"
                        : "Update"
                    )}
                </Button>

                {formErrorStatus.status ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                ) : formSuccessState.status ? (
                  <div className="success_alert">
                    {formSuccessState.message}{" "}
                  </div>
                ) : (
                      ""
                    )}
              </div>
            </div>
          </form>
        </div>
        {
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => { this._deleteFile(this.state.imageUrl).then((result) => { this.setState({ imageUrl: null, fileRef: null, confirmationModal: false }); }); }} closeModal={() => this.setState({ confirmationModal: false })} />
            : null
        }
      </div>
    );
  }
}

AddTool = connect(mapStateToProps, mapDispatchToProps)(AddTool);
export default withApollo(reduxForm({ form: "fieldArrays" })(AddTool));
