import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";
import { connect } from 'react-redux';
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from "../../actions/user-actions"
import { RESET_PASSWORD, VERIFY_PASSCODE, UPDATE_PASSWORD } from "../../queries/user_queries";

import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { _generate_email } from '../../middleware/index';
import { CREATE_USER_NOTIFICATION } from "../../queries/common_queries";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    const { minStrength = 3, thresholdLength = 7 } = props;
		this.minStrength = typeof minStrength === 'number'
			? Math.max( Math.min(minStrength, 4), 0 )
			: 3;
		this.thresholdLength = typeof thresholdLength === 'number'
			? Math.max(thresholdLength, 7)
            : 7;
    this.state = {
      email: "",
      pcode: "",
      loading: false,
      loadingPasscode: false,
      updatingPassword: false,
      formSwitcher: 0,
      userID: "",
      password: "",
      confPassword: '',
      thresholdLength: 7,
      strength: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
  }
  updatePassword = () => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.setState({ updatingPassword: true });
    const { password, confPassword, userID } = this.state;
    console.log(userID);
    if (password !== confPassword) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: 
        this.props.lang=="ar" ? (
          <div style={{paddingRight:"20px"}}> { this.props.t('password_not_match')} </div>
        ):(
          <div> { this.props.t('password_not_match')} </div>
        )   
      });
      this.setState({ updatingPassword: false });
      return;
    }

    if(this.state.password.length <= this.state.thresholdLength ){
      this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message: 
          this.props.lang=="ar" ? (
            <div style={{paddingRight:"20px"}}> { this.props.t('password_validity') } </div>
          ):(
            <div> { this.props.t('password_validity') } </div>
          )
          
                 
      });
      this.setState({ loading: false });
      return;
  }

    this.reset_password(password, userID).then(result => {
      console.log(result);

      const notificationData = {
        notificationType: "PASSWORD_CHANGED",
        notification: "You have changed your password successfully.",
        userToNotify: userID,
        languageObj: {
          ar: "لقد قمت بتغيير كلمة المرور الخاصة بك بنجاح.",
          fr: "Vous avez changé votre mot de passe avec succès.",
          sp: "Su contraseña se ha cambiado con éxito."
      }
      };

      this.notifyUser(notificationData).then((result1) => {
        console.log(result1)
      })

      this.setState({
        updatingPassword: false,
      });
      setTimeout(() => {
        this.props.history.push('/');
      }, 3000);
      this.props.setSuccessStatus({
        status: true,
        title: "",
        message:
        this.props.lang=="ar" ? (
          <div style={{paddingRight:"20px"}} >{ this.props.t('password_updated')} </div>
        ):(
          <div >{ this.props.t('password_updated')} </div>
        )
      });

      

    }).catch(error => {
      if (error) {
        this.setState({ updatingPassword: false });
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message:
          this.props.lang=="ar" ? (
            <div style={{paddingRight:"20px"}} >
            {this.props.t('error_updating_pw_user')} <br/>
            {this.props.t('try_agin')}
          </div>
          ):(
            <div>
            {this.props.t('error_updating_pw_user')} <br/>
            {this.props.t('try_agin')}
          </div>
          )
        });
      }
    });
  }
  checkPasscode = () => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.setState({ loadingPasscode: true });
    const { pcode, userID } = this.state;
    const passscode = parseInt(pcode);
    this.verify_passcode(passscode, userID).then(result => {
      console.log(result);
      this.setState({ loadingPasscode: false });
      if (result.status === true) {
        this.setState({
          formSwitcher: 2
        });
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message:
          this.props.lang=="ar" ? (
            <div style={{paddingRight:"20px"}} > { this.props.t('passcode_matched')}  </div>
          ):(
            <div> { this.props.t('passcode_matched')}  </div>
          )
         
        });
      } else {
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message:
          this.props.lang=="ar" ? (
            <div style={{paddingRight:"20px"}}> {this.props.t('invalid_passcode')} </div>
          ):(
            <div> {  this.props.t('invalid_passcode')} </div>
          )
         
          
        });
      }
    }).catch(error => {
      this.setState({ loadingPasscode: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message:
        this.props.lang=="ar"? (
          <div style={{paddingRight:"20px"}}> { this.props.t('invalid_passcode')} </div>
        ):(
          <div> { this.props.t('invalid_passcode')} </div>
        )
        
       
      });
      console.error('onRejected function called: ' + error.message);
    });
  }

  handleSubmit = () => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.setState({ loading: true });
    const { email } = this.state;
    this.request_reset_password(email).then(result => {
      const email = result.userEmail;
      const name = result.userName;
      const passscode = result.psscode;
      const userId = result.userId;
      const personalizations = {
        "personalizations": [
          {
            "to": [
              {
                "email": email,
                "name": name
              }
            ],
            "bcc": [
              {
                "email": "narthana.se@gmail.com",
                "name": "Narthana"
              },
              {
                "email": "chameera.lakshitha212@gmail.com",
                "name": "Lakshitha"
              },
              {
                "email": "rishi@fpasrilanka.org",
                "name": "Rishikeshan Thiyagaraja"
              }
             
            ],
            "dynamic_template_data": {
              "name": name,
              "psscode": passscode
            },
            "subject": "Social Enterprise Academy – Password reset"
          }
        ],
        "template_id": localStorage.LANG === 'en' ? "d-29d394d1f68142a4831701db6fe9ef48" 
                                    : localStorage.LANG === 'ar' ? "d-cb928ad5a0b34cc59a95fb3a5383e1e8" 
                                        : localStorage.LANG === 'fr' ? "d-84f4ece7e7f148dd977aa9d9fb69fc9e" 
                                            : localStorage.LANG === 'sp' ? "d-e0b7cc2346d244cbb2e4ffe0f42484b3" 
                                                : "d-29d394d1f68142a4831701db6fe9ef48"
      };
      try {
        // const data = _generate_email('./mail/mail.php', personalizations);
        const data = _generate_email(' https://seippf.org/mail/mail.php ', personalizations);
        console.log(data)
        
        this.setState({
          loading: false,
          formSwitcher: 1,
          userID: userId
        })
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message: 
          <div style = {this.props.lang=="ar"? (styles.success_alert_Ar):(styles.success_alert)}>
            {/* <p className="marginsPara"> We have emailed you a passcode to reset your password. </p> */}
            <p className="marginsPara"> {this.props.t('passcode_sent')} </p>
            <p className="marginsPara2"> {this.props.t('please_chk')} </p>
          </div> 
        });
      } catch (error) {
        console.error(error);
      }
      
    }).catch(error => {
    
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "",
        message: <div style={{paddingRight:"12px"}}>{this.props.t('incorrect_email')}</div>
      });
      
      console.error('onRejected function called: ' + error.message);
    })
  };

  notifyUser = async (data) => {
    const result = await this.props.client.mutate({
      mutation: CREATE_USER_NOTIFICATION,
      variables: data,
    });

    console.log(result.data);
    return result.data.createUserNotification;
  };


  _handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  reset_password = async (password, userId) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_PASSWORD,
      variables: { password, userId },
    });
    return result.data.updateUserPassword;
  };

  verify_passcode = async (passcode, userId) => {
    const result = await this.props.client.query({
      query: VERIFY_PASSCODE,
      variables: { passcode, userId },
    });
    return result.data.checkPasscode;
  };

  request_reset_password = async (email) => {
    const result = await this.props.client.mutate({
      mutation: RESET_PASSWORD,
      variables: { email },
    });
    return result.data.resetPasswordRequest;
  };

  redirectToRegister =()=>{
    this.props.history.push('/registration');
  }

 
  stateChanged = ( event )=> {
    const {   validator = f => f,  } = this.props;
this.setState({
  password: event.target.value,
        strength: zxcvbn(event.target.value).score,
    },) 
};

  render() {
    const { formErrorStatus, formSuccessState, validator, children,t, ...restProps } = this.props;
    const { formSwitcher ,  password, strength  } = this.state;
    const passwordLength = password.length;
console.log(localStorage.LANG)
		const passwordStrong = strength >= this.minStrength;
		const passwordLong = passwordLength > this.thresholdLength;
    const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();
    const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
    return (
      <div>
            {(formSwitcher === 0) ? (
              <Form error success onSubmit={this.handleSubmit} >
                <h2 className=" uk-text-bold uk-text-center loginTitle"> { t('forgot_pw_title') } </h2>
                <div  className="uk-margin" >
                  <input
                    name="email"
                    id="email"
                    value={this.state.email}
                    className="bo ll uk-input uk-width-1-1"
                    placeholder={t('email')}
                    type="email"
                    onChange={this._handleChange}
                    required
                  />
                </div>

                <div className="semantic-font uk-margin uk-text-center">
                  <input
                    id="btn-resetPassword"
                    className="uk-button uk-button-success buttonLogin uk-width-2-3  uk-margin"
                    type="submit"
                    value={ localStorage.LANG=='sp'? "Enviar código de acceso": localStorage.LANG=='fr'? "Envoyez-moi un code d'accès": localStorage.LANG=='ar'?  "أرسل لي رمز سر" : "Send me a passcode" }
                    style={{ marginBottom: "10px" }}
                    loading={this.state.loading}
                  />
                </div>
                {(formErrorStatus.status) ? (
                            <div className="danger_alert">{formErrorStatus.message}</div>
                        ) : ((formSuccessState.status) ? (
                            <div className="success_alert">{formSuccessState.message} </div>
                        ) : (''))}

<p className="uk-text-center Login_text" style={{ marginTop: 0 }}>
             {t('already_registered')} <button onClick={() => { window.location.href = '/' }} className="uk-button align uk-button-text Login_text "> <button class="uk-button align uk-button-text Login_text"> {t('login_here')} </button> </button>
            </p>
            <p class="uk-text-center Login_text" style={{ marginTop: "0px", marginBottom: "0px" }} >{t('dont_have_account')} <button class="uk-button align uk-button-text Login_text"
       onClick={()=>this.redirectToRegister()}
       >
         { t('register_here')}
          </button>
      </p>
              </Form>
            ) : ("")}

            {(formSwitcher === 1) ? (
              <Form error success onSubmit={this.checkPasscode} >
                <h2 className=" uk-text-bold uk-text-center loginTitle"> {t('type_pc')} </h2>
                <div className="uk-margin">
                  <input
                    name="pcode"
                    id="pcode"
                    value={this.state.pcode}
                    className="bo ll uk-input uk-width-1-1"
                    placeholder={t('passcode')}
                    type="number"
                    onChange={this._handleChange}
                    required
                  />
                </div>

                <div className="uk-margin uk-text-center">
                  <input
                    id="btn-resetPassword"
                    className="uk-button uk-button-success uk-width-2-3  uk-margin buttonLogin"
                    type="submit"
                    value={t("ck_pass_code")}
                    // value={ localStorage.LANG=='sp'? "Verificar contraseña": localStorage.LANG=='fr'? "Vérifier mon code d'accès": localStorage.LANG=='ar'?  "التحقق من رمز السر الخاص بي": "Check my passcode" }  
                    style={{ marginBottom: "10px" }}
                    loading={this.state.loadingPasscode}
                  />
                </div>
                {(formErrorStatus.status) ? (
                            <div className="danger_alert">{formErrorStatus.message}</div>
                        ) : ((formSuccessState.status) ? (
                            <div className="success_alert">{formSuccessState.message} </div>
                ) : (''))}
                            <p className="uk-text-center Login_text" style={{ marginTop: 0 }}>
             {t('already_registered')} <button onClick={() => { window.location.href = '/' }} className="uk-button align uk-button-text Login_text "> <button class="uk-button align uk-button-text Login_text"> {t('login_here')} </button> </button>
            </p>
            <p class="uk-text-center Login_text" style={{ marginTop: "0px", marginBottom: "0px" }} >{t('dont_have_account')} <button class="uk-button align uk-button-text Login_text"
       onClick={()=>this.redirectToRegister()}
       >
         { t('register_here')}
          </button>
      </p>
              </Form>
            ) : ("")}

            {(formSwitcher === 2) ? (

              <Form error success onSubmit={this.updatePassword} >
                <h2 className=" uk-text-bold uk-text-center loginTitle titlebottom_mar"> {t('update_pw')} </h2>
                {/* <div    minStrength={3}> */}
                  <Form.Field {...restProps} validator={this.validatePasswordStrong}  >
                  <span style={{fontSize:"11.8px", color:"#ffffff", lineHeight:"0.8px"}} className="d-block form-hint">{ t('pw_strength_indicator') }</span>
                           {children}
                          <div className={strengthClass}>
                              <div className="strength-meter-fill" data-strength={strength}></div>
                          </div>
                  </Form.Field>
                  <input
                    name="password"
                    id="password"
                    value={this.state.password}
                    className="bo ll uk-input uk-width-1-1"
                    placeholder={t('new_pw')}
                    type="password"
                    onChange={this.stateChanged}
                    required
                  />
                {/* </div> */}

                <div className="uk-margin">
                  <input
                    name="confPassword"
                    id="confPassword"
                    value={this.state.confPassword}
                    className="bo ll uk-input uk-width-1-1"
                    placeholder={t('password')}
                    type="password"
                    onChange={this._handleChange}
                    required
                  />
                </div>

                <div className="system_msg_style uk-margin uk-text-center">
                  <input
                    id="btn-resetPassword"
                    className= { localStorage.LANG=='fr'? "updateStyle uk-button uk-button-success uk-width-2-3  uk-margin buttonLogin": "uk-button uk-button-success uk-width-2-3  uk-margin buttonLogin"}
                    type="submit"
                    value={ localStorage.LANG=='sp'? "Actualizar mi contraseña": localStorage.LANG=='fr'? "Mettre à jour mon mot de passe": localStorage.LANG=='ar'?  "تحديث كلمة السر الخاصة بي" : "Update my password" }  
                    style={{ marginBottom: "10px" }}
                    loading={this.state.loadingPasscode}
                  />
                </div>
                
                        {(formErrorStatus.status) ? (
                            <div className="danger_alert">{formErrorStatus.message}</div>
                        ) : ((formSuccessState.status) ? (
                            <div className="success_alert">{formSuccessState.message} </div>
                        ) : (''))}
              </Form>
            ) : ("")}
            {this.state.loading ? (<div className="spinner"></div>) : ("")}
            {this.state.loadingPasscode ? (<div className="spinner"></div>) : ("")}
            {this.state.updatingPassword ? (<div className="spinner"></div>) : ("")}


            {/* <p className="uk-text-center Login_text" style={{ marginTop: 0 }}>
              Don't Have an Account Yet? <button onClick={()=>this.redirectToRegister()}  className="uk-button align uk-button-text Login_text"> Register Here </button>
            </p> */}
          </div>  
        // </div>
      // </div>
    );
  }
}
const styles = {
  icon: {
    width: "0px"
  },
  success_alert:{
    textAlign:"left"
  },
  success_alert_Ar: {
    textAlign: "right",
    paddingRight:"20px"
  },
 
};

ForgotPassword.propTypes = {
  children: PropTypes.node,
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ForgotPassword))));


