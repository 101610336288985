import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import Circle from "react-circle";
import { withRouter } from "react-router-dom";
import { GET_OTHER_STATS } from "../../queries/admin_queries";
import NoDataMessage from '../messages/contentNotFound/NoDataFoundSmall'
var _ = require('underscore');
const mapStateToProps = (state, ownProps) => {
  return {};
};

class ResourceStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        resourceStatList: [],
        resources: [],
        totalUsers: 0,
        resourceUserList: "",
        resourceUserCount: "",
        totalViewsResources: 0,

        fontColors: ['#aa91f2', '#f7be2d'],

        fromDate: this.props.fromDate,
        toDate: this.props.toDate, 
    };
  }

  
  componentDidMount() {
    this.getOtherStats(this.state.fromDate, this.state.toDate).then((result) => {
      var temp = [];
      var resourceStats = [];
      var userListRes = "";
      var resourceUserCount = 0;
      var totalViewsResources = 0;
      var checkedList = [];
      console.log(result.resourceStatList)
      if (result.resources.length > 0) {
        if (result.resourceStatList.length > 0) {
            result.resourceStatList.slice(0).reverse().map(r => (
                temp = Object.values(result.resources).filter(obj => obj.id === r.resourceId),
                temp.length > 0 ?
                  checkedList.includes(r.resourceId) != true  ?
                    resourceStats.push({resourceTitle: temp[0].resourceTitle, clickCount: r.clickCount, createdBy: r.createdBy})
                    : null
                : null,
                totalViewsResources = totalViewsResources + r.clickCount,
                checkedList.push(r.resourceId)
            ))
            userListRes = (_.countBy(resourceStats, function(resourceStats) { return resourceStats.createdBy; }));
            resourceUserCount = Object.keys(userListRes).length  
      }
    }

      this.setState({
        resourceStatList: resourceStats,
        resourceUserList: userListRes,
        totalViewsResources: totalViewsResources,
        resourceUserCount: resourceUserCount,
        totalUsers: result.totalUsers,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate) {
      this.getOtherStats(this.props.fromDate, this.props.toDate).then((result) => {
        var temp = [];
        var resourceStats = [];
        var userListRes = "";
        var resourceUserCount = 0;
        var totalViewsResources = 0;
  
        if (result.resources.length > 0) {
          if (result.resourceStatList.length > 0) {
              result.resourceStatList.map(r => (
                  temp = Object.values(result.resources).filter(obj => obj.id === r.resourceId),
                  temp.length > 0 ?
                      resourceStats.push({resourceTitle: temp[0].resourceTitle, clickCount: r.clickCount, createdBy: r.createdBy})
                  : null,
                  totalViewsResources = totalViewsResources + r.clickCount
              ))
              userListRes = (_.countBy(resourceStats, function(resourceStats) { return resourceStats.createdBy; }));
              resourceUserCount = Object.keys(userListRes).length  
        }
      }
  
        this.setState({
          resourceStatList: resourceStats,
          resourceUserList: userListRes,
          totalViewsResources: totalViewsResources,
          resourceUserCount: resourceUserCount,
        });
      });
    }
  }

  objectSum = ( obj ) => {
    var sum = 0;
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
          sum = sum + parseInt(obj[key])
          // console.log(key + " -> " + obj[key]);
      }
  }
    return sum;
  }

  getOtherStats = async (from, to) => {
    const result = await this.props.client.query({
      query: GET_OTHER_STATS,
      variables: {fromDate: from, toDate: to},
      fetchPolicy: "network-only",
    });
    return result.data.getOtherStats;
  };

  render() {
    const {} = this.props;
    const {
        resourceStatList,
        totalUsers,
        resources,
      
        fontColors,

        resourceUserList,
        resourceUserCount,
        totalViewsResources,

        fromDate,
        toDate,

    } = this.state;
    return (
      <div className="">
        <h4 className="statTitleMain">Resources</h4>
        <hr />
        <table style={{width: '100%', backgroundColor: '#F4ECD2', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <tbody>
                <tr>
                    <td style={{width: '60%', padding: '0.8rem', verticalAlign:'top'}}>
                        <div style={{width: '100%', backgroundColor: '#F4ECD2', textAlign: 'left'}}>
                            <h5 className="resStatTitle">Individual Resource Visits</h5>
                            <div>
                                <table style={{width: '100%'}}>
                                <thead style={{color: '#6e230e', fontSize: '0.9rem'}}>
                                  <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th style={{textAlign: 'right'}}>Visits</th>
                                  </tr>
                                </thead>
                                    <tbody>
                                        {
                                            resourceStatList.length > 0 ?
                                            resourceStatList.map((stat, index) => (
                                              index + 1 <=3 ?
                                              resourceStatList.length > 3 ?
                                                <tr>
                                                    <td style={{width: '2rem', fontSize: '0.9rem', fontWeight: 'bold'}}>{index + 1}.</td>
                                                    <td style={{width: '', textAlign: 'left', fontSize: '0.9rem', fontWeight: 'bold'}}>{stat.resourceTitle}</td>
                                                    <td style={{width: '2rem', textAlign: 'right', fontSize: '1rem', fontWeight: 'bold', color: '#2d9e00'}}>{stat.clickCount}</td>
                                                </tr>
                                              :
                                              <tr>
                                                <td style={{width: '2.5rem'}}>{index + 1}.</td>
                                                <td style={{width: '', textAlign: 'left', fontSize: '0.8rem'}}>{stat.resourceTitle}</td>
                                                <td style={{width: '2rem', textAlign: 'right',}}>{stat.clickCount}</td>
                                              </tr>
                                              :
                                              <tr>
                                                <td style={{width: '2.5rem'}}>{index + 1}.</td>
                                                <td style={{width: '', textAlign: 'left', fontSize: '0.8rem'}}>{stat.resourceTitle}</td>
                                                <td style={{width: '2rem', textAlign: 'right',}}>{stat.clickCount}</td>
                                              </tr>
                                            ))
                                        : 
                                        <tr>
                                            <td colSpan='3' style={{width: '2rem', fontSize: '0.9rem', fontWeight: 'bold'}}>
                                              <NoDataMessage message="No statistics found for the selected duration" fontSize="0.8rem" />
                                            </td>

                                        </tr>
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                    <td style={{width: '40%', padding: '0.8rem'}}>
                      <div style={{textAlign: 'center', width: '50%', float: 'left'}}>
                          <text style={{fontSize: '4rem', color: fontColors[0]}}>{totalViewsResources}</text>
                          <br />
                          <text style={{fontSize: '1rem', fontWeight: '300'}}>Total Visits</text> 
                      </div>
                      <div style={{textAlign: 'center', width: '50%', float: 'left'}}>
                          <text style={{fontSize: '4rem', color: fontColors[1]}}>{Math.round(totalViewsResources/resourceUserCount) || 0}</text>
                          <text style={{fontSize: '1rem'}}>/ per user</text> 

                          <br />
                          
                          <text style={{fontSize: '1.1rem', fontWeight: '300'}}>Average Visits</text> 
                          
                      </div>
                      <br /><br /><br /><br /><br />
                      <div style={{width: '100%'}}>
                      <div className="fitCenterDiv" style={{paddingTop: '1rem'}}>
                          <Circle
                            animate={true}
                            animationDuration="3s"
                            size={120}
                            lineWidth={50}
                            progress={Math.round(resourceUserCount/totalUsers*100) || 0}
                            progressColor={"#8ACBC3"}
                            bgColor="white"
                            // textColor="white"
                            textStyle={{
                              fontSize: "6.5rem",
                              fontWeight: "500",
                            }}
                          />
                          <br />

                      </div>
                      <div className="fitCenterDiv">
                        <text style={{fontSize: '1.1rem', fontWeight: '300', textAlign: 'center'}}>Average User Views</text> 
                      </div>
                      </div>
                      
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(ResourceStats)));
