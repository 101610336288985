import * as React from 'react';
import Moment from 'react-moment';
import Header from '../../../header/header';
import { withNamespaces } from 'react-i18next';

class Single_resource_banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { resourceTitle, createdAt, t } = this.props;
        const dateToFormat = createdAt;
        return (
            <div className="banner_wraper" >
                <div style={{ backgroundImage: "linear-gradient(to right, rgb(24, 41, 120) 50% ,rgb(24, 41, 120,0.9) )", minHeight: "140px", maxHeight: "140px", objectFit: "cover" }} className="uk-position-bottomsingle-news-header" uk-img="" className="single-news-header uk-height-large uk-background-norepeat  uk-background-center-center uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover">
                    <div className="header_wrapper">
                        <div className="sigle-page-header">
                            <Header />
                        </div>
                        <div className="uk-width-1-1 uk-container uk-margin-large-top uk-padding-remove-bottom">
                            {localStorage.LANG == 'ar' ? (
                                <h1 style={{paddingTop:"12px"}} className="uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top"> {resourceTitle} </h1>
                            ) : (
                                <h1 className="uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top"> {resourceTitle} </h1>
                            )}
                            {/* <p className="uk-margin-large-left  uk-text-white">
                               {t('created_on')} <time dateTime="2019-02-08T14:18:59+00:00"><Moment format="D MMM YYYY">{dateToFormat}</Moment></time>.</p> */}
                        </div>
                    </div>
                    <div className="overlay"> </div>
                </div>
            </div>
        )
    }
}

export default withNamespaces()(Single_resource_banner);