import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Image, Dropdown } from 'semantic-ui-react';
import { isLoggedIn } from "../../../services/CacheOperations";

import Footer from '../../../components/footer/footer'
import FaqContainer from '../../../components/faq/faqContainer/faqContainer'
import { ippfauthtoken, USER_ID, EMAIL, FIRST_NAME, LAST_NAME, USER_TYPE } from '../../../constants/genarics';
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';
import { EDIT_BASIC_PROFILE } from '../../../queries/user_queries';

import logo1 from '../../../images/1_SE_IPPF_logo_1.png';
import logo2 from '../../../images/2_SE_IPPF_logo_old_edited_color.png';

const languageOptions = [
    { text: "English", value: "en", key: "en" },
    { text: "العربية", value: "ar", key: "ar" },
    { text: "Français", value: "fr", key: "fr" },
    { text: "Español", value: "sp", key: "sp" },
  ];

class FAQ extends React.Component {
    constructor(props) {
        super(props);
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
                    : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
                    : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
                    : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
    }

    logout = () => {
        setTimeout(() => {
            localStorage.removeItem(ippfauthtoken);
            localStorage.removeItem(USER_ID);
            localStorage.removeItem(EMAIL);
            localStorage.removeItem(FIRST_NAME);
            localStorage.removeItem(LAST_NAME);
            localStorage.removeItem(USER_TYPE);
            {/* login route changed */ }
            this.props.history.push("/");
        }, 500)
    }

    // changeLanguage = async (e, { value }, data) => {
    //     localStorage.LANG = value;
    //     i18n.changeLanguage(value);
    //     window.location.reload();
    //   };

    changeLanguage = async (e, { value }, data) => {
        localStorage.LANG = value;
        if(localStorage.USER_ID != undefined) {
        this.update_user_language(value).then((result) => {
            i18n.changeLanguage(value);
            window.location.reload();
        });
        }
        else {
            i18n.changeLanguage(value);
            window.location.reload();
        }
    };

    update_user_language = async (lang) => {
        const result = await this.props.client.mutate({
            mutation: EDIT_BASIC_PROFILE,
            variables: { userId: localStorage.USER_ID, languagePreference: lang }
        });
        return;
    };


    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="tm-header uk-visible@m tm-header-transparent">
                    <div
                        media="768"
                        animation="uk-animation-slide-top"
                        cls-active="uk-navbar-sticky uk-nav-dark"
                        sel-target=".uk-navbar-container"
                        top='.tm-header ~ [class*="uk-section"], .tm-header ~ * > [class*="uk-section"]'
                        cls-inactive="uk-navbar-transparent   uk-dark"
                        className="uk-sticky uk-navbar-container"
                    >
                        <div className=" uk-container  uk-position-relative">
                        {localStorage.LANG=="ar"? (
                            <nav className="paddingHeader uk-navbar uk-navbar-transparent">
                           
                            <div className="lineHeight uk-navbar-right">
                                <ul className=" uk-navbar-nav toolbar-nav">
                                     {/* Uncomment to enable language switcher in user site */}

                                        <li className="uk-text-capitalize">
                                            <div style={{ position: "relative" }}>
                                                <Dropdown
                                                className="languageSwitcherLogin"
                                                options={languageOptions}
                                                value={localStorage.LANG}
                                                onChange={this.changeLanguage}
                                                />
                                                <i
                                                class="fa fa-caret-down langDropdownIcon"
                                                aria-hidden="true"
                                                ></i>
                                            </div>
                                        </li>
                                    {
                                        isLoggedIn() ? (
                                            <li style={{marginRight:"60px"}} className="uk-text-uppercase">
                                                <a href="/home">{t("home")}</a>
                                            </li>
                                        ) : (
                                                <li style={{marginRight:"60px"}} className="uk-text-uppercase">
                                                    {/* login route changed */}
                                                    <a href="/">{t("login")}</a>
                                                </li>
                                            )
                                    }
                                    <li>
                                        <a href="/faq">{t("faq")}</a>
                                    </li>
                                    <li target="_blank" className="uk-text-uppercase">
                                        {/* login route changed */}
                                        <a href="/#aboutus_section" target="_blank" >{t("about")}</a>
                                    </li>
                                    <li className="uk-text-uppercase" >
                                        <a target="_blank" href="/contact">{t("contact")}</a>
                                    </li>

                                    {isLoggedIn() ? (
                                        <li className="uk-text-uppercase">
                                            <a onClick={() => this.logout()}>{t("logout")}</a>
                                        </li>
                                    ) : ("")
                                    }
                                </ul>

                                {isLoggedIn() ? ("") : (
                                    <a
                                        className="fontstyle el-content uk-button uk-button-success uk-circle"
                                        href="registration"
                                    >
                                        {" "}
                                        {t("register")}{" "}
                                    </a>
                                )}
                            </div>
                            <div className="uk-navbar-left">
                                <a style={{float:"left"}} href="#" className="uk-logo black-logo">
                                    {/* <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/SE_IPPF_logo.png')} fluid /> */}
                                {/* //added on 3rd Feb 2022 */}
                                    <Image style={{marginTop:"14.5px"}} className="ui fluid image uk-display-inline-block ippf-logo adm-logo" 
                                    // src={require('../../../images/1_SE_IPPF_logo_1.png')} 
                                    src={logo1}
                                    fluid />
                                    <Image style={{float:"right", paddingBottom:"2px"}} className="ui fluid image uk-display-inline-block ippf-logo adm-logo" 
                                    // src={require('../../../images/2_SE_IPPF_logo_old_edited_color.png')}
                                    src={logo2}
                                     fluid />

                                </a>
                            </div>
                        </nav>
                        ):(
                            <nav className="paddingHeader uk-navbar uk-navbar-transparent">
                            <div className="uk-navbar-left">
                                <a href="#" className="uk-logo black-logo">
                                    {/* <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/SE_IPPF_logo.png')} fluid /> */}
                                
                                 {/* //added on 3rd Feb 2022 */}
                                 <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo" 
                                //  src={require('../../../images/1_SE_IPPF_logo_1.png')}
                                src={logo1} 
                                 fluid />
                                    <Image float="right" className="ui fluid image uk-display-inline-block ippf-logo adm-logo" 
                                    // src={require('../../../images/2_SE_IPPF_logo_old_edited_color.png')}
                                    src={logo2}
                                     fluid />

                                </a>
                            </div>
                            <div className="lineHeight uk-navbar-right">
                                <ul className=" uk-navbar-nav toolbar-nav">
                                     {/* Uncomment to enable language switcher in user site */}

                                        <li className="uk-text-capitalize">
                                            <div style={{ position: "relative" }}>
                                                <Dropdown
                                                className="languageSwitcherLogin"
                                                options={languageOptions}
                                                value={localStorage.LANG}
                                                onChange={this.changeLanguage}
                                                />
                                                <i
                                                class="fa fa-caret-down langDropdownIcon"
                                                aria-hidden="true"
                                                ></i>
                                            </div>
                                        </li>
                                    {
                                        isLoggedIn() ? (
                                            <li className="uk-text-uppercase">
                                                <a href="/home">{t("home")}</a>
                                            </li>
                                        ) : (
                                                <li className="uk-text-uppercase">
                                                    {/* login route changed */}
                                                    <a href="/">{t("login")}</a>
                                                </li>
                                            )
                                    }
                                    <li>
                                        <a href="/faq" >{t("faq")}</a>
                                    </li>
                                    <li target="_blank" className="uk-text-uppercase">
                                        {/* login route changed */}
                                        <a href="/#aboutus_section" target="_blank" >{t("about")}</a>
                                    </li>
                                    <li className="uk-text-uppercase" >
                                        <a target="_blank" href="/contact">{t("contact")}</a>
                                    </li>

                                    {isLoggedIn() ? (
                                        <li className="uk-text-uppercase">
                                            <a onClick={() => this.logout()}>{t("logout")}</a>
                                        </li>
                                    ) : ("")
                                    }
                                </ul>

                                {isLoggedIn() ? ("") : (
                                    <a
                                        className="fontstyle el-content uk-button uk-button-success uk-circle"
                                        href="registration"
                                    >
                                        {" "}
                                        {t("register")}{" "}
                                    </a>
                                )}
                            </div>
                        </nav>
                        )
                        }
                            
                        </div>
                    </div>
                </div>
                <hr />
                <FaqContainer />
                <div style={{ bottom: "0" }}>
                    <Footer language={localStorage.LANG}/>
                </div>
            </div>
        )
    }
}
export default withRouter(withApollo(withNamespaces()(FAQ)));