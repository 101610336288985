import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import moment from "moment";
import { _generate_email } from "../../../middleware/index";

//queries
import {
  CREATE_ANNOUNCEMENT,
  UPDATE_MODULE,
  UPDATE_ANNOUNCEMENT,
} from "../../../queries/admin_queries";
import {
  CREATE_USER_NOTIFICATION,
  GET_EXAM_SUBMISSIONS,
  GET_SINGLE_ANNOUNCEMENT,
  GET_SINGLE_EXAM_SUBMISSION,
  UPDATE_EXAM_SUBMISSION,
} from "../../../queries/common_queries";

//redux-form
import { Field, FieldArray, reduxForm, change } from "redux-form";

import { Button } from "react-uikit";

import { Input, Popup, Grid, Icon } from "semantic-ui-react";

import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import CKUploadAdapter from "../../../middleware/ck-file-upload-adapter";

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class SingleExamSubmission extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");

    this.state = {
      submissionId: query.get("id"),
      loading: false,
      questions: [],
      submission: "",
      exam: "",
      comments: [],
      commentText: "",
      changeCommentText: "",
      commentInEditing: -1,
      enableSubmitButton: false,
      confirmDelete: -1,
      grade: "",
      submittedGrade: "",
      gradeChanged: false,
      gradeError: "",
      answerCommentBoxEnabled: -1,
      answerComment: "",
      commentInEditingAnswer: -1,
      changeCommentTextAnswer: "",
      enableSubmitButtonAnswer: false,
    };
    this.addNewCommentRef = React.createRef();

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getSingleExamSubmission().then((result) => {
      console.log(result);
      this.setState({
        submission: result.submission,
        grade: result.submission.grade,
        exam: result.exam,
        questions: result.submission.submission.submission,
        comments: result.submission.comments
          ? result.submission.comments.comments
            ? result.submission.comments.comments.length > 0
              ? result.submission.comments.comments
              : []
            : []
          : [],
      });
    });
    this.setState({ loading: false });
  }

  getSingleExamSubmission = async () => {
    const { submissionId } = this.state;
    console.log(submissionId);
    const result = await this.props.client.mutate({
      mutation: GET_SINGLE_EXAM_SUBMISSION,
      variables: {
        submissionId: submissionId,
      },
    });
    const submittedGrade = result.data.getSingleExamSubmission.submission ? result.data.getSingleExamSubmission.submission.grade : "";
    this.setState({
      submittedGrade
    })
    return result.data.getSingleExamSubmission;
  };

  updateExamSubmission = async (data) => {
    const { submissionId } = this.state;
    const result = await this.props.client.mutate({
      mutation: UPDATE_EXAM_SUBMISSION,
      variables: data,
    });
    console.log(result.data);
    return result.data.updateExamSubmission;
  };

  notifyUser = async (data) => {
    const { submissionId } = this.state;
    const result = await this.props.client.mutate({
      mutation: CREATE_USER_NOTIFICATION,
      variables: data,
    });
    console.log(result.data);
    return result.data.createUserNotification;
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/admin_home");
    } else if (path == "KTC") {
      this.props.history.push("/admin_knowledge_testing_center");
    } else if (path == "SUBMISSIONS") {
      this.props.history.push(
        "/admin_exam_submissions?id=" + this.state.exam.id
      );
    } else {
      return false;
    }
  };

  handleCommentChange = (e) => {
    this.setState({
      commentText: e.target.value,
    });
  };

  addNewComment = () => {
    if(this.state.submittedGrade === "" || this.state.submittedGrade === null || this.state.submittedGrade === undefined) {
      this.setState({
        gradeError: "Please assign a score for this submission!"
      })
    }
    else {
      if (this.state.commentText != "") {
        const tempComments = this.state.comments;
        tempComments.push({
          text: this.state.commentText,
          addedBy: {
            name: localStorage.FIRST_NAME + " " + localStorage.LAST_NAME,
            userType: "Reviewer",
            date: moment().format(),
            userId: localStorage.USER_ID,
            updated: "",
          },
        });
        const data = {
          submissionId: this.state.submissionId,
          comments: { comments: tempComments },
        };
        this.updateExamSubmission(data).then((result1) => {
          console.log(result1);
          this.setState({
            commentText: "",
          });
          this.getSingleExamSubmission().then((result) => {
            console.log(result);
            this.setState({
              comments: result.submission.comments
                ? result.submission.comments.comments
                  ? result.submission.comments.comments.length > 0
                    ? result.submission.comments.comments
                    : []
                  : []
                : [],
            });

            const email = result.submission.user.email;
            const name =
              result.submission.user.firstName +
              " " +
              result.submission.user.lastName;
            
            // CHANGE 23/04/2021 

            // comment below line
            // const examName = result.exam.examName;

            // uncomment below 2 lines
            const examName = result.submission.user.languagePreference === 'en' || result.submission.user.languagePreference === null  ? result.exam.examName 
                          : result.exam.languageObj[result.submission.user.languagePreference].examName

            const examId = result.exam.id;
            console.log(result)
            const personalizations = {
              personalizations: [
                {
                  to: [
                    {
                      email: email,
                      name: name,
                    },
                  ],
                  bcc: [
                    {
                      // email: "narthana.se@gmail.com",
                      // name: "Narthana Selvachothy",
                      email: "rishi@fpasrilanka.org",
                      name: "Rishikeshan Thiyagaraja",
                    },
                    {
                      email: "narthana.se@gmail.com",
                      name: "Narthana Selvachothy",
                    }
                  ],
                  dynamic_template_data: {
                    examName: examName,
                    name: result.submission.user.firstName,
                    url: "http://seippf.org/sbl?id="+examId
                  },
                  subject: "Social Enterprise Academy – Your SBL attempt has been reviewed/comment added",
                },
              ],
              // CHANGE 23/04/2021 
              // template_id: "d-b04b625ccbfc4f36b5f40fc81d7f20fd" 
              
              template_id: result.submission.user.languagePreference === 'en' ? "d-b04b625ccbfc4f36b5f40fc81d7f20fd" 
                                      : result.submission.user.languagePreference === 'ar' ? "d-482d6ef10d994a39b3a0c8996d02175f" 
                                          : result.submission.user.languagePreference === 'fr' ? "d-418c7d9f04cf48bc84789a205cf3628d" 
                                              : result.submission.user.languagePreference === 'sp' ? "d-35c50081de954e77bbb5e94b5304cd3b" 
                                                  : "d-b04b625ccbfc4f36b5f40fc81d7f20fd"
            };
            try {
              const data = _generate_email(
                " https://seippf.org/mail/mail.php ",
                personalizations
              );
              console.log(data);
            } catch (error) {
              console.error(error);
            }

            const {submission, exam} = this.state;
            const data = {
              notificationType: "SBL_REVIEWED",
              notification: "SE Hub has added comments to your SBL ("+ examName +") submission.",
              userToNotify: submission.user.id,
              languageObj: {
                ar: "أضاف المركز الرئيسي للمؤسسات الاجتماعية تعليقات إلى الـSBL الخاص بك ("+ examName +").",
                fr: "SE Hub a ajouté des commentaires à votre soumission SBL (" + examName + ").",
                sp: "El Centro de Empresariado Social ha añadido comentarios a su presentación ABE (" + examName + ")"
            }
            };

            this.notifyUser(data).then((result) => {
              console.log(result)
            })

          });
        });
      }
    }
  };

  editComment = (cIndex, text) => {
    this.setState({
      commentInEditing: cIndex,
      changeCommentText: text,
    });
  };

  cancelEditComment = (cIndex) => {
    this.setState({
      commentInEditing: -1,
      changeCommentText: "",
    });
  };

  changeComment = (e, cIndex) => {
    this.setState({
      changeCommentText: e.target.value,
      enableSubmitButton: true,
    });
  };

  requestDeleteComment = (cIndex) => {
    this.setState({
      confirmDelete: cIndex,
    });
  };

  confirmDeleteComment = (cIndex) => {
    var tempArray = this.state.comments;
    tempArray.splice(cIndex, 1);
    const data = {
      submissionId: this.state.submissionId,
      comments: { comments: tempArray },
    };
    this.updateExamSubmission(data).then((result) => {
      this.setState({
        confirmDelete: -1,
      });
    });
  };

  cancelDeleteComment = (cIndex) => {
    this.setState({
      confirmDelete: -1,
    });
  };

  handleGradeChange = (e, data) => {
    this.setState({
      grade: e.target.value,
      gradeChanged: true,
    });
    console.log(e.target.value);
    this.props.setSuccessStatus({
      status: false,
      title: "",
      message: "",
    });
  };

  handleGradeSubmit = () => {
    const data = {
      submissionId: this.state.submissionId,
      grade: this.state.grade === null ? null : this.state.grade === "" ? null : Number(this.state.grade),
    };
    this.updateExamSubmission(data).then((result) => {
      console.log(result);
      this.setState({
        grade: result.grade,
        submittedGrade: result.grade,
        submission: result,
        gradeError: "",
      });
      this.props.setSuccessStatus({
        status: true,
        title: "",
        message: "Grade saved successfully!",
      });
    });
  };

  submitChangeComment = (cIndex) => {
    if (this.state.changeCommentText != "") {
      var tempArray = this.state.comments;
      tempArray[cIndex].text = this.state.changeCommentText;
      tempArray[cIndex].updated = moment().format();

      const data = {
        submissionId: this.state.submissionId,
        comments: { comments: tempArray },
      };
      this.updateExamSubmission(data).then((result) => {
        this.setState({
          commentInEditing: -1,
          enableSubmitButton: false,
        });
      });
    } else {
      this.setState({
        commentInEditing: -1,
        enableSubmitButton: false,
      });
    }
  };

  addAnswerComment = (index) => {
    // window.scrollTo(0, this.addNewCommentRef.current.offsetTop);
    // this.setState({
    //   commentText: "[Question " + questionNumber + "]. ",
    // });
    // document.examSubmission.commentText.focus();
    this.setState({ answerCommentBoxEnabled: index });
  };

  handleAnswerCommentChange = (e) => {
    this.setState({
      answerComment: e.target.value,
    });
  };

  submitAnswerComment = (index) => {
    var temp = this.state.submission.submission.submission;
    temp[index].comment = {
      text: this.state.answerComment,
      name: localStorage.FIRST_NAME + " " + localStorage.LAST_NAME,
      userType: "Reviewer",
      date: moment().format(),
      userId: localStorage.USER_ID,
      updated: "",
    };
    console.log(temp);
    const data = {
      submissionId: this.state.submissionId,
      submission: { submission: temp },
    };
    this.updateExamSubmission(data).then((result) => {
      this.setState({
        answerCommentBoxEnabled: -1,
        answerComment: "",
      });
    });
  };

  editAnswerComment = (index, text) => {
    this.setState({
      commentInEditingAnswer: index,
      changeCommentTextAnswer: text,
    });
  };

  cancelEditAnswerComment = () => {
    this.setState({
      commentInEditingAnswer: -1,
      changeCommentTextAnswer: "",
    });
  };

  changeAnswerComment = (e, cIndex) => {
    this.setState({
      changeCommentTextAnswer: e.target.value,
      enableSubmitButtonAnswer: true,
    });
  };

  submitChangeAnswerComment = (cIndex) => {
    console.log(this.state.submission.submission);
    console.log(this.state.changeCommentTextAnswer);
    if (this.state.changeCommentTextAnswer != "") {
      var temp = this.state.submission;
      temp.submission.submission[
        cIndex
      ].comment.text = this.state.changeCommentTextAnswer;
      temp.submission.submission[cIndex].comment.updated = moment().format();
      const data = {
        submissionId: this.state.submissionId,
        submission: temp.submission,
      };
      this.updateExamSubmission(data).then((result) => {
        this.setState({
          commentInEditingAnswer: -1,
          enableSubmitButtonAnswer: false,
        });
      });
    } else {
      this.setState({
        commentInEditingAnswer: -1,
        enableSubmitButtonAnswer: false,
      });
    }
  };

  render() {
    const {
      questions,
      submission,
      exam,
      commentText,
      changeCommentText,
      comments,
      commentInEditing,
      enableSubmitButton,
      confirmDelete,
      grade,
      answerCommentBoxEnabled,
    } = this.state;
    const {
      handleSubmit,
      formErrorStatus,
      formSuccessState,
      translatingLanguages,
    } = this.props;
    console.log(changeCommentText);
    console.log(exam)

    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>Home</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("KTC")}>Manage SBL</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("SUBMISSIONS")}>
                SBL Submissions
              </a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("SUBMISSION")}>
                Submission
              </a>
            </li>
          </ul>
        </div>

        <div className="uk-flex-inline uk-flex-middle">
        <i className="far fa-newspaper uk-margin-right" style={{fontSize: '2.3rem'}}></i>
          <h4 className="uk-margin-remove"> Review Submission </h4>
        </div>

        <div className="uk-margin-medium">
          <form className="ui form" name="examSubmission">
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <div className="uk-form-label uk-float-right userDetailsRightAligned">
                      {" "}
                      Region:{" "}
                      <b>{submission.user ? submission.user.region : ""}</b>
                    </div>
                    <div className="uk-form-label userDetailsLeftAligned">
                      {" "}
                      Submitted by:{" "}
                      <b>
                        {submission.user
                          ? submission.user.firstName +
                            " " +
                            submission.user.lastName
                          : ""}
                      </b>
                    </div>
                    <div className="uk-form-label uk-float-right userDetailsRightAligned">
                      {" "}
                      Country:{" "}
                      <b>{submission.user ? submission.user.country : ""}</b>
                    </div>

                    <div className="uk-form-label userDetailsLeftAligned">
                      {" "}
                      Email:{" "}
                      <b>{submission.user ? submission.user.email : ""}</b>
                    </div>
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">
                    {questions
                      ? questions.length > 0
                        ? questions.map((q, index) => (
                            <div className="examSubmissionQDiv">
                              <div
                                className="uk-form-label"
                                style={{
                                  fontWeight: "500",
                                  textAlign: "justify",
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {index + 1}. {q.question}
                              </div>
                              <div
                                className="uk-form-label"
                                style={{
                                  margin: "0rem 1.5rem",
                                  textAlign: "justify",
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <br />
                                <b style={{color: 'green'}}>Answer:</b> <br />
                                {q.answer}
                              </div>
                              {answerCommentBoxEnabled != index ? (
                                !q.comment ? (
                                  <span
                                    className="addAnswerCommentButton1"
                                    onClick={() => this.addAnswerComment(index)}
                                  >
                                    + Add Comment
                                  </span>
                                ) : null
                              ) : (
                                <div className="newCommentBox2">
                                  <textarea
                                    value={this.state.answerComment || ""}
                                    className="uk-textarea commentTextArea2"
                                    rows="2"
                                    id="commentText"
                                    name="commentText"
                                    onChange={(e) =>
                                      this.handleAnswerCommentChange(e)
                                    }
                                    placeholder={
                                      localStorage.FIRST_NAME +
                                      " " +
                                      localStorage.LAST_NAME +
                                      " " +
                                      "says..."
                                    }
                                  ></textarea>
                                  <i
                                    class="fa fa-paper-plane sendIcon"
                                    aria-hidden="true"
                                    uk-tooltip= "Add Comment"
                                    onClick={() =>
                                      this.submitAnswerComment(index)
                                    }
                                  ></i>
                                </div>
                              )}

                              {/* ANSWER COMMENTS START */}

                              {q.comment ? (
                                <div className="commentMainDivAnswer">
                                  <div className="commentUserReviewer">
                                    {q.comment.name}{" - SE Hub"}
                                  </div>

                                  <div className="commentDate">
                                    {moment(q.comment.date)
                                      .utc()
                                      .format("llll")}{" "}
                                    GMT
                                    {this.state.commentInEditingAnswer ===
                                    index ? (
                                      <span
                                        className="commentButton"
                                        onClick={() =>
                                          this.cancelEditAnswerComment(
                                            index,
                                            q.comment.text
                                          )
                                        }
                                      >
                                        Cancel Edit
                                      </span>
                                    ) : (
                                      <span
                                        className="commentButton"
                                        onClick={() =>
                                          this.editAnswerComment(
                                            index,
                                            q.comment.text
                                          )
                                        }
                                      >
                                        Edit
                                      </span>
                                    )}
                                  </div>
                                  {this.state.commentInEditingAnswer ===
                                  index ? (
                                    <React.Fragment>
                                      <textarea
                                        value={
                                          this.state.changeCommentTextAnswer ||
                                          ""
                                        }
                                        className="uk-textarea commentTextArea"
                                        rows="2"
                                        id="changeCommentTextAnswer"
                                        name="changeCommentTextAnswer"
                                        onChange={(e) =>
                                          this.changeAnswerComment(e)
                                        }
                                      ></textarea>
                                      {this.state.enableSubmitButtonAnswer ===
                                      true ? (
                                        <i
                                          class="fa fa-paper-plane sendIcon"
                                          aria-hidden="true"
                                          uk-tooltip= "Add Comment"
                                          onClick={() =>
                                            this.submitChangeAnswerComment(
                                              index
                                            )
                                          }
                                        ></i>
                                      ) : null}
                                    </React.Fragment>
                                  ) : (
                                    <div className="commentText">
                                      {q.comment.text}
                                    </div>
                                  )}
                                  {q.comment.updated ? (
                                    q.comment.updated != "" ? (
                                      <div className="commentEditedText">
                                        Edited
                                      </div>
                                    ) : null
                                  ) : null}
                                </div>
                              ) : null}

                              {/* ANSWER COMMENTS END */}

                              <hr style={{ marginTop: "4.2rem" }} />
                            </div>
                          ))
                        : null
                      : null}
                  </div>
                  <text className="examMarksText">
                    <b>Rate the comment: </b>
                  </text>
                  <input
                    value={grade || ""}
                    className="uk-input"
                    rows="1"
                    id="grade"
                    name="grade"
                    type="number"
                    onChange={(e, data) => this.handleGradeChange(e, data)}
                    style={{
                      width: "5rem",
                      height: "2rem",
                      textAlign: "right",
                      fontSize: "0.8rem",
                    }}
                  ></input>
                  <text className="examMarksText2">/100</text>
                  {submission ? (
                    submission.grade != "" || submission.grade != null ? (
                      <Button
                        className="uk-button uk-margin saveGradeButton"
                        type="button"
                        disabled={this.state.grade != "" && this.state.grade != null ? false : true}
                        onClick={() => this.handleGradeSubmit()}
                      >
                        Save Grade
                      </Button>
                    ) : (
                      <Button
                        className="uk-button uk-margin saveGradeButton"
                        type="button"
                        disabled={this.state.grade != "" && this.state.grade != null ? false : true}
                        onClick={() => this.handleGradeSubmit()}
                      >
                        Save Grade
                      </Button>
                    )
                  ) : (
                    <Button
                      className="uk-button uk-margin saveGradeButton"
                      type="button"
                      disabled={this.state.grade != "" && this.state.grade != null ? false : true}
                      onClick={() => this.handleGradeSubmit()}
                    >
                      Save Grade
                    </Button>
                  )}
                  {
                    this.state.gradeError != "" ?
                      <div className="danger_alert messageCustom">
                      {this.state.gradeError}
                    </div>
                    : 
                    formErrorStatus.status ? (
                    <div className="danger_alert">
                      {formErrorStatus.message}
                    </div>
                  ) : formSuccessState.status ? (
                    <div className="success_alert messageCustom">
                      {formSuccessState.message}{" "}
                    </div>
                  ) : (
                    ""
                  )}

                  <br />
                  <div
                    ref={this.addNewCommentRef}
                    className="uk-card-header uk-text-bold"
                  >
                    <text className="commentTitle">
                      Comments -{" "}
                      {comments
                        ? comments.length > 0
                          ? comments.length
                          : 0
                        : 0}
                    </text>
                  </div>
                  <div className="newCommentBox">
                    <text>
                      {/* {localStorage.FIRST_NAME} {localStorage.LAST_NAME} */}
                      Add New Comment
                    </text>
                    <textarea
                      value={commentText || ""}
                      className="uk-textarea commentTextArea"
                      rows="2"
                      id="commentText"
                      name="commentText"
                      onChange={(e) => this.handleCommentChange(e)}
                      placeholder={
                        localStorage.FIRST_NAME +
                        " " +
                        localStorage.LAST_NAME +
                        " " +
                        "says..."
                      }
                    ></textarea>
                    {/* <button
                            // onClick={() => this.viewSubmission(sub.id)}
                            className="uk-button admin-table-btn reviewButtonExamSub"
                          >
                          </button> */}
                    <i
                      class="fa fa-paper-plane sendIcon"
                      uk-tooltip= "Add Comment"
                      aria-hidden="true"
                      onClick={() => this.addNewComment()}
                    ></i>
                  </div>
                  {comments
                    ? comments.length > 0
                      ? comments
                          .slice(0)
                          .reverse()
                          .map((comment, cIndex) => (
                            <div className="commentMainDiv">
                              {comment.addedBy.userType === "Reviewer" ? (
                                <div className="commentUserReviewer">
                                  {comment.addedBy.name}{" - SE Hub"}
                                </div>
                              ) : (
                                <div className="commentUser">
                                  {comment.addedBy.name}{" "}
                                </div>
                              )}

                              <div className="commentDate">
                                {moment(comment.addedBy.date)
                                  .utc()
                                  .format("llll")}{" "}
                                GMT
                                {comment.addedBy.userId ===
                                localStorage.USER_ID ? (
                                  commentInEditing != -1 ? (
                                    commentInEditing === cIndex ? (
                                      <span
                                        className="commentButton"
                                        onClick={() =>
                                          this.cancelEditComment(cIndex)
                                        }
                                      >
                                        Cancel Edit
                                      </span>
                                    ) : (
                                      <span
                                        className="commentButton"
                                        onClick={() =>
                                          this.editComment(cIndex, comment.text)
                                        }
                                      >
                                        Edit
                                      </span>
                                    )
                                  ) : (
                                    <React.Fragment>
                                      {confirmDelete != cIndex ? (
                                        <React.Fragment>
                                          <span
                                            className="commentButton"
                                            onClick={() =>
                                              this.editComment(
                                                cIndex,
                                                comment.text
                                              )
                                            }
                                          >
                                            Edit
                                          </span>
                                          <span
                                            className="commentButton"
                                            onClick={() =>
                                              this.requestDeleteComment(cIndex)
                                            }
                                          >
                                            Delete
                                          </span>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <span
                                            className="commentButtonRed"
                                            onClick={() =>
                                              this.confirmDeleteComment(cIndex)
                                            }
                                          >
                                            Confirm Delete
                                          </span>
                                          <span
                                            className="commentButtonBlue"
                                            onClick={() =>
                                              this.cancelDeleteComment(cIndex)
                                            }
                                          >
                                            Cancel
                                          </span>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )
                                ) : null}
                              </div>
                              {cIndex != commentInEditing ? (
                                <div className="commentText">
                                  {comment.text}
                                </div>
                              ) : (
                                <React.Fragment>
                                  <textarea
                                    value={changeCommentText || ""}
                                    className="uk-textarea commentTextArea"
                                    rows="2"
                                    id="changeCommentText"
                                    name="changeCommentText"
                                    onChange={(e) => this.changeComment(e)}
                                  ></textarea>
                                  {enableSubmitButton === true ? (
                                    <i
                                      class="fa fa-paper-plane sendIcon"
                                      uk-tooltip= "Add Comment"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.submitChangeComment(cIndex)
                                      }
                                    ></i>
                                  ) : null}
                                </React.Fragment>
                              )}
                              {comment.updated ? (
                                comment.updated != "" ? (
                                  <div className="commentEditedText">
                                    Edited
                                  </div>
                                ) : null
                              ) : null}
                              <hr />
                            </div>
                          ))
                      : null
                    : null}
                  <br />

                  <br />
                  <br />
                </div>

                {/* {formErrorStatus.status ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                ) : formSuccessState.status ? (
                  <div className="messageCustom" >
                    {formSuccessState.message}{" "}
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SingleExamSubmission = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleExamSubmission);

export default withApollo(
  reduxForm({ form: "fieldArrays" })(SingleExamSubmission)
);
