import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_OTHER_STATS } from "../../queries/admin_queries";
import NoDataMessage from '../messages/contentNotFound/NoDataFoundSmall';
import Circle from "react-circle";

var _ = require('underscore');
const mapStateToProps = (state, ownProps) => {
  return {};
};

class NewsStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        newsStatList: [],
        news: [],

        newsUserList: "",
        newsUserCount: "",
        totalViewsNews: 0,

        fontColors: ['#3c5e91', '#ff5274'],

        fromDate: this.props.fromDate,
        toDate: this.props.toDate, 
    };
  }

  
  componentDidMount() {
    this.getOtherStats(this.state.fromDate, this.state.toDate).then((result) => {
      var temp = [];
      var newsStats = [];
      var userListNews = "";
      var newsUserCount = 0;
      var totalViewsNews = 0;
      var checkedList = [];

      if (result.news.length > 0) {
        if (result.newsStatList.length > 0) {
            result.newsStatList.slice(0).reverse().map(r => (
                temp = Object.values(result.news).filter(obj => obj.id === r.newsId),
                temp.length > 0 ?
                checkedList.includes(r.newsId) != true  ?
                    newsStats.push({newsTitle: temp[0].newsTitle, clickCount: r.clickCount, createdBy: r.createdBy})
                    :null
                    : null,
                totalViewsNews = totalViewsNews + r.clickCount,
                checkedList.push(r.newsId)
            ))
            userListNews = (_.countBy(newsStats, function(newsStats) { return newsStats.createdBy; }));
            newsUserCount = Object.keys(userListNews).length  
      }
    }

      this.setState({
        newsStatList: newsStats,
        newsUserList: userListNews,
        totalViewsNews: totalViewsNews,
        newsUserCount: newsUserCount,
        totalUsers: result.totalUsers,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate) {
      this.getOtherStats(this.props.fromDate, this.props.toDate).then((result) => {
        var temp = [];
        var newsStats = [];
        var userListNews = "";
        var newsUserCount = 0;
        var totalViewsNews = 0;

        if (result.news.length > 0) {
            if (result.newsStatList.length > 0) {
                result.newsStatList.map(r => (
                    temp = Object.values(result.news).filter(obj => obj.id === r.newsId),
                    temp.length > 0 ?
                        newsStats.push({newsTitle: temp[0].newsTitle, clickCount: r.clickCount, createdBy: r.createdBy})
                    : null,
                    totalViewsNews = totalViewsNews + r.clickCount
                ))
                userListNews = (_.countBy(newsStats, function(newsStats) { return newsStats.createdBy; }));
                newsUserCount = Object.keys(userListNews).length  
        }
        }

        this.setState({
            newsStatList: newsStats,
            newsUserList: userListNews,
            totalViewsNews: totalViewsNews,
            newsUserCount: newsUserCount,
        });
      });
    }
  }

  getOtherStats = async (from, to) => {
    const result = await this.props.client.query({
      query: GET_OTHER_STATS,
      variables: {fromDate: from, toDate: to},
      fetchPolicy: "network-only",
    });
    return result.data.getOtherStats;
  };

  render() {
    const {} = this.props;
    const {
        newsStatList,
        news,
        fontColors,
        totalUsers,
        newsUserList,
        newsUserCount,
        totalViewsNews,

        fromDate,
        toDate,

    } = this.state;
    return (
      <div className="">
        <h4 className="statTitleMain">News</h4>
        <hr />
        <table style={{width: '100%', backgroundColor: '#E1E8ED', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <tbody>
                <tr>
                    <td style={{width: '60%', padding: '0.8rem', verticalAlign:'top'}}>
                        <div style={{width: '100%', backgroundColor: '#E1E8ED', textAlign: 'left'}}>
                            <h5 className="resStatTitle">Individual News Visits</h5>
                            <div>
                                <table style={{width: '100%'}}>
                                <thead style={{color: '#6e230e', fontSize: '0.9rem'}}>
                                  <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th style={{textAlign: 'right'}}>Visits</th>
                                  </tr>
                                </thead>
                                    <tbody>
                                        {
                                            newsStatList.length > 0 ?
                                            newsStatList.map((stat, index) => (
                                              index + 1 <=3 ?
                                              newsStatList.length > 3 ?
                                                <tr>
                                                    <td style={{width: '2rem', fontSize: '0.9rem', fontWeight: 'bold'}}>{index + 1}.</td>
                                                    <td style={{width: '', textAlign: 'left', fontSize: '0.9rem', fontWeight: 'bold'}}>{stat.newsTitle}</td>
                                                    <td style={{width: '2rem', textAlign: 'right', fontSize: '1rem', fontWeight: 'bold', color: '#2d9e00'}}>{stat.clickCount}</td>
                                                </tr>
                                              :
                                              <tr>
                                                <td style={{width: '2.5rem'}}>{index + 1}.</td>
                                                <td style={{width: '', textAlign: 'left', fontSize: '0.8rem'}}>{stat.newsTitle}</td>
                                                <td style={{width: '2rem', textAlign: 'right',}}>{stat.clickCount}</td>
                                              </tr>
                                              :
                                              <tr>
                                                <td style={{width: '2.5rem'}}>{index + 1}.</td>
                                                <td style={{width: '', textAlign: 'left', fontSize: '0.8rem'}}>{stat.newsTitle}</td>
                                                <td style={{width: '2rem', textAlign: 'right',}}>{stat.clickCount}</td>
                                              </tr>
                                            ))
                                        : 
                                        <tr>
                                            <td colspan='3' style={{width: '2rem', fontSize: '0.9rem', fontWeight: 'bold'}}>
                                              <NoDataMessage message="No statistics found for the selected duration" fontSize="0.8rem" />
                                            </td>
                                        </tr>
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                    <td style={{width: '40%', padding: '0.8rem'}}>
                      <div style={{textAlign: 'center', width: '50%', float: 'left'}}>
                          <text style={{fontSize: '4rem', color: fontColors[0]}}>{totalViewsNews}</text>
                          <br />
                          <text style={{fontSize: '1.2rem', fontWeight: '300'}}>Total Visits</text> 
                      </div>
                      <div style={{textAlign: 'center', width: '50%', float: 'left'}}>
                          <text style={{fontSize: '4rem', color: fontColors[1]}}>{Math.round(totalViewsNews/newsUserCount) || 0}</text>
                          <text style={{fontSize: '1rem'}}>/ per user</text> 

                          <br />
                          
                          <text style={{fontSize: '1.2rem', fontWeight: '300'}}>Average Visits</text> 
                          <br />
                          
                      </div>
                      <br /><br /><br /><br /><br />
                      <div style={{width: '100%'}}>
                      <div className="fitCenterDiv" style={{paddingTop: '1rem'}}>
                          <Circle
                            animate={true}
                            animationDuration="3s"
                            size={120}
                            lineWidth={50}
                            progress={Math.round(newsUserCount/totalUsers*100) || 0}
                            progressColor="#3e9679"
                            bgColor="white"
                            // textColor="white"
                            textStyle={{
                              fontSize: "6.5rem",
                              fontWeight: "500",
                            }}
                          />
                          <br />

                      </div>
                      <div className="fitCenterDiv">
                        <text style={{fontSize: '1.1rem', fontWeight: '300', textAlign: 'center'}}>Average User Views</text> 
                      </div>
                      </div>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(NewsStats)));
