import React, { Component } from "react";
import About from '../../user/aboutus/aboutus';
import Contact from '../../user/contact/contact';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Image, Dropdown } from 'semantic-ui-react';
import { Carousel } from "react-responsive-carousel";
import { Button } from "react-uikit";
import moment from "moment";
import { isLoggedIn } from "../../../services/CacheOperations";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import BannerImg from "../../../assets/images/backgrounds/kms-image-ori.png";
import ForgotPassword from '../../../components/forgotPassword/forgot_password';
import LogoEncyte from '../../../assets/images/logo.png'
import { ippfauthtoken, USER_ID, EMAIL, FIRST_NAME, LAST_NAME, USER_TYPE } from '../../../constants/genarics';
import AnnouncementLine from "../../../components/announcementLine/announcementLine";
import { GET_ANNOUNCEMENTS } from "../../../queries/common_queries";
import CloseIcon from '../../../images/close.png';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import NoDataMessage from '../../../components/messages/contentNotFound/AnnouncementMessage';
import { withNamespaces } from 'react-i18next';
import i18n from '../../../i18n';
import { EDIT_BASIC_PROFILE } from "../../../queries/user_queries";
import { formatDate } from '../../../middleware/index';

import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ar';
import Moment from 'react-moment';

import logo1 from '../../../images/1_SE_IPPF_logo_1.png';
import logo2 from '../../../images/2_SE_IPPF_logo_old_edited_color.png';

const languageOptions = [
  { text: "English", value: "en", key: "en" },
  { text: "العربية", value: "ar", key: "ar" },
  { text: "Français", value: "fr", key: "fr" },
  { text: "Español", value: "sp", key: "sp" },
];

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
        : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
          : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
            : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    this.state = {
      announcements: [],
      blurrModalOpen: false,
    }
  }

  componentDidMount() {
    this.get_all_announcements().then((result) => {
      console.log(result);
      if (result.length > 0) {
        this.setState({
          announcements: result,
        });
      }
    });
  }

  get_all_announcements = async () => {
    const result = await this.props.client.query({
      query: GET_ANNOUNCEMENTS,
      variables: {
        lang: localStorage.LANG,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        isActive: true,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getAnnouncements.announcements;
  };
  logout = () => {
    setTimeout(() => {
      localStorage.removeItem(ippfauthtoken);
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(EMAIL);
      localStorage.removeItem(FIRST_NAME);
      localStorage.removeItem(LAST_NAME);
      localStorage.removeItem(USER_TYPE);
      {/* login route changed */ }
      this.props.history.push("/");
    }, 500)
  }

  redirectToRegister = () => {
    this.props.history.push('/registration');
  }

  navigate = (path) => {
    console.log(path);
    this.props.history.push(path);
  }

  // changeLanguage = async (e, { value }, data) => {
  //   localStorage.LANG = value;
  //   i18n.changeLanguage(value);
  //   window.location.reload();
  // };

  changeLanguage = async (e, { value }, data) => {
    localStorage.LANG = value;
    if (localStorage.USER_ID != undefined) {
      this.update_user_language(value).then((result) => {
        i18n.changeLanguage(value);
        window.location.reload();
      });
    }
    else {
      i18n.changeLanguage(value);
      window.location.reload();
    }
  };

  update_user_language = async (lang) => {
    const result = await this.props.client.mutate({
      mutation: EDIT_BASIC_PROFILE,
      variables: { userId: localStorage.USER_ID, languagePreference: lang }
    });
    return;
  };

  openSiteMap = (status) => {
    this.setState({ blurrModalOpen: status })
  }

  render() {
    const { announcements, blurrModalOpen } = this.state;
    const { t } = this.props;
    var es_date = "";
    var date_sp = announcements.map((an_es) => (
      es_date = an_es.date));
    var localLocale = moment(es_date);
    moment.locale('es');
    localLocale.locale(false);

    console.log(es_date)


    return (
      <div>
        {/* Begin Main */}
        <div id="spinneroverlay">
          <div className="spinner"></div>
        </div>
        {/* End Main */}

        {/* Begin Mobile Navbar */}
        <div className="tm-mobile-header uk-hidden@m">
          <nav className="uk-navbar-container uk-navbar" uk-navbar="">
            <div className="uk-navbar-left">
              <a className="uk-hidden@m uk-logo black-logo" href="/">
                {/* <Image className="ippf-logo" src={require('../../../assets/images/ippf-logo.png')} fluid /> */}

                <Image className="ippf-logo" 
                // src={require('../../../assets/images/1_SE_IPPF_logo_1.png')}
                src={logo1} 
                fluid />
                <Image className="ippf-logo" 
                // src={require('../../../assets/images/2_SE_IPPF_logo_old_edited_color.png')}
                src={logo2}
                fluid />

              </a>
            </div>
            <div className="uk-navbar-right">
              <a className="uk-navbar-toggle" href="#tm-mobile" uk-toggle="true">
                <div className="uk-navbar-toggle-icon">
                  <i className="far fa-bars icon-large uk-text-black"></i>
                </div>
              </a>
            </div>
          </nav>
          <div id="tm-mobile" className="uk-modal-full uk-modal" uk-modal="true">
            <div
              className="uk-modal-dialog uk-modal-body uk-text-center uk-flex"
              uk-height-viewport="true"
            >
              <button
                className="uk-modal-close-full uk-close uk-icon"
                type="button"
                uk-close="true"
              ></button>
              <div className="uk-margin-auto-vertical uk-width-1-1">
                <div className="uk-child-width-1-1" uk-grid="true">
                  <div>
                    <div className="uk-panel">
                      <ul className="uk-nav uk-nav-primary uk-nav-center nav-white">
                        <li>
                          <a href="">Home</a>
                        </li>
                        <li>
                          <a href="">About</a>
                        </li>
                        <li className="uk-active">
                          <a href="">Instructions</a>
                        </li>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div className="uk-panel widget-text" id="widget-text-1">
                      <div className="textwidget">
                        <p className="uk-text-center">
                          <a
                            className="uk-button uk-button-success uk-button-large"
                            href="../main/Homepage.html"
                          >
                            {" "}
                            My Account{" "}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="uk-panel widget-text" id="widget-text-1">
                      <div className="textwidget">
                        <p className="uk-text-center">
                          <a
                            className="uk-button uk-button-success uk-button-large"
                            href="../main/Homepage.html"
                          >
                            {" "}
                            My Account{" "}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Mobile NavBar */}

        {/* Begin NavBar */}
        <div className="uk-container loginNavBar">
          {
            localStorage.LANG == "ar" ?
              (
                <nav className="paddingHeader uk-navbar uk-navbar-transparent">

                  <div className="lineHeight uk-navbar-right">
                    <ul className=" uk-navbar-nav toolbar-nav">

                      {/* Uncomment to enable language switcher in user site */}

                      <li className="uk-text-capitalize">
                        <div style={{ position: "relative" }}>
                          <Dropdown
                            className="languageSwitcherLogin"
                            options={languageOptions}
                            value={localStorage.LANG}
                            onChange={this.changeLanguage}
                          />
                          <i
                            class="fa fa-caret-down langDropdownIcon"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </li>

                      {isLoggedIn() ? (
                        <li style={{ marginRight: "60px" }} className="uk-text-uppercase">
                          <a href="/home"> {t('home')}</a>
                        </li>
                      ) : (
                        ""
                      )}
                      {
                        isLoggedIn() ? (
                          <li >
                            <a href="/faq" target="_blank"> {t('faq')} </a>
                          </li>
                        ) :
                          (
                            <li style={{ marginRight: "60px" }}>
                              <a href="/faq" target="_blank"> {t('faq')} </a>
                            </li>
                          )
                      }

                      <li className="uk-text-uppercase">
                        <a href="/#aboutus_section"> {t('about')} </a>
                      </li>
                      <li className="uk-text-uppercase">
                        <a href="/contact" target="_blank">{t('contact')}</a>
                      </li>

                      {isLoggedIn() ? (
                        <li className="uk-text-uppercase">
                          <a onClick={() => this.logout()}>{t('logout')}</a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>

                    {isLoggedIn() ? (
                      ""
                    ) : (
                      <a
                        className="fontstyle el-content uk-button uk-button-success uk-circle"
                        href="/registration"
                      >
                        {" "}
                        {t('register')}
                      </a>
                    )}
                  </div>
                  <div className="uk-navbar-left">
                    <a style={{float:"left"}} href="#" className="uk-logo black-logo">
                      {/* <Image
                        className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                        src={require("../../../images/SE_IPPF_logo.png")}
                        fluid
                      /> */}

                      <Image
                        className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                        // src={require("../../../images/1_SE_IPPF_logo_1.png")}
                        src={logo1}
                        fluid
                        style={{marginTop:"14.5px"}}
                      />
                      <Image
                        style={{float:"right", paddingBottom:"2px"}}
                        className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                        // src={require("../../../images/2_SE_IPPF_logo_old_edited_color.png")}
                        src={logo2}
                        fluid
                      />

                    </a>
                  </div>
                </nav>
              ) : (
                <nav className="paddingHeader uk-navbar uk-navbar-transparent">
                  <div className="uk-navbar-left">
                    <a href="#" className="uk-logo black-logo">
                      {/* <Image
                        className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                        src={require("../../../images/SE_IPPF_logo.png")}
                        fluid
                      /> */}

                      <Image
                        className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                        // src={require("../../../images/1_SE_IPPF_logo_1.png")}
                        src={logo1}
                        fluid
                      />

                      <Image
                        float="right"
                        className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                        // src={require("../../../images/2_SE_IPPF_logo_old_edited_color.png")}
                        src={logo2}
                        fluid
                      />
                    </a>
                  </div>
                  <div className="lineHeight uk-navbar-right">
                    <ul className=" uk-navbar-nav toolbar-nav">

                      {/* Uncomment to enable language switcher in user site */}

                      <li className="uk-text-capitalize">
                        <div style={{ position: "relative" }}>
                          <Dropdown
                            className="languageSwitcherLogin"
                            options={languageOptions}
                            value={localStorage.LANG}
                            onChange={this.changeLanguage}
                          />
                          <i
                            class="fa fa-caret-down langDropdownIcon"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </li>

                      {isLoggedIn() ? (
                        <li className="uk-text-uppercase">
                          <a href="/home"> {t('home')}</a>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <a href="/faq" target="_blank"> {t('faq')} </a>
                      </li>
                      <li className="uk-text-uppercase">
                        <a href="/#aboutus_section"> {t('about')} </a>
                      </li>
                      <li className="uk-text-uppercase">
                        <a href="/contact" target="_blank">{t('contact')}</a>
                      </li>

                      {isLoggedIn() ? (
                        <li className="uk-text-uppercase">
                          <a onClick={() => this.logout()}>{t('logout')}</a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>

                    {isLoggedIn() ? (
                      ""
                    ) : (
                      <a
                        className="fontstyle el-content uk-button uk-button-success uk-circle"
                        href="/registration"
                      >
                        {" "}
                        {t('register')}
                      </a>
                    )}
                  </div>
                </nav>
              )
          }

        </div>
        {/* End NavBar */}

        {/* Begin Router */}
        <Router>
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
        </Router>

        {/* End Router */}

        {/* Begin Header  */}
        <div className="loginSection">
          <br />
          {
            localStorage.LANG == "ar" ?
              (
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "30%", padding: "10px" }}>
                        <div className="loginBox heightLogin">
                          <ForgotPassword lang={localStorage.LANG} />
                        </div>
                      </td>
                      <td style={{ width: "70%" }}>
                        <div className="loginBoxEmpty"></div>
                        <h1 style={{ paddingRight: "290px" }} className="loginSectionSiteTitle">{t('site_title')}</h1>
                      </td>

                    </tr>
                  </tbody>
                </table>
              ) : (
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <div className="loginBoxEmpty"></div>
                        {/* style={{paddingRight:"510px"}} */}
                        <h1 className="loginSectionSiteTitle">{t('site_title')}</h1>
                      </td>
                      <td style={{ width: "30%", padding: "10px" }}>
                        <div className="loginBox heightLogin">
                          <ForgotPassword />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )
          }
        </div>

        <div className="loginAnnouncementLine">
          <AnnouncementLine />
        </div>

        <div className="uk-container uk-overflow-auto loginSectionOne">
          {
            localStorage.LANG == "ar" ?
              (
                <table style={{ width: "100%" }} class="uk-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "60%" }}>
                        <div className="" style={{ paddingTop: "4.5rem" }}>
                          <div>
                            <h4
                              style={{
                                color: "#00A9E0",
                                fontWeight: "bold",
                                color: "#1A2674",
                                textAlign: "center",
                              }}
                            >
                              {t('sectionOne_titleOne')}
                            </h4>
                          </div>
                          <div>
                            <p style={styles.Align}>
                              <div dangerouslySetInnerHTML={{ __html: t('sectionOne_descriptionOne') }} />
                            </p>
                          </div>

                          <div>
                            <h4
                              style={{
                                color: "#00A9E0",
                                fontWeight: "bold",
                                color: "#1A2674",
                                textAlign: "center",
                              }}
                            >
                              {t('sectionOne_titleTwo')}
                            </h4>
                          </div>
                          <div>
                            <p style={styles.Align}>
                              <div dangerouslySetInnerHTML={{ __html: t('sectionOne_descriptionTwo') }} />
                            </p>
                          </div>
                        </div>
                      </td>

                      <td style={{ width: "40%", textAlign: "align", paddingTop:"0px",paddingBottom:"0px" }}>
                        <div className="">
                          <div
                            className="announcementSectionLogin"
                            style={{ padding: "0rem 0rem", textAlign: "center" }}
                          >
                            <h4 style={{ color: "#00A9E0", fontWeight: "bold" }}>
                              {t('announcementTitle')}
                            </h4>
                            <div className="announcementBox">
                              <Carousel
                                className="announcementCarousel carousel_Arabic"
                                autoPlay
                                showThumbs={false}
                                showIndicators={false}
                                showStatus={false}
                                style={{ height: "100%" }}
                                infiniteLoop
                                stopOnHover
                                interval={10000}
                              >
                                {announcements
                                  ? announcements.length > 0
                                    ? announcements.map((an) => (
                                      <div className="carouselDiv_ar">
                                        <div className="annTitleDiv_ar">
                                          <text dir="rtl">{an.title}</text>
                                          <br></br>
                                          <div display='flex' style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "normal",
                                          }}>
                                            {/* <text
                                     
                                    > */}


                                            <text style={{ float: "left", paddingLeft: "5rem" }}>
                                              {/* {moment(an.date, "YYYY-MM-DD").format(
                                                  "DD MMM YYYY"
                                                )} */}
                                              {/* {formatDate(an.date)} */}
                                              {/* {moment(an.date, "YYYY-MM-DD").format("YYYY MMM DD")} */}

                                              {/* //January 10th */}
                                              {localStorage.LANG == 'sp' ? (
                                                <div class="">
                                                  {moment(an.date).locale('es').format('ll')}
                                                  {/* {localLocale.format('ll')} */}
                                                </div>
                                              ) : (
                                                <Moment direction="rtl" locale='Ar' format="DD MMM YYYY">{an.date}</Moment>

                                                // <Moment locale={localStorage.LANG == "ar" ? "Ar" : localStorage.LANG == "fr" ? "Fr" : "en-gb"} format="YYYY MMM DD">{an.date}</Moment>

                                              )}

                                            </text>
                                            <text style={{ paddingRight: "3.5rem" }}>:{t('published_on')}</text>
                                            {/* </text> */}
                                          </div>

                                        </div>

                                        <div className="annDescDiv" dir="rtl">
                                          <text>{an.description}</text>
                                        </div>
                                        {an ? (
                                          an.showExpDate === true ? (
                                            <div className="annExpDateDiv_ar" >
                                              <text>
                                                <b>
                                                {t('announcement_expiration')}:{" "}
                                                  {/* {moment(
                                                        an.endDate,
                                                        "YYYY-MM-DD"
                                                      ).format("YYYY MMM DD")} */}

                                                  {/* //January 10th */}
                                                  {localStorage.LANG == 'sp' ? (
                                                    <div class="">
                                                      {moment(an.endDate).locale('es').format('ll')}
                                                      {/* {localLocale.format('ll')} */}
                                                    </div>
                                                  ) : (
                                                    <Moment direction="rtl" locale='Ar' format="DD MMM YYYY">{an.date}</Moment>

                                                    // <Moment locale={localStorage.LANG == "ar" ? "Ar" : localStorage.LANG == "fr" ? "Fr" : "en-gb"} format="YYYY MMM DD">{an.endDate}</Moment>
                                                  )}

                                                </b> 
                                                {/* :
                                                {t('announcement_expiration')}{" "} */}
                                              </text>
                                            </div>
                                          ) : null
                                        ) : null}
                                        {an.url ? (
                                          <div className="annBtnDiv">
                                            <div
                                              style={{
                                                width: "fit-content",
                                                margin: "0 auto",
                                                marginTop: "1rem",
                                              }}
                                            >
                                              <Button
                                                className="uk-button annYellowButton"
                                                type="submit"
                                                onClick={() =>
                                                  this.openAnnouncement(an.url)
                                                }
                                              >
                                                {t('read_more')}
                                              </Button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    ))
                                    : <NoDataMessage message={t('nill_view_announcement')} fontSize="0.95rem" />
                                  : <NoDataMessage message={t('nill_view_announcement')} fontSize="0.95rem" />}
                              </Carousel>
                            </div>
                          </div>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              ) : (
                <table style={{ width: "100%" }} class="uk-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "40%", textAlign: "align",  paddingTop:"0px",paddingBottom:"0px" }}>
                        <div className="">
                          <div
                            className="announcementSectionLogin"
                            style={{ padding: "0rem 0rem", textAlign: "center" }}
                          >
                            <h4 style={{ color: "#00A9E0", fontWeight: "bold" }}>
                              {t('announcementTitle')}
                            </h4>
                            <div className="announcementBox">
                              <Carousel
                                className="announcementCarousel"
                                autoPlay
                                showThumbs={false}
                                showIndicators={false}
                                showStatus={false}
                                style={{ height: "100%" }}
                                infiniteLoop
                                stopOnHover
                                interval={10000}
                              >
                                {announcements
                                  ? announcements.length > 0
                                    ? announcements.map((an) => (
                                      <div className="carouselDiv">
                                        <div className="annTitleDiv">
                                          <text>{an.title}</text>
                                          <br></br>
                                          <text
                                            style={{
                                              fontSize: "0.8rem",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {/* {an.date} */}
                                            {t('published_on')} {" "}
                                            {/* {moment(an.date, "YYYY-MM-DD").format(
                                                  "DD MMM YYYY"
                                                )} */}
                                            {/* //January 10th */}
                                            {localStorage.LANG == 'sp' ? (
                                              <div class="">
                                                {moment(an.date).locale('es').format('ll')}
                                                {/* {localLocale.format('ll')} */}
                                              </div>
                                            ) : (
                                              <Moment locale={localStorage.LANG == "ar" ? "Ar" : localStorage.LANG == "fr" ? "Fr" : "en-gb"} format="DD MMM YYYY">{an.date}</Moment>
                                            )}
                                          </text>
                                        </div>

                                        <div className="annDescDiv">
                                          <text>{an.description}</text>
                                        </div>
                                        {an ? (
                                          an.showExpDate === true ? (
                                            <div className="annExpDateDiv">
                                              <text>
                                                {t('announcement_expiration')}{" "}
                                                <b>
                                                  {/* {moment(
                                                        an.endDate,
                                                        "YYYY-MM-DD"
                                                      ).format("DD MMM YYYY")} */}
                                                  {localStorage.LANG == 'sp' ? (
                                                    <div class="">
                                                      {moment(an.endDate).locale('es').format('ll')}
                                                      {/* {localLocale.format('ll')} */}
                                                    </div>
                                                  ) : (
                                                    <Moment locale={localStorage.LANG == "ar" ? "Ar" : localStorage.LANG == "fr" ? "Fr" : "en-gb"} format="DD MMM YYYY">{an.endDate}</Moment>
                                                  )}
                                                </b>
                                              </text>
                                            </div>
                                          ) : null
                                        ) : null}
                                        {an.url ? (
                                          <div className="annBtnDiv">
                                            <div
                                              style={{
                                                width: "fit-content",
                                                margin: "0 auto",
                                                marginTop: "1rem",
                                              }}
                                            >
                                              <Button
                                                className="uk-button annYellowButton"
                                                type="submit"
                                                onClick={() =>
                                                  this.openAnnouncement(an.url)
                                                }
                                              >
                                                {t('read_more')}
                                              </Button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    ))
                                    : <NoDataMessage message={t('nill_view_announcement')} fontSize="0.95rem" />
                                  : <NoDataMessage message={t('nill_view_announcement')} fontSize="0.95rem" />}
                              </Carousel>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "60%" }}>
                        <div className="" style={{ paddingTop: "4.5rem" }}>
                          <div>
                            <h4
                              style={{
                                color: "#00A9E0",
                                fontWeight: "bold",
                                color: "#1A2674",
                                textAlign: "center",
                              }}
                            >
                              {t('sectionOne_titleOne')}
                            </h4>
                          </div>
                          <div>
                            <p style={styles.Align}>
                              <div dangerouslySetInnerHTML={{ __html: t('sectionOne_descriptionOne') }} />
                            </p>
                          </div>

                          <div>
                            <h4
                              style={{
                                color: "#00A9E0",
                                fontWeight: "bold",
                                color: "#1A2674",
                                textAlign: "center",
                              }}
                            >
                              {t('sectionOne_titleTwo')}
                            </h4>
                          </div>
                          <div>
                            <p style={styles.Align}>
                              <div dangerouslySetInnerHTML={{ __html: t('sectionOne_descriptionTwo') }} />
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )
          }

        </div>
        {/* End Section 1 */}
        {/* <br />
        <br /> */}
        {/* Start Section 2 */}
        <div className="loginSectionTwo">
          <h2 className="image2Text" dangerouslySetInnerHTML={{ __html: t('login_image_2_text') }} />
        </div>
        {/* End Section 2 */}
        <br />
        <br />

        {/* Start Section 3 */}
        <div className="uk-container" style={{ display: "flex" }}>
          {localStorage.LANG == "ar" ? (
            <div >
              <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
                <div>
                  <h4 className="semantic-font" style={{ color: "#00A9E0" }}>
                    {t('sectionTwo_titleTwo')}
                  </h4>
                </div>
                <div>
                  <h4 className="semantic-font" style={{ color: "#00A9E0" }}>
                    {t('sectionTwo_titleOne')}
                  </h4>
                </div>

              </div>
              <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
                <p style={styles.Align}>
                  <div dangerouslySetInnerHTML={{ __html: t('sectionTwo_descriptionTwo') }} />
                </p>
                <p style={styles.Align}>
                  {t('sectionTwo_descriptionOne')}
                  <br />
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: t('para_two') }} />
                </p>

              </div>
            </div>
          ) : (
            <div >
              <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
                <div>
                  <h4 className="semantic-font" style={{ color: "#00A9E0" }}>
                    {t('sectionTwo_titleOne')}
                  </h4>
                </div>
                <div>
                  <h4 className="semantic-font" style={{ color: "#00A9E0" }}>
                    {t('sectionTwo_titleTwo')}
                  </h4>
                </div>
              </div>
              <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
                <p style={styles.Align}>
                  {t('sectionTwo_descriptionOne')}
                  <br />
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: t('para_two') }} />
                </p>
                <p style={styles.Align}>
                  <div dangerouslySetInnerHTML={{ __html: t('sectionTwo_descriptionTwo') }} />
                </p>
              </div>
            </div>
          )}

        </div>
        {/* End Section 3 */}

        {/* Begin Footer */}
        <div className="foot fontFamily uk-margin-medium-top">
          <hr className="uk-margin-remove" />
          <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative">
            {localStorage.LANG == "ar" ? (
              <div className="uk-grid ">

                <div className="uk-width-expand@m uk-width-1-1@s uk-flex  uk-flex-left">
                  <ul
                    style={{ fontSize: "14px" }}
                    className="uk-list tm-footer-list"
                  >
                    {/* login route changed */}
                    <li>
                      <a
                        style={{ textDecoration: "none" }}

                        href="/#aboutus_section"
                        data-uk-smooth-scroll="{offset: 190}"
                      >
                        {t('about')}
                      </a>
                    </li>
                    <li>
                      <a
                        name="manual"
                        href="https://seippf.org/userguide/ar"
                        // href="https://seippf.org/userguide/"

                        target="_blank"
                      >
                        {" "}
                        {t('user_manual')}
                      </a>
                    </li>

                    <li>
                      <a>
                        <div onClick={() => this.openSiteMap(true)}>
                          {t('site_map')}
                        </div>
                      </a>
                    </li>

                    <li>
                      <a href="/contact" target="_blank">
                        {t('contact')}
                      </a>
                    </li>
                    <li>
                      <a href="https://drive.google.com/file/d/1bVElETZIbuqv0IsQ-ULbltCh7xaLblqQ/view?usp=sharing" target="_blank">
                        {t('privacy')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="uk-width-2-3@m uk-width-1-2@s uk-first-column uk-margin-auto"
                  uk-height-match="target: SELECTOR"
                >
                  <p  style={{float:"left"}} className="uk-link-heading uk-text-lead uk-text-bold">
                    {/* <Image
                      className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                      src={require("../../../images/SE_IPPF_logo.png")}
                      fluid
                      style={{ float: "left" }}
                    /> */}
                     <Image
                      className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                      // src={require("../../../images/1_SE_IPPF_logo_1.png")}
                      src={logo1}
                      fluid
                      style={{ float: "left", marginTop:"14.5px" }}
                    />
                     <Image
                      className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                      // src={require("../../../images/2_SE_IPPF_logo_old_edited_color.png")}
                      src={logo2}
                      fluid
                      style={{float:"right", paddingBottom:"2px"}}
                    />
                  </p>
                </div>
              </div>
            ) : (
              <div className="uk-grid ">
                <div
                  className="uk-width-2-3@m uk-width-1-2@s uk-first-column uk-margin-auto"
                  uk-height-match="target: SELECTOR"
                >
                  <p className="uk-link-heading uk-text-lead uk-text-bold">
                    {/* <Image
                      className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                      src={require("../../../images/SE_IPPF_logo.png")}
                      fluid
                    /> */}
                    <Image
                      className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                      // src={require("../../../images/1_SE_IPPF_logo_1.png")}
                      src={logo1}
                      fluid
                    />
                    <Image
                      float="right"
                      className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                      // src={require("../../../images/2_SE_IPPF_logo_old_edited_color.png")}
                      src={logo2}
                      fluid
                    />
                  </p>
                </div>
                <div className="uk-width-expand@m uk-width-1-1@s uk-flex  uk-flex-right">
                  <ul
                    style={{ fontSize: "14px" }}
                    className="uk-list tm-footer-list"
                  >
                    {/* login route changed */}
                    <li>
                      <a
                        style={{ textDecoration: "none" }}

                        href="/#aboutus_section"
                        data-uk-smooth-scroll="{offset: 190}"
                      >
                        {t('about')}
                      </a>
                    </li>
                    <li>
                      <a
                        name="manual"
                        href={localStorage.LANG == "fr" ? "https://seippf.org/userguide/fr" : localStorage.LANG == "sp" ? "https://seippf.org/userguide/es" : "https://seippf.org/userguide/en"}
                        // href="https://seippf.org/userguide/"

                        target="_blank"
                      >
                        {" "}
                        {t('user_manual')}
                      </a>
                    </li>

                    <li>
                      <a>
                        <div onClick={() => this.openSiteMap(true)}>
                          {t('site_map')}
                        </div>
                      </a>
                    </li>

                    <li>
                      <a href="/contact" target="_blank">
                        {t('contact')}
                      </a>
                    </li>
                    <li>
                      <a href={localStorage.LANG == 'sp' ? "https://drive.google.com/file/d/17MF3-PtiGTQH03wriceSxx6jJc5mb-mt/view?usp=sharing" : localStorage.LANG == 'fr' ? "https://drive.google.com/file/d/1BHAd9w7PAU4ZCXFHAh2mqEB7JxjdI_A9/view?usp=sharing" : "https://drive.google.com/file/d/1t56KrWgscRbhCW7Q4PZPn8cdqiKLuZDi/view?usp=sharing"} target="_blank">
                        {t('privacy')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )
            }

          </div>
        </div>
        <div className="fontFamily footerCopyright uk-section-small">
          <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative">
            {localStorage.LANG == "ar" ? (
              <div
                style={{ fontSize: "14px" }}
                className="uk-grid uk-margin-small"
                uk-grid="true"
              >
                <div className="image uk-width-expand@m uk-width-1-2@s">
                  <p style={{ textAlign: "right" }}>
                    {" "}
                    {t('powered_by')}{" "}
                    <a href="https://encyte.io" target="_blank">
                      {" "}
                      <img src={LogoEncyte} />{" "}
                    </a>
                  </p>
                </div>
                <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                  <p>{t('version')} 1 . 3 . 0</p>
                  {/* <p>{t('version')} 1 . 1 . 0</p> */}

                </div>
                <div style={{ display: "flex" }} className="copyRightText uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                  {t('copyright')}
                  <div style={{ marginLeft: "3px", marginRight: "3px" }} className="uk-text-bold">{t('IPPF')}</div>
                  {t('All_rights_reserved')}
                </div>


              </div>
            ) : (
              <div
                style={{ fontSize: "14px" }}
                className="uk-grid uk-margin-small"
                uk-grid="true"
              >
                <div style={{ display: "flex" }} className={localStorage.LANG == "sp" ? "copyRightText_spanish uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto" : "copyRightText uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto"} >
                  {t('copyright')}
                  <div style={{ marginLeft: "3px", marginRight: "3px" }} className="uk-text-bold">{t('IPPF')}</div>
                  {t('All_rights_reserved')}
                </div>
                <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                  <p>{t('version')} 1 . 3 . 0</p>
                  {/* <p>{t('version')} 1 . 1 . 0</p> */}

                </div>
                <div className="image uk-width-expand@m uk-width-1-2@s">
                  <p style={{ textAlign: "right" }}>
                    {" "}
                    {t('powered_by')}{" "}
                    <a href="https://encyte.io" target="_blank">
                      {" "}
                      <img src={LogoEncyte} />{" "}
                    </a>
                  </p>
                </div>
              </div>
            )}

          </div>
        </div>

        {
          blurrModalOpen === true ? (
            <div style={styles.background}
            // onClick={() => this.openSiteMap(false)}
            >
              <div style={styles.background1}>
                <h3 className={localStorage.LANG == 'ar' ? "float" : ""}>{t('site_map')}</h3>
                <img onClick={() => this.openSiteMap(false)} className={localStorage.LANG == 'ar' ? " close_sitemap_ar leftFloat" : "close_sitemap"} src={CloseIcon} />
                <br />
                <br />
                <div className="fitCenterDiv">
                  <InnerImageZoom src={localStorage.LANG == 'ar' ? require("../../../images/sitemap_v_3_arabic.svg") : localStorage.LANG == 'fr' ? require("../../../images/sitemap_v_3_french_v2.svg") : localStorage.LANG == 'sp' ? require("../../../images/sitemap_v_3_spanish.svg") : require("../../../images/sitemap_v_3.svg")} />
                  {/* <img style={{height: 'auto', width: '100%', margin: '0 auto'}} src={SiteMap} /> */}
                </div>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

const styles = {
  darkBack: {
    backgroundImage: `url(${BannerImg})`,
    backgroundBlendMode: 'color-burn',
    width: '100%',
    height: '100%',
    backgroundPosition: '100% -30%'
  },
  poweredBy: {
    bottom: "8px",
    right: "60px"
  },
  Align: {
    textAlign: "justify",
    color: "black",
    fontSize: "0.9rem",
  },
  background: {
    backgroundColor: "#0000005e",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: 'hidden',
    // padding: '1rem',
  },
  background1: {
    backgroundColor: "white",
    width: "95%",
    padding: "1rem",
    borderRadius: "5px",
    textAlign: "left",
    overflow: 'auto',
    margin: '0 auto',
    marginTop: '1rem'
  },

  cancelButton: {
    width: "7rem",
    float: "right",
    marginRight: "0.8rem",
  },

  confirmButton: {
    width: "7rem",
    float: "right",
  },
};

export default withRouter(withApollo(withNamespaces()(ForgetPassword)));