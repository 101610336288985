import * as React from "react";
import Circle from "react-circle";

class Card_module extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { module,moduleNo } = this.props;
    console.log(moduleNo);
    return (
      <div onClick={() => this.props.get_module()}>
        <div
          className="uk-card uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle fade_card"
          tabIndex="0"
        >
          <a href="" className="uk-link-reset">
            <img
              src={module.bannerIconUrl}
              className="course-img global-img-size"
            />
            <div className="uk-card-body" style={{height: '10rem', overflow: 'hidden'}}>
              <h4>{module.moduleTitle}</h4>
              <p> {module.moduleHelperText}<span className="fade_bottom">
                  {/* <div style={{width: 'fit-content', position: 'absolute', bottom: '0', right: '0'}} uk-tooltip="Module progress">
              <Circle
                animate={true}
                animationDuration="3s"
                size={60}
                lineWidth={50}
                progress={10}
                progressColor="#008C95"
                bgColor="#E0E0E0"
                // textColor="white"
                textStyle={{
                  fontSize: "5rem",
                  fontWeight: "bold",
                }}
                
              />
            </div> */}
                  </span></p>
            </div>

            <div style={{width: 'fit-content', margin: "0rem 1.2rem 1rem auto"}} uk-tooltip="Module progress">
              <Circle
                animate={true}
                animationDuration="3s"
                size={60}
                lineWidth={50}
                progress={Math.round(module.progress) || 0}
                progressColor="#008C95"
                bgColor="#E0E0E0"
                // textColor="white"
                textStyle={{
                  fontSize: "5rem",
                  fontWeight: "bold",
                }}
                
              />
            </div>
            <div className="uk-card-footer">
              <div
                class="uk-child-width-expand@s uk-child-width-1-2@s"
                uk-grid="true"
              >
                <div>
                  <a href="#" className="uk-button uk-button-text">
                    Module { moduleNo }
                    {/* {module.moduleNumber } */}
                  </a>
                </div>
                {/* <div
                  style={{
                    width: "fit-content",
                    // marginLeft: '2rem',
                    paddingLeft: '15rem'
                  }}
                  uk-tooltip="Module progress"
                >
                  <Circle
                    animate={true}
                    animationDuration="3s"
                    size={35}
                    lineWidth={50}
                    progress={10}
                    progressColor="#008C95"
                    bgColor="#E0E0E0"
                    // textColor="white"
                    textStyle={{
                      fontSize: "7rem",
                      fontWeight: "bold",
                    }}
                  />
                </div> */}
                <div className="uk-text-right">
                {/* <Circle
                      animate={true}
                      animationDuration="3s"
                      size={35}
                      lineWidth={50}
                      progress={10}
                      progressColor="#008C95"
                      bgColor="#E0E0E0"
                      // textColor="white"
                      textStyle={{
                        fontSize: "7rem",
                        fontWeight: "bold",
                      }}
                    />
                   <span style={{width: '1rem', visibility: 'hidden'}}>{" "}{" "}</span>  */}
                  <span
                    class="uk-card-badge uk-label timeButton"
                    style={{ position: "inherit" }}
                  >
                    
                    <i class="fas fa-clock" aria-hidden="true"></i>
                    <span> {module.moduleOverview} </span>{" "}
                    <span className="miniuteText"> min </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export function Card_loading_module() {
  return (
    <div>
      <div
        className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle"
        tabIndex="0"
      >
        {/* <a href="" className="uk-link-reset" > */}
        <div className="uk-card-body">
          {/* <img
            src={require("../../../images/default.jpg")}
            className="course-img"
          /> */}
          <div className="loading_div">
            <h4 className="loading_text"></h4>
          </div>
          <hr className="uk-margin-remove-top" />
          <div className="uk-clearfix">
            <h4></h4>
          </div>
        </div>
        {/* </a> */}
      </div>
    </div>
  );
}

export default Card_module;
