import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';

import Single_resource_banner from '../../../components/resource/single_resource/single_resource_banner/single_resource_banner';
import Single_resource_container from '../../../components/resource/single_resource/single_resource_container/single_resource_container';
import Footer from '../../../components/footer/footer';
import { withNamespaces } from 'react-i18next';

//queries
import {  GET_SINGLE_RESOURCE } from '../../../queries/common_queries';

class SingleResource extends React.Component {
    constructor(props) {
        super(props);

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');
        this.state = {
            id: id,
            from: page,
            resourceId: id,
            pagination: {
                resourceId: id,
                isActive: true,
                lang:localStorage.LANG
            },
            loading: false,
            videos: [],
        }

    }

    componentDidMount() {
        this.setState({ loading: true });
         //Get single resource
         this.getSingleResource(this.state.pagination).then(result =>{
            const videoList = result.videos ? result.videos.videos ? result.videos.videos : [] : [];
            this.setState({
                resourceId: result.id,
                resourceTitle: result.resourceTitle,
                resources: result.resources,
                createdBy: result.createdBy,
                createdAt: result.createdAt,
                description: result.description,
                url: result.url,
                resourceContent: result.resourceContent,
                loading: false,
                videos: videoList,
            });
        });    
    }

    getSingleResource = async (pagination) => {        
        const result = await this.props.client.query({
            query: GET_SINGLE_RESOURCE,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
       console.log(result);
        return result.data.getSingleResource;
    };

    render() {
        const { resourceId, resourceTitle, resources, createdBy, createdAt, description, loading, url, resourceContent  } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Navbar />
                <div className="app">
                    {loading ? (
                        <div data-src='' uk-img="" className=" single-news-header single-news-header uk-height-large uk-background-norepeat  uk-background-center-center    uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover">
                            <div className="uk-width-1-1 uk-container uk-margin-large-top uk-padding-remove-bottom">
                                <h1 className=" uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top">{t('wait')} </h1>
                                <p className="  uk-margin-large-left  uk-text-white">
                                {t('loading')} </p>
                            </div>                 
                        </div>
                    ) : (
                            [<Single_resource_banner resourceTitle={resourceTitle}  createdAt={createdAt}/>,
                              <Single_resource_container videos={this.state.videos} resourceTitle={resourceTitle} resourceContent={resourceContent} />]
                        )}
                    <Footer language={localStorage.LANG}/>
                </div>
            </div>
        );
    }
}

 export default withRouter(withApollo(withNamespaces()(SingleResource)));