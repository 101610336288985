import React, { Component } from 'react'

class TableLoader extends Component {
    render() {
        return (
            <div className="uk-overflow-auto">
             <table className="uk-table uk-table-hover uk-table-middle uk-table-divider uk-width-xxlarge">
             
                <tbody>
                <br></br>
                
                    <tr className="loading-table">
                        {/* <td>
                        <input type="checkbox" />
                        </td> */}
                        <td> <strong> NUMBER</strong> </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td><strong> MODULE </strong> </td>
                        <td><strong> SCORE </strong> </td>
                        <td><strong> STATUS </strong> </td>
                    </tr>
                <br></br>
                
                    <tr className="loading-table">
                        <td> ..... </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td> ..... </td>
                        <td> .....</td>
                        <td> ..... </td>
                    </tr>
                <br></br>

                    <tr className="loading-table">
                    <td> ..... </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td> ..... </td>
                        <td> .....</td>
                        <td> ..... </td>
                    </tr>
                <br></br>

                    <tr className="loading-table">
                    <td> ..... </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td> ..... </td>
                        <td> .....</td>
                        <td> ..... </td>
                    </tr>
                    <br/>

                    <tr  className="loading-table">
                    <td> ..... </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td> ..... </td>
                        <td> .....</td>
                        <td> ..... </td>
                    </tr>
                    <br/>

                    <tr  className="loading-table">
                    <td> ..... </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td> ..... </td>
                        <td> .....</td>
                        <td> ..... </td>
                    </tr>
                    <br/>

                    <tr  className="loading-table">
                    <td> ..... </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td> ..... </td>
                        <td> .....</td>
                        <td> ..... </td>
                    </tr>
                    <br/>

                    <tr  className="loading-table">
                    <td> ..... </td>
                        <td>
                        <img
                        className="uk-preserve-width uk-border-circle user-profile-small"
                        src="../assets/images/avatures/avature-1.png"
                        width="50"
                        alt=""
                        />
                        </td>
                        <td> ..... </td>
                        <td> .....</td>
                        <td> ..... </td>
                    </tr>
                </tbody>
                </table>
            </div>
           
        )
    }
}

export default TableLoader