import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Button from "react-uikit/lib/components/Button";
import { setModalStatus } from "../../actions/user-actions";
import { DELETE_GALLERY_IMAGE } from "../../queries/user_queries";

const mapStateToProps = (state, ownProps) => {
    return {
        ratingModalOpen: state.custom_reducer.ratingModalOpen,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setModalStatus: (status) => {
            dispatch(setModalStatus(status));
        },
    }
}

class Delete_gallery_image extends React.Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        console.log(this.props.galleryImageID)
        console.log(this.props.galleryImages)


        this.state = {
            galleryImageId: this.props.galleryImageID,
            galleryImageIn: this.props.galleryImageIndex,
            galleryImagesAR: this.props.galleryImages
        }
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onCloseModal = () => {
        this.props.setModalStatus(false);
    };

    deleteImage = (galleryImageID, galleryImageIN) => {
        console.log(galleryImageID)
        console.log(galleryImageIN)
        if (galleryImageIN!= undefined) {
            const tempArray = this.state.galleryImagesAR;
            console.log(this.state.galleryImagesAR)
            tempArray.splice(galleryImageIN, 1);
            this.setState({
                galleryImagesAR: tempArray,
            })
            this.props.setModalStatus(false);

        } else {
            // this.deleteGalleryImage = async (galleryImageID) => {
            //     console.log(galleryImageID)
            //     // window.alert("Image Gallery id " + '"' + galleryImageID + '"' + " will be deleted");
            //     const result = await this.props.client.mutate({
            //         mutation: DELETE_GALLERY_IMAGE,
            //         variables: { galleryImageID }
            //     });
            //     this.setState({ galleryImages: this.state.galleryImages, });
        
            //     this.props.setModalStatus(false);
            //     window.location.reload();
            //     return result.data.deleteGalleryImage;
            // }
  
        }


    }

    deleteGalleryImage = async (galleryImageID) => {
        console.log(galleryImageID)
        // window.alert("Image Gallery id " + '"' + galleryImageID + '"' + " will be deleted");
        const result = await this.props.client.mutate({
            mutation: DELETE_GALLERY_IMAGE,
            variables: { galleryImageID }
        });
        this.setState({ galleryImages: this.state.galleryImages, });

        this.props.setModalStatus(false);
        window.location.reload();
        return result.data.deleteGalleryImage;
    }

    componentDidMount() {
        setTimeout(() => {
        this.deleteGalleryImage(this.state.galleryImageID);
    }, 500);

    }

    render() {
        const { galleryImageId, galleryImageIn } = this.state;
        console.log(galleryImageId)
        return (
            <div className="deleteModalDiv">
                <form>
                    <div className="deleteModal">
                        <div>
                            <h4> Are you sure you want to delete this image? </h4>
                        </div>
                        <hr />
                        <div className="deleteUser_buttons">
                            <Button
                                className="uk-button uk-button-primary cancelDeleteUserButton"
                                type="button"
                                onClick={() => this.onCloseModal()}
                            >
                                Cancel
                        </Button>
                            <Button
                                className="uk-button uk-button-primary sumbitDeleteUserButton"
                                type="button"
                                onClick={() => this.deleteGalleryImage(galleryImageId)}
                            >
                                Yes
                        </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withApollo(Delete_gallery_image))
);