import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_FEEDBACK_STATS } from "../../queries/admin_queries";
import Circle from "react-circle";
import Star from "../../images/1-star.png";
import Star2 from "../../images/2-star.png";
import Star3 from "../../images/3-star.png";
import Star4 from "../../images/4-star.png";
import Star5 from "../../images/5-star.png";
const mapStateToProps = (state, ownProps) => {
  return {};
};

class FeedbackStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        totalFeedbackCount: '',
        averageRating: '',
        FeedbackRate: '',
        ex: '',
        ve: '',
        go: '',
        fa: '',
        ne: '',
        fromDate: this.props.fromDate,
        toDate: this.props.toDate, 
    };
  }

  
  componentDidMount() {
    this.getFeedbackStats(this.state.fromDate, this.state.toDate).then((result) => {
      console.log(result);
      console.log(result.userRatings)
      var ex = [];
      var ve = [];
      var go = [];
      var fa = [];
      var ne = [];
      var averageRating = '';
      if(result.userRatings.length > 0) {
         ex = Object.values(result.userRatings).filter(obj => obj.rating === 'Excellent');
         ve = Object.values(result.userRatings).filter(obj => obj.rating === 'Very good');
         go = Object.values(result.userRatings).filter(obj => obj.rating === 'Good');
         fa = Object.values(result.userRatings).filter(obj => obj.rating === 'Fair');
         ne = Object.values(result.userRatings).filter(obj => obj.rating === 'Needs improvement');
         averageRating = Math.round((ex.length * 5 + ve.length * 4 + go.length * 3 + fa.length * 2 + ne.length * 1) / result.userRatings.length);
      }
      this.setState({
        totalFeedbackCount: result.totalFeedbackCount,
        averageRating: averageRating,
        FeedbackRate: result.FeedbackRate,
        ex: ex.length,
        ve: ve.length,
        go: go.length,
        fa: fa.length,
        ne: ne.length,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate) {
      this.getFeedbackStats(this.props.fromDate, this.props.toDate).then((result) => {
        console.log(result);
        var ex = [];
        var ve = [];
        var go = [];
        var fa = [];
        var ne = [];
        var averageRating = '';
        if(result.userRatings.length > 0) {
           ex = Object.values(result.userRatings).filter(obj => obj.rating === 'Excellent');
           ve = Object.values(result.userRatings).filter(obj => obj.rating === 'Very good');
           go = Object.values(result.userRatings).filter(obj => obj.rating === 'Good');
           fa = Object.values(result.userRatings).filter(obj => obj.rating === 'Fair');
           ne = Object.values(result.userRatings).filter(obj => obj.rating === 'Needs improvement');
           averageRating = Math.round((ex.length * 5 + ve.length * 4 + go.length * 3 + fa.length * 2 + ne.length * 1) / result.userRatings.length);
        }
        this.setState({
          totalFeedbackCount: result.totalFeedbackCount,
          averageRating: averageRating,
          FeedbackRate: result.FeedbackRate,
          ex: ex.length,
          ve: ve.length,
          go: go.length,
          fa: fa.length,
          ne: ne.length,
        });
      });
    }
  }

  getFeedbackStats = async (from, to) => {
    const result = await this.props.client.query({
      query: GET_FEEDBACK_STATS,
      variables: {fromDate: from, toDate: to},
      fetchPolicy: "network-only",
    });
    return result.data.getFeedbackStats;
  };

  render() {
    console.log(this.state.FeedbackRate)
    const {} = this.props;
    const {
        totalFeedbackCount,
        averageRating,
        FeedbackRate,
        ex,
        ve,
        go,
        fa,
        ne,
        fromDate,
        toDate,
    } = this.state;
    return (
      <div className="">
        <h4 className="statTitleMain">User Feedback</h4>
        <hr />
        <table style={{width: '100%', padding: '1rem', margin: '0 auto', borderSpacing: '20px'}}>
            <tbody>
                <tr>
                    <td style={{width: '40%', backgroundColor: '#D4895E', padding: '1rem'}}>
                    <div className="">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{borderRight: '1px solid white', paddingRight: '1rem', textAlign: 'center', }}>
                                            <text style={{fontSize: '3rem', fontWeight: 'bold', color: '#ffd885'}}>
                                                {totalFeedbackCount || 0}
                                                
                                            </text>
                                            <br />
                                            <text style={{fontSize: '0.9rem', fontWeight: '200', color: 'white'}}>
                                                Total Feedback
                                            </text>
                                            <br />
                                        </td>
                                        <td style={{padding: '0rem 1rem'}}>
                                            <text style={{fontSize: '0.8rem', color: 'white'}}>Excellent: </text>
                                            <text style={{fontSize: '1rem', color: '#fdffeb', fontWeight: 'bold'}}>{ex || 0}</text>
                                            <br />
                                            <text style={{fontSize: '0.8rem', color: 'white'}}>Very good: </text>
                                            <text style={{fontSize: '1rem', color: '#fdffeb', fontWeight: 'bold'}}>{ve || 0}</text>
                                            <br />
                                            <text style={{fontSize: '0.8rem', color: 'white'}}>Good: </text>
                                            <text style={{fontSize: '1rem', color: '#fdffeb', fontWeight: 'bold'}}>{go || 0}</text>
                                            <br />
                                            <text style={{fontSize: '0.8rem', color: 'white'}}>Fair: </text>
                                            <text style={{fontSize: '1rem', color: '#fdffeb', fontWeight: 'bold'}}>{fa || 0}</text>
                                            <br />
                                            <text style={{fontSize: '0.8rem', color: 'white'}}>Needs improvement: </text>
                                            <text style={{fontSize: '1rem', color: '#fdffeb', fontWeight: 'bold'}}>{ne || 0}</text>
                                            <br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
             
                    <td style={{width: '60%', padding: '0rem 0rem', backgroundColor: '#7d7a96'}}>
                      <table style={{width: '100%', backgroundColor: '', borderRadius: '5px',}}>
                        <tbody>
                          <tr>
                            <td style={{width: '50%'}}>
                              <div className="fitCenterDiv">
                                  <Circle
                                  animate={true}
                                  animationDuration="3s"
                                  size={120}
                                  lineWidth={40}
                                  progress={Math.round(FeedbackRate) || "N/A"}
                                  progressColor={ "#211f36" }
                                  bgColor="white"
                                  textColor="#ffebf2"
                                  textStyle={{
                                  fontSize: "6.5rem",
                                  fontWeight: "500",
                                  }}
                              />
                              </div>
                              <div className="fitCenterDiv">
                              <text style={{fontSize: '0.9rem', fontWeight: '300', color: 'white', textAlign: 'center', marginLeft: '0.5rem'}}>
                            from total users have rated
                          </text>
                              </div>
                                
                          
                            </td>
                            <td style={{width: '50%'}}>
                                  <div className="ratingBox2">
                                  <text style={{fontSize: '1.6rem', fontWeight: '300', color: '#ffebf2', textAlign: 'center', marginLeft: '0.5rem'}}>
                                    {averageRating === 1 ? 'Needs improvement' : averageRating === 2 ? 'Fair' : averageRating === 3 ? 'Good' : averageRating === 4 ? 'Very good' : averageRating === 5 ? 'Excellent' : 'N/A'}
                                  </text>
                                  <br />
                                  <img style={{width: 'auto', height: '1.5rem'}} src ={averageRating === 1 ? Star : averageRating === 2 ? Star2 : averageRating === 3 ? Star3 : averageRating === 4 ? Star4 : averageRating === 5 ? Star5 : null}  />
                                  <br />
                                  <br />
                                  <text style={{fontSize: '1rem', fontWeight: '300', color: 'white', textAlign: 'center', marginLeft: '0.5rem'}}>
                                    Average Rating
                                  </text>                 
                            </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    
                    </td>


                </tr>
            </tbody>
        </table>
        <br />
        {/* <div className="ratingBox2">
        <text style={{fontSize: '2rem', fontWeight: '300', color: 'black', textAlign: 'center', marginLeft: '0.5rem'}}>
            {averageRating === 1 ? 'Needs improvement' : averageRating === 2 ? 'Fair' : averageRating === 3 ? 'Good' : averageRating === 4 ? 'Very good' : averageRating === 5 ? 'Excellent' : 'N/A'}
         </text>
         <br />
         <img style={{width: 'auto', height: '2rem'}} src ={averageRating === 1 ? Star : averageRating === 2 ? Star2 : averageRating === 3 ? Star3 : averageRating === 4 ? Star4 : averageRating === 5 ? Star5 : null}  />
         <hr />
        <text style={{fontSize: '1rem', fontWeight: '300', color: 'black', textAlign: 'center', marginLeft: '0.5rem'}}>
            Average Rating
         </text>                 
        </div> */}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(FeedbackStats)));
