import * as React from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Navbar from "../../../components/navbar/navbar";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import ContentLoader from "react-content-loader";
import ExamContainer from "../../../components/exams/examContainer";
//queries
import {
  GET_SUB_MODULES,
  GET_NEXT_MODULE,
  GET_PREVIOUS_MODULE,
  GET_FLASH_QUIZ_PROGRESS,
  GET_MODULE,
  GET_SINGLE_EXAM,
} from "../../../queries/common_queries";

import ExamBanner from "../../../components/exams/examBanner";
import { withNamespaces } from 'react-i18next';

class Exam extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(-500, -200);
    var currentLocation = window.location.href;
    var url = currentLocation.split("?");
    url = url[0];
    url = url.split("/");
    var page = url[url.length - 1];

    let query = new URLSearchParams(this.props.location.search);
    let id = query.get("id");

    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (id == undefined) {
        this.props.history.push("/home");
      }
    }

    this.state = {
      examId: query.get("id"),
      exam: "",
    };

    // this.handleClick = this.handleClick.bind(this);
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
                    : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
                    : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
                    : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  componentDidMount() {
    this.getSingleExam().then((result) => {
      console.log(result);
      this.setState({
        exam: result,
      });
    });
  }

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/home");
    } else if (path == "EXAMS") {
      this.props.history.push("/sbls");
    } else {
      return false;
    }
  };

  redirectToHome = () => {
    this.props.history.push("/home");
  };

  getSingleExam = async () => {
    const { examId, formLanguage } = this.state;
    console.log(examId);
    const result = await this.props.client.mutate({
      mutation: GET_SINGLE_EXAM,
      variables: {
        examId: examId,
        lang: localStorage.LANG,
      },
    });
    console.log(result.data);
    return result.data.getSingleExam;
  };

  isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  render() {
    const { loading, exam } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Navbar />
        <div className="app" style={{ paddingBottom: "20px" }}>
          <Header />
          {loading
            ? "loading"
            : // <div>
              //   <div
              //     className="course-dhashboard"
              //     style={{
              //       backgroundImage:
              //         "linear-gradient(to right, rgb(1, 102, 202) 50% ,rgb(1, 102, 202,0.9) )",
              //       minHeight: "140px",
              //       maxHeight: "140px",
              //     }}
              //   >
              //     <div className="uk-grid">
              //       <div>
              //         <h2 className="uk-light  uk-text-bold uk-text-white">
              //           {" "}
              //           Just a moment.{" "}
              //         </h2>
              //         <p className="uk-light uk-text-bold">
              //           {" "}
              //           We are loading the content…{" "}
              //         </p>
              //       </div>
              //     </div>
              //   </div>
              //   <div
              //     className="uk-container"
              //     uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
              //   >
              //     <div className="uk-clearfix boundary-align">
              //       <div className="section-heading none-border">
              //         {/* <Card_loading_sub_module /> */}
              //       </div>
              //     </div>
              //   </div>
              // </div>
              [
                <ExamBanner exam={exam} />,

                <div
                  className="uk-container"
                  uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
                >
                  <br />{" "}
                  <div>
                    <ul className="uk-breadcrumb">
                      <li>
                        <a onClick={() => this.breadcrumbredirect("HOME")}>
                          {t('home')}
                        </a>
                      </li>
                      <li>
                        <a onClick={() => this.breadcrumbredirect("EXAMS")}>
                          {t('SBL_tab')}
                        </a>
                      </li>
                      <li>
                        <a onClick={() => this.breadcrumbredirect("EXAM")}>
                          {exam.examName}
                        </a>
                      </li>
                    </ul>
                  </div>
                  {this.isEmpty(exam) != true ? <ExamContainer exam={exam} /> : null}
                </div>,
              ]}
        </div>
        <Footer language={localStorage.LANG}/>
      </div>
    );
  }
}

export default withRouter(withApollo(withNamespaces()(Exam)));
