import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import moment from 'moment';
import {formatDate} from '../../../middleware/index';

//queries
import { GET_ANNOUNCEMENTS } from "../../../queries/common_queries";
import { async } from "q";
import { UPDATE_ANNOUNCEMENT } from "../../../queries/admin_queries";
import ConfirmationModal from '../../../components/adminModals/confirmationModal';


const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class AnnouncementList extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }
    this.state = {
      loading: false,
      pagination: {
        // skip: 0,
        // first: 3,
        lang: "en",
      },
      announcements: [],
      nextPage: 1,
      previousPage: 0,
      currentPage: 0,
      activePage: "",
      ToDos: 6,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      isFirstBtnActive: "disabled",
      isLastBtnActive: "",
      options: [
        { text: "Active", key: "Recent", value: "Recent" },
        { text: "Upcoming", key: "Upcoming", value: "Upcoming" },
        { text: "Inactive", key: "Inactive", value: "Inactive" },
        { text: "Expired", key: "Archived", value: "Archived" },
        { text: "All", key: "All", value: "All" },
      ],
      selectedOption: "Recent",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, index) {
    this.setState({
      currentPage: Number(index),
      activePage: index,
    });
  }

  componentDidMount() {
    this.get_all_announcements(this.state.pagination).then((result) => {
      console.log(result);
      if (result.length > 0) {
        var all = result;
        const currentDate = moment().format('YYYY-MM-DD');
        var tempList = tempList = Object.values(all).filter(obj => obj.isActive === true && obj.startDate <= currentDate && obj.endDate > currentDate);
        
        this.setState({
          announcements: tempList,
          all: all
        });
      }
    });
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  // handlePagination = async(pageNo)=> {
  //     console.log(pageNo);
  //     this.setState({
  //         currentPage:pageNo
  //     })

  //     if(pageNo>=0){
  //         this.setState({ isNextBtnActive:'',isLastBtnActive:''})
  //         this.state.nextPage = pageNo+1;
  //     }

  //     if(pageNo===Math.ceil(this.state.count / this.state.ToDos )-1){
  //         this.setState({ isNextBtnActive:'disabled',isLastBtnActive:'disabled'});
  //     }

  //     if(pageNo>0){
  //         this.setState({isPrevBtnActive:'',isFirstBtnActive:''})
  //         this.state.previousPage = pageNo-1;
  //     }

  //     if(pageNo===0){
  //         this.setState({ isPrevBtnActive:'disabled',isFirstBtnActive:'disabled'})
  //     }

  //     var paginations = {
  //         skip: pageNo * this.state.ToDos,
  //         first: 6
  //     };

  //     if (pageNo > 0) {
  //         this.get_all_announcements(paginations).then(result => {
  //             this.setState({ announcements: result.announcements, count: result.count });
  //         });
  //     } else {
  //         this.get_all_announcements(this.state.pagination).then(result => {
  //             this.setState({ announcements: result.announcements, count: result.count });
  //         });
  //     }
  // }

  addNewAnnouncement() {
    this.props.history.push("/admin_add_announcement");
  }

  editAnnouncement(id) {
    this.props.history.push("/admin_add_announcement?id=" + id);
  }

  get_all_announcements = async (pagination) => {
    const result = await this.props.client.query({
      query: GET_ANNOUNCEMENTS,
      variables: pagination,
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getAnnouncements.announcements;
  };

  updateAnnouncement = async (id, status) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_ANNOUNCEMENT,
      variables: {
        announcementId: id,
        lang: "en",
        isActive: status,
      },
    });
    console.log(result.data);
    return result.data.updateAnnouncement;
  };

  changeAnnouncementStatus = (id, status) => {
    this.updateAnnouncement(id, status).then((result) => {
      this.get_all_announcements(this.state.pagination).then((result) => {
        console.log(result);
        const all = result;
        const currentDate = moment().format('YYYY-MM-DD');
        const category = this.state.selectedOption;
        if (result.length > 0) {
          var tempList = [];
          if (category === "Recent") { //Active
            tempList = Object.values(all).filter(obj => obj.isActive === true && obj.startDate <= currentDate && obj.endDate > currentDate);
          } else if (category === "Upcoming") {
            tempList = Object.values(all).filter(obj => obj.startDate > currentDate);
          } else if (category === "Archived") { // Expired
            tempList = Object.values(all).filter(obj => obj.endDate < currentDate);
          } else if (category === "Inactive") {
            tempList = Object.values(all).filter(obj => obj.isActive === false);
          } else {
            tempList = all;
          }
          this.setState({
            announcements: tempList,
            all: result,
            confirmationModal: false,
            confirmationModal2: false,
          });
        }
      });
    });
  };

  // handleOptionChange = (event) => {
  //   var category = event.target.value;
  //   console.log(category);
  //   this.setState({ selectedOption: category });
  //   var tempList = [];
  //   if (category === "Recent") {
  //     tempList = this.state.recent;
  //   } else if (category === "Upcoming") {
  //     tempList = this.state.upcoming;
  //   } else if (category === "Archived") {
  //     tempList = this.state.archived;
  //   } else {
  //     tempList = this.state.all;
  //   }
  //   this.setState({
  //     announcements: tempList,
  //   });
  // };

  handleOptionChange = (event) => {
    const {all} = this.state;
    const currentDate = moment().format('YYYY-MM-DD');

    var category = event.target.value;
    console.log(category);
    this.setState({ selectedOption: category });
    var tempList = [];
    if (category === "Recent") { //Active
      tempList = Object.values(all).filter(obj => obj.isActive === true && obj.startDate <= currentDate && obj.endDate > currentDate);
    } else if (category === "Upcoming") {
      tempList = Object.values(all).filter(obj => obj.startDate > currentDate);
    } else if (category === "Archived") { // Expired
      tempList = Object.values(all).filter(obj => obj.endDate < currentDate);
    } else if (category === "Inactive") {
      tempList = Object.values(all).filter(obj => obj.isActive === false);
    } else {
      tempList = this.state.all;
    }
    this.setState({
      announcements: tempList,
      selectedOption: category,
    });
  };

  render() {
    const {
      announcements,
      loading,
      count,
      currentPage,
      nextPage,
      previousPage,
      ToDos,
      isPrevBtnActive,
      isNextBtnActive,
      isFirstBtnActive,
      isLastBtnActive,
    } = this.state;
    console.log(moment().format('YYYY-MM-DD') > '2020-09-22');

    // const pages = Math.ceil(count / ToDos);

    // if (pages === 0) return null;

    // var items = [];

    // for (var index = 0; index < pages; index++) {
    //     items[index] = index;
    // }

    // let renderPrevBtn = null;
    // if(isPrevBtnActive === 'disabled') {
    //     renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className="fas fa-angle-left"></i> </span></li>
    // } else{
    //     renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={()=> this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
    // }

    // let renderNextBtn = null;
    // if(isNextBtnActive === 'disabled') {
    //     renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
    // }
    // else{
    //     renderNextBtn = <li className={isNextBtnActive}><a onClick={()=> this.handlePagination(nextPage) } id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
    // }

    // let renderfirstBtn = null;
    // if(isFirstBtnActive === 'disabled') {
    //     renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
    // } else{
    //     renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={()=> this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
    // }

    // let renderlastBtn = null ;
    // if(isLastBtnActive==='disabled'){
    //     renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
    // }else{
    //     renderlastBtn = <li className={isLastBtnActive}><a onClick={()=>this.handlePagination(items.length -1)}  id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
    // }

    return (
      <div className="admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
        <i className="fas fa-bullhorn uk-margin-right" style={{fontSize: '2.3rem'}}></i>

          <h4 className="uk-margin-remove adminSectionTitles"> Announcements </h4>
        </div>
        <div className="uk-flex-inline uk-float-right">
          <button
            className="uk-button uk-button-primary admin-btn"
            onClick={() => this.addNewAnnouncement()}
          >
            Add Announcement
          </button>
        </div>
<br />
<br />
        <div className="uk-overflow-auto">
        <div className="uk-form-label"> Announcement Category </div>
          <select
            className="uk-select uk-width-large"
            onChange={this.handleOptionChange}
          >
            {/* <option key="userType" value="" disabled>
              All
            </option> */}
            {this.state.options.map((item) => (
              <option key={item.key} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
          <br />
          <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead>
              <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                <th>Title</th>
                <th style={{width: '6rem'}}>Start Date</th>
                <th style={{width: '6rem'}}>End Date</th>
                <th>Status</th>
                {/* <th></th> */}
                <th style={{textAlign: 'right', paddingRight:'4rem', width: '8rem'}}> Actions</th>
              </tr>
            </thead>
            <tbody>
              {announcements
                ? announcements.length > 0
                  ? announcements.slice(0).reverse().map((announcement, i) => (
                      <tr key={i}>
                        <td> {announcement.title} </td>
                        <td> {formatDate(announcement.startDate)}  </td>
                        <td> {formatDate(announcement.endDate)}  </td>
                        <td>
                          {" "}
                          {announcement.isActive ? (
                            <p style={{ color: "green" }}>ACTIVE</p>
                          ) : (
                            <p style={{ color: "red" }}>INACTIVE</p>
                          )}{" "}
                        </td>
                        {/* <td style={{width: 'fit-content', backgroundColor: 'red'}}>
                          <button
                            disabled={loading ? true : false}
                            className="uk-button uk-button-warning admin-table-btn"
                            uk-tooltip="Edit"
                            onClick={() =>
                              this.editAnnouncement(announcement.id)
                            }
                            style={{float: 'right'}}
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </button>
                        </td> */}
                        <td style={{width: 'fit-content'}}>
                          
                          {announcement.isActive ? (
                            <button
                              onClick={() =>
                                // this.changeAnnouncementStatus(
                                //   announcement.id,
                                //   false
                                // )
                                this.setState({confirmationModal: true, clickedAnnouncementIdChangeStatus: announcement.id})
                              }
                              className="uk-button uk-button-danger admin-table-btn tableButtonAnnouncement"
                              uk-tooltip="title: Deactivate Announcement; delay: 500; pos: top; animation: uk-animation-scale-up"
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                // this.changeAnnouncementStatus(
                                //   announcement.id,
                                //   true
                                // )
                                this.setState({confirmationModal2: true, clickedAnnouncementIdChangeStatus: announcement.id})
                              }
                              className="uk-button uk-button-success admin-table-btn button_Success tableButtonAnnouncement"
                              uk-tooltip="title: Activate Announcement; delay: 500; pos: top; animation: uk-animation-scale-up"
                            >
                              Activate
                            </button>
                          )}
                          <button
                            disabled={loading ? true : false}
                            className="uk-button uk-button-warning admin-table-btn"
                            uk-tooltip="Edit"
                            onClick={() =>
                              this.editAnnouncement(announcement.id)
                            }
                            style={{float: 'right'}}
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  : null
                : null}
            </tbody>
          </table>
        </div>
        {/* <div className="paginations uk-flex uk-flex-center">
                    <ul class="uk-pagination" uk-margin>
                        {renderfirstBtn}
                        {renderPrevBtn}

                        {
                            items.map((item,index) =>
                                <li key ={index} id={index} active={this.state.activePage=== index } onClick={(e)=> this.handleClick(e,item)}  className={currentPage===item? 'active': ''}><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                            )
                        }
                        {renderNextBtn}
                        {renderlastBtn}
                    </ul>
                </div> */}
 

{
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to deactivate this announcement?" confirmDelete={() => this.changeAnnouncementStatus(this.state.clickedAnnouncementIdChangeStatus,false)} closeModal={() => this.setState({confirmationModal: false})} />
            : null
        }

        {
          this.state.confirmationModal2 === true ?
            <ConfirmationModal message="Are you sure you want to activate this announcement?" confirmDelete={() => this.changeAnnouncementStatus(this.state.clickedAnnouncementIdChangeStatus,true)} closeModal={() => this.setState({confirmationModal2: false})} />
            : null
        }


      </div>
    );
  }
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(AnnouncementList))
);
