import React, { Component } from "react";
import { connect } from 'react-redux';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_ALL_USERS } from "../../../queries/common_queries";
import { ItemMeta } from "semantic-ui-react";
import Button from "react-uikit/lib/components/Button";
import { GET_USER_STATS } from "../../../queries/admin_queries";

// import LearningStats from '../../../components/DasboardStats/learningStats';
import LearningStats from '../../../components/DasboardStats/learningSection';
import SblStats from '../../../components/DasboardStats/sblStats';
import FeedbackStats from '../../../components/DasboardStats/feedbackStats';
import ResourceStats from '../../../components/DasboardStats/resourceStats';
import GalleryStats from '../../../components/DasboardStats/galleryStats';
import NewsStats from '../../../components/DasboardStats/newsStats';
import GoogleAnalyticsStats from '../../../components/DasboardStats/googleAnalyticsStats';
import {Line, Doughnut, Pie} from 'react-chartjs-2';
import moment from 'moment'
import Filter from '../../../images/filter.png'
import 'chartjs-plugin-labels';
import { setFormStatus, setSuccessStatus, setStatDates } from '../../../actions/user-actions';

const mapStateToProps = (state, ownProps) => {
  return {
      formErrorStatus: state.custom_reducer.formErrorStatus,
      formSuccessState: state.custom_reducer.formSuccessState,
      statFromDate: state.custom_reducer.statFromDate,
      statToDate: state.custom_reducer.statToDate,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      setFormStatus: (status) => {
          dispatch(setFormStatus(status))
      },
      setSuccessStatus: (status) => {
          dispatch(setSuccessStatus(status))
      },
      setStatDates: (dates) => {
        dispatch(setStatDates(dates))
    }
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMA: true,
      activeMA: 0,
      pendingMA: 0,
      isActive: 0,
      value: 0,

      allUsers: [],
      weekCount: '',
      monthCount: '',
      regions: [
        { name: "Americas and the Caribbean", key: "ACR" },
        { name: "Africa", key: "Africa" },
        { name: "Arab World", key: "AWR" },
        { name: "European Network", key: "EN" },
        { name: "East and Southeast Asia and Oceania", key: "ESEAOR" },
        { name: "South Asia", key: "SAR" },
      ],
      userCategories: [
        { name: "MA", key: "MA" },
        { name: "RO", key: "RO" },
        { name: "CO", key: "CO" }
      ],
      dataset: {
        labels: [],
        datasets: [
        {
        // label: 'My First dataset',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      }
      ]
      },
      chartData: "",
      selectedStat: 'alltime',
      fromDate: '',
      toDate: '',
      chartLabel: moment().format('YYYY'),
      test1: 0,
      test2: props.statToDate,
      chartColors: [
        "#77C750",
        "#F47373",
        "#FFBE5C",
        "#b97dd1",
        "#2CCCE4",
        "#912763",
      ],
      chartLabels: [],
      chartCounts: [],
      areaTotal: 0,
      options: {
        elements: {
          arc: {
            borderWidth: 0,
            borderColor: 'black'
          }
        },
        plugins: {
          labels: {
                render: 'percentage',
                // position: 'outside',
                fontColor: "white"
             }
        },
      },
      chartData2: "",
      chartLabels2: [],
      chartCounts2: [],
      chartColors2: [
        "#264466",
        "#FF5872",
        "#febf00"
      ],
    };
    // this.get_all_users()
    //   .then((result) => {
    //     const MAList = result.users;
    //     let res = MAList.filter((it) => it.isActive === true);
    //     this.setState({
    //       loadingMA: false,
    //       activeMA: res.length,
    //       pendingMA: MAList.length - res.length,
    //       activeList: res,
    //     });
    //   })
    //   .catch((error) => {
    //     this.setState({ loadingMA: false });
    //   });
    
  }

  componentDidMount() {
      this.getUserStats(this.state.fromDate, this.state.toDate).then((result) => {
        this.prepareChartData(result.allUsers);
        this.prepareChartData2(result.allUsers);
        this.prepareLineChartData(result.allUsers);
          console.log(result)
          this.setState({
            allUsers: result.allUsers,
            loadingMA: false,
            activeMA: result.activeUsers.length,
            pendingMA: result.pendingUsers.length,
            activeList: result.activeUsers,
            weekCount: result.thisWeekCount,
            monthCount: result.thisMonthCount
          })
      })
      
  }

  get_all_users = async () => {
    const result = await this.props.client.query({
      query: GET_ALL_USERS,
      variables: { userType: "MAUSER" },
      fetchPolicy: "network-only",
    });
    return result.data.getAllUsers;
  };

  prepareLineChartData = (users) => {

    const {fromDate, toDate, dataset} = this.state;

    var tempDataset = dataset;

    var dateStart = '';
    var dateEnd = '';
    var months = [];
    var chartData = [];
    var timeline = [];
    var yearDiff = 0;

    if(fromDate != ''){
      dateStart = moment(fromDate, 'YYYY-MM-DD');
    } else {
      dateStart = moment().startOf('year');
    }
    if(toDate != ''){
      dateEnd = moment(toDate, 'YYYY-MM-DD');
    } else {
      dateEnd = moment();
    }

    // yearDiff = moment(dateEnd).diff(moment(dateStart), 'years');
    const isYearsDifferent = moment(dateStart).format('YYYY') === moment(dateEnd).format('YYYY');
    // console.log(moment(dateStart).format('YYYY'), dateEnd)
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      if(isYearsDifferent == false) {
        months.push(dateStart.format('MMM\'YY'));
        timeline.push(dateStart.format('MMM\'YY'));
      }
      else {
        months.push(dateStart.format('MMM'));
        timeline.push(dateStart.format('MMM\'YY'));
      }
      dateStart.add(1,'month');
    }


    var monthStart= '';
    var monthEnd= '';
    var temp = [];
    // timeline.length > 0 ?
    timeline.map(month => (
      monthStart = moment(month, 'MMM\'YY').startOf('month').format(),
      monthEnd = moment(month, 'MMM\'YY').endOf('month').format(),
      temp = Object.values(users).filter(obj => obj.createdAt >= monthStart && obj.createdAt <= monthEnd),
      chartData.push(temp.length)
    ))
    // : null

    tempDataset.labels = months;
    tempDataset.datasets[0].data = chartData;

    this.setState({
      dataset: tempDataset
    })

  }

  prepareChartData = (users) => {
    const { regions } = this.state;
    var tempArray = [];
    var chartDataArray = [];
    var chartLabelArray = [];
    var chartCounts= [];
    var areaTotal = 0;
    regions.map(
      (region) => (
        (tempArray = Object.values(users).filter((obj) => obj.region === region.name)),
        chartDataArray.push(tempArray.length),
        users.length > 0 ?
        chartLabelArray.push(region.key)
        : chartLabelArray.push(region.key + " " + '-' + " " + '0'),
        chartCounts.push({region: region.key, count: tempArray.length }),
        areaTotal = areaTotal + tempArray.length
      )
    );
    const data = {
      labels: chartLabelArray,
      datasets: [
        {
          data: chartDataArray,
          backgroundColor: this.state.chartColors
          // hoverBackgroundColor: [
          // '#FF6384',
          // '#36A2EB',
          // '#FFCE56'
          // ]
        },
      ],
    };
    this.setState({
      chartData: data,
      chartLabels: chartLabelArray,
      chartCounts: chartCounts,
      areaTotal: areaTotal,
    });
  };
  // general.fullName === "International Planned Parenthood Federation") ? "CO" : (general.fullName === "NA" ? "RO" : "MA"
  prepareChartData2 = (users) => {
    const { userCategories } = this.state;
    var tempArray = [];
    var chartDataArray = [];
    var chartLabelArray = [];
    var chartCounts= [];
    var areaTotal = 0;

    var co = [];
    var ro = [];
    var ma = [];
    const categories = ["MA", "RO", "CO" ];
    const emptyCategories = ["MA - 0", "RO - 0", "CO - 0"];
    if (users) {
      if (users.length > 0) {
        users.map(user => (
          user.fullName === "International Planned Parenthood Federation" ?
            co.push(user)
          : user.fullName === "NA" ?
            ro.push(user)
          : ma.push(user)
        ))
      }
    }
    categories.map(cat => (
      cat === "CO" ?
          chartDataArray.push(co.length)
      : cat === "RO" ?
          chartDataArray.push(ro.length)
      : cat === "MA" ?
          chartDataArray.push(ma.length)
      : null
    ))

    categories.map(cat => (
      cat === "CO" ?
          chartCounts.push({region: cat, count: co.length })
      : cat === "RO" ?
          chartCounts.push({region: cat, count: ro.length })
      : cat === "MA" ?
          chartCounts.push({region: cat, count: ma.length })
      : null
    ))

    

    users.length > 0 ?
      chartLabelArray = categories
    : chartLabelArray = emptyCategories

    const data = {
      labels: chartLabelArray,
      datasets: [
        {
          data: chartDataArray,
          backgroundColor: this.state.chartColors2
          // hoverBackgroundColor: [
          // '#FF6384',
          // '#36A2EB',
          // '#FFCE56'
          // ]
        },
      ],
    };
    this.setState({
      chartData2: data,
      chartLabels2: chartLabelArray,
      chartCounts2: chartCounts,
    });
  };


  getUserStats = async (fromDate, toDate) => {
    const result = await this.props.client.query({
      query: GET_USER_STATS,
      variables: {
        fromDate: fromDate,
        toDate: toDate
      },
      fetchPolicy: "network-only",
    });
    return result.data.getUserStats;
  };

  userStatus = (status, value) => {
    console.log(value);
    this.setState({
      isActive: status,
      value: value,
    });

    // this.props.history.push('/admin_user_list',isActive, value );
    this.props.history.push(`/admin_user_list?status=${status}&from=${this.state.fromDate}&to=${this.state.toDate}`);
    // this.props.history.push(`/admin_user_list/${status}/${this.state.fromDate}/${this.state.toDate}`);
  };

  downloadCsv = () => {
    console.log(this.state.allUsers);
    const arrayHeader = ['First name', 'Last name', 'User Category', 'Email Address', 'Organization name', 'Country', 'IPPF Region', 'Year Established', 'Account created', 'Weekly Newsletter', 'Status'];
    const arrayData = this.state.allUsers;
    const delimiter = ',';
    const fileName = 'User List.csv';
    this.export_csv(arrayHeader, arrayData, delimiter, fileName);
  }

//   export_csv = (arrayHeader, arrayData, delimiter, fileName) => {
//     let key = ['firstName', 'lastName', 'email', 'isActive', 'fullName', 'country', 'region', 'year', 'createdAt', 'newsletterSubscription'];
//     let keys = ['firstName', 'lastName', 'email', 'isActive', 'fullName', 'country', 'region', 'year', 'createdAt', 'newsletterSubscription'];
//     let header = arrayHeader.join(delimiter) + '\n';
//     let csv = header;
//     arrayData.forEach( obj => {
//         let row = [];
//         for (key in obj) {
//           if(keys.includes(key)){
//             if (obj.hasOwnProperty(key)) {
//               row.push(obj[key]);
//             }
//           }
//         }
//         csv += row.join(delimiter)+"\n";
//     });

//     let csvData = new Blob([csv], { type: 'text/csv' });  
//     let csvUrl = URL.createObjectURL(csvData);

//     let hiddenElement = document.createElement('a');
//     hiddenElement.href = csvUrl;
//     hiddenElement.target = '_blank';
//     hiddenElement.download = fileName + '.csv';
//     hiddenElement.click();
// }

export_csv = (arrayHeader, arrayData, delimiter, fileName) => {
  console.log(arrayData)
  let key = ['firstName', 'lastName', 'userType',  'email', 'fullName', 'country', 'region', 'year', 'createdAt', 'newsletterSubscription', 'isActive'];
  let keys = ['firstName', 'lastName', 'userType', 'email', 'fullName', 'country', 'region', 'year', 'createdAt', 'newsletterSubscription', 'isActive'];
  let header = arrayHeader.join(delimiter) + '\n';
  let csv = header;
  arrayData.forEach( obj => {
      let row = [];
      let date = '';
        keys.map(key => (
          obj.hasOwnProperty(key) ? 
            key === 'createdAt' ?
                row.push(moment(obj[key]).format('DD-MM-YYYY'))
                : key === 'isActive' ?
                      obj[key] === true ?
                          row.push('Active')
                          : row.push('Inactive')
                : key === 'userType' ?
                      obj['fullName'] === "International Planned Parenthood Federation" ?
                           row.push('CO')
                      : obj['fullName'] === "NA" ?
                           row.push("RO")
                      : row.push("MA")
                : key === 'newsletterSubscription' ?
                      obj[key] === true ?
                          row.push('Subscribed')
                          : row.push('Unsubscribed')
                : row.push(obj[key])
            
          : null
        ))
      csv += row.join(delimiter)+"\n";
  });

  let csvData = new Blob([csv], { type: 'text/csv' });  
  let csvUrl = URL.createObjectURL(csvData);

  let hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  hiddenElement.click();
}

changeStatType = (type) =>{

  this.setState({
    selectedStat: type,
  })
  if(type === 'alltime') {
    this.filterStats('', '');
    this.setState({
      fromDate: '',
      toDate: '',
      chartLabel: moment().format('YYYY'),
    })
  }

}

handleDateChange = (e) => {
  var temp = '';
  console.log(e.target.value);
  this.setState({
    [e.target.name]: e.target.value
  })
  if(e.target.name === 'fromDate') {
    this.filterStats(e.target.value, this.state.toDate);
    temp = this.state.toDate != '' ? moment(this.state.toDate, 'YYYY-MM-DD').format('DD MMM YYYY') : moment().format('DD MMM YYYY');
    this.setState({
      chartLabel: moment(e.target.value, 'YYYY-MM-DD').format('DD MMM YYYY') + " " + '-' + " " + temp
    })
    this.props.setStatDates({ fromDate: e.target.value, toDate: this.state.toDate });
  }
  else if(e.target.name === 'toDate') {
    this.filterStats(this.state.fromDate, e.target.value);
    temp = this.state.fromDate != '' ? moment(this.state.fromDate, 'YYYY-MM-DD').format('DD MMM YYYY') : moment().startOf('year').format('DD MMM YYYY');
    this.setState({
      chartLabel: temp + " " + '-' + " " + moment(e.target.value, 'YYYY-MM-DD').format('DD MMM YYYY')
    })
    this.props.setStatDates({ fromDate: this.state.fromDate, toDate: e.target.value });

  }

}

filterStats = (fromDate, toDate) => {
  this.getUserStats(fromDate, toDate).then((result) => {
    this.prepareChartData(result.allUsers);
    this.prepareChartData2(result.allUsers);
    this.prepareLineChartData(result.allUsers);
      console.log(result)
      this.setState({
        allUsers: result.allUsers,
        loadingMA: false,
        activeMA: result.activeUsers.length,
        pendingMA: result.pendingUsers.length,
        activeList: result.activeUsers,
        weekCount: result.thisWeekCount,
        monthCount: result.thisMonthCount
      })
  })
}

  render() {
    const { loadingMA, activeMA, pendingMA, isActive, weekCount, monthCount, selectedStat, fromDate, toDate, chartCounts, chartColors, chartCounts2, chartColors2 } = this.state;
    return (
      <div className="userlist-content admin-content-inner">
            <div className="uk-flex-inline uk-flex-middle">
                <i className="fas fa-home icon-large uk-margin-right"></i>
                <h3 className="uk-margin-remove adminSectionTitles"> Dashboard </h3>
                </div>
                <br />
                <br />
        <div className="dashboardUserMain">
          <table style={{width: '100%'}}>
            <tbody>
              <tr>
                <td style={{width: '35%'}}>
                    <div className={selectedStat==='alltime' ? "statButtonsClicked" : "statButtons"} onClick={() => this.changeStatType('alltime')}>All-time Statistics</div>
                    <div className={selectedStat==='custom' ? "statButtonsClicked" : "statButtons"} onClick={() => this.changeStatType('custom')}>Custom Statistics</div>
                </td>
                <td style={{width: '2%'}}>
                  <text style={{visibility: 'hidden'}}>a</text>
                </td>
                <td style={{width: '32%'}}>
                  {selectedStat === 'custom' ?
                    <div style={{float: 'right', width: '100%'}}>
                    <div className="uk-form-label" style={{fontSize: '0.9rem', float: 'left', marginTop: '2px'}}> From </div>
                              <input
                                value={fromDate || ""}
                                className="uk-textarea"
                                rows="2"
                                id="fromDate"
                                name="fromDate"
                                type="date"
                                onChange={(e) => this.handleDateChange(e)}
                                style={{fontSize: '0.8rem', width: '70%', marginLeft: '10px'}}
                              ></input>
                    </div>
                    : null
                  }
                
                </td>
                <td style={{width: '31%'}}>
                  {
                    selectedStat === 'custom' ?
                    <div style={{float: 'left', width: '100%'}}>
                    <div className="uk-form-label" style={{fontSize: '0.9rem', float: 'left', marginTop: '2px', marginLeft: '5px'}}> To </div>
                              <input
                                value={toDate || ""}
                                className="uk-textarea"
                                rows="2"
                                id="toDate"
                                name="toDate"
                                type="date"
                                onChange={(e) => this.handleDateChange(e)}
                                style={{fontSize: '0.8rem', width: '70%', marginLeft: '5px'}}
                              ></input>
                    </div>
                    : null
                  }
                
                </td>
                {/* <td style={{width: '4%'}}>
                  {
                    selectedStat === 'custom' ?
                      <div className="filterStatButton" onClick={() => this.filterStats()}>Filter <i class="fa fa-filter" aria-hidden="true"></i></div>
                    :null
                  }
                </td> */}
              </tr>
            </tbody>
          </table>



          <h4 className="statTitleMain">Users</h4>
          <hr />
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "33.33%", paddingLeft: '1rem', paddingRight: '1rem' }}>
                    <div className="userBox">
                      <div className="circle">
                        <i
                          class="fas fa-users icon-xxlarge uk-visible@m"
                          style={{ color: "white" }}
                        ></i>
                      </div>
                      <div className="fitCenterDiv">
                        <h3 style={{ color: "#73d7ff" }}>
                          {loadingMA ? "Loading" : activeMA + " Members"}
                        </h3>
                      </div>
                      <div className="fitCenterDiv">
                        <p> Active</p>
                      </div>
                      <div className="fitCenterDiv">
                          <Button
                          //   className="uk-button uk-button-primary"
                            style={{ fontWeight: "500", color: '#ffe0bf' }}
                            onClick={() => this.userStatus("active", true)}
                          >
                            View All
                          </Button>
                        
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "33.33%", paddingLeft: '1rem', paddingRight: '1rem' }}>
                    <div className="userBox2">
                      <div className="circle">
                        <i
                          class="fas fa-users icon-xxlarge uk-visible@m"
                          style={{ color: "white" }}
                        ></i>
                      </div>
                      <div className="fitCenterDiv">
                        <h3 style={{ color: "#b55d00" }}>
                        {loadingMA ? "Loading" : pendingMA + " Members"}
                        </h3>
                      </div>
                      <div className="fitCenterDiv">
                        <p> Pending</p>
                      </div>
                      <div className="fitCenterDiv">

                        <Button
                        //   className="uk-button uk-button-primary"
                          style={{ fontWeight: "500", color: '#125450',  }}
                          onClick={() => this.userStatus("pending", false)}
                        >
                          View All
                        </Button>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "33.33%", paddingLeft: '0.5rem', paddingRight: '0.5rem' }} className="paddinglessTd">
                    
                    <div className="smallUserBox" style={{color: '#35396e'}}>
                    {/* <div className="userTag">
                        
                        </div> */}
                    <text style={{fontSize: '2.1rem', fontWeight: 'bold', color: "#266c9e"}}>{pendingMA + activeMA || 0}</text>
                    <text style={{fontSize: '0.9rem', color: 'black'}}>&nbsp; total users</text>
                    <br />
                    <text style={{fontSize: '1.2rem', fontWeight: 'bold', color: "#266c9e"}}>{weekCount || 0} </text>
                    <text style={{fontSize: '0.9rem', color: 'black'}}>new users this week</text>
                    <br />
                    <text style={{fontSize: '0.9rem', color: 'black'}}>and </text>
                    <text style={{fontSize: '1.2rem', fontWeight: 'bold', color: "#266c9e"}}>{monthCount || 0} </text>
                    <text style={{fontSize: '0.9rem', color: 'black'}}>new users this month</text>
                    </div>
                    <div className="userDownloadButtonDiv">
                    <div className="fitCenterDiv">
                    <Button
                          className="userDownloadButton"
                          onClick={() => this.downloadCsv()}
                        >
                          Download user list
                        </Button>
                      </div>
                    
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td style={{padding: '0.2rem', width: '50%'}}>
                  <div style={{backgroundColor: 'white', padding: '1rem', borderRadius: '5px', minHeight: '20.3rem', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                        <text style={{fontWeight: 'bold', color: '#a6b577'}}>Category wise user registrations</text>
                        <hr />
                        {
                          this.state.chartData != "" ?
                          <table style={{width: '100%'}}>
                            <tbody>
                              <tr>
                                <td style={{width: '80%'}}>
                                  <Doughnut
                                    data={this.state.chartData2}
                                    legend={{ position: "right", display: false, }}
                                    width={180}
                                    options={this.state.options}
                                  />
                                </td>
                                <td style={{width: '20%', paddingLeft: '1rem'}}>
                                  <table style={{width: 'fit-content'}}>
                                    <tbody>
                                      {
                                        chartCounts2.length > 0 ?
                                          chartCounts2.map((label, index) => (
                                            <tr>
                                              <td style={{width: '50%'}}><div style={{width: '2.5rem', height: '1.3rem', backgroundColor: chartColors2[index], color: 'white', fontSize: '0.8rem', textAlign: 'center'}}>{label.count}</div></td>
                                              <td style={{width: '50%', fontSize: '0.8rem', paddingLeft: '0.5rem'}}>{label.region}</td>
                                            </tr>
                                          ))
                                        : null
                                      }
                                      
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        : null
                        }
                        
                      </div>
                        
                    </td>
                    <td style={{padding: '0.2rem', width: '50%'}}>
                      <div style={{backgroundColor: 'white', padding: '1rem', borderRadius: '5px', minHeight: '20.3rem', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                        <text style={{fontWeight: 'bold', color: '#a38cad'}}>Region wise user registrations</text>
                        <hr />
                        {
                          this.state.chartData != "" ?
                          <table style={{width: '100%'}}>
                            <tbody>
                              <tr>
                                <td style={{width: '80%'}}>
                                  <Doughnut
                                    data={this.state.chartData}
                                    legend={{ position: "right", display: false, }}
                                    width={180}
                                    options={this.state.options}
                                  />
                                </td>
                                <td style={{width: '20%', paddingLeft: '1rem'}}>
                                  <table style={{width: 'fit-content'}}>
                                    <tbody>
                                      {
                                        chartCounts.length > 0 ?
                                          chartCounts.map((label, index) => (
                                            <tr>
                                              <td style={{width: '50%'}}><div style={{width: '2.5rem', height: '1.3rem', backgroundColor: chartColors[index], color: 'white', fontSize: '0.8rem', textAlign: 'center'}}>{label.count}</div></td>
                                              <td style={{width: '50%', fontSize: '0.8rem', paddingLeft: '0.5rem'}}>{label.region}</td>
                                            </tr>
                                          ))
                                        : null
                                      }
                                      
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        : null
                        }
                        
                      </div>
                        
                    </td>
                </tr>
                <tr>
                  <td colSpan="2">
                  <div style={{backgroundColor: 'white', padding: '1rem', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                      <text style={{fontWeight: 'bold', color: '#ff9f38'}}>Monthly registrations</text>
                        <hr />
                        <br />
                        <Line data={this.state.dataset} legend={{ display: false }} height={110} />
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />               
          <br />               
          {/* <br /> */}

         <h4 className="statTitleMain">Website Traffic</h4>
        <hr />
        <GoogleAnalyticsStats fromDate={fromDate} toDate={toDate} />               
        <br />
        <br />
        <LearningStats fromDate={fromDate} toDate={toDate} />
        <br />
        <br />
        <SblStats fromDate={fromDate} toDate={toDate} />
        <br />
        <br />
        <ResourceStats fromDate={fromDate} toDate={toDate} />
        <br />
        <br />
        <NewsStats fromDate={fromDate} toDate={toDate} />
        <br />
        <br />
        <GalleryStats fromDate={fromDate} toDate={toDate} />
        <br />
        <br />
        <FeedbackStats fromDate={fromDate} toDate={toDate} />


       

        </div>
        


        
      </div>
    );
  }
}
// export default withRouter(withApollo(Dashboard));
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
