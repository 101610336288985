import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

import translationEN from './languages/en/translation.json';
import translationAR from './languages/ar/translation.json';
import translationFR from './languages/fr/translation.json';
import translationSP from './languages/sp/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  },
  fr: {
    translation: translationFR
  },
  sp: {
    translation: translationSP
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    returnObjects: true,
    value: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome
    joinArrays:'',

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    // returnObjects: true
  });

export default i18n;