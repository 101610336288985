import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import moment from 'moment';

const AccessedUsers = () => {
  const [data, setData] = useState([]);
  // const initialFromDate = moment().subtract(7,'d').format('YYYY-MM-DD');
  // const initialToDate = moment().format('YYYY-MM-DD');
  const [state, setState] = useState({
    fromDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
    toDate:moment().format('YYYY-MM-DD'),
    dataset: {
      labels: [],
      datasets: [
        {
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(33, 138, 191,0.4)",
          borderColor: "rgb(33, 138, 191)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "rgba(33, 138, 191,0.4)",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 3,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [],
        },
      ],
    },
    options: {
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Visits',
            fontSize: 15
          },
          ticks: {
            beginAtZero: true,
        }
        //   gridLines: {
        //     color: "rgba(66, 135, 245, 0)",
        // }
        }],
        xAxes: [{
           gridLines: {
            color: "rgba(66, 135, 245, 0)",
          },
          
        }],
      }     
    }
  });
  console.log(state.fromDate, state.toDate)

  useEffect(() => {
    const queryReport = () => {
      //(1)
      window.gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: "210656968", //enter your view ID here
                dateRanges: [
                  {
                    startDate: '2020-02-01',
                    endDate: 'today',
                  },
                ],
                metrics: [
                  {
                    expression: "ga:users",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:date",
                  },
                ],
              },
            ],
          },
        })
        .then(displayResults, console.error.bind(console));
    };

    const displayResults = (response) => {
      const initialFrom = moment().subtract(7,'d').format();
      const initialTo = moment().format();
      const chartData = state.dataset;
      var labels = [];
      var data = [];
      var temp = [];
      var tempArray = [];
      const queryResult = response.result.reports[0].data.rows;
      queryResult.map((row) => {
        const dateString = row.dimensions[0];
        const formattedDate = moment(dateString, 'YYYYMMDD').format();
        temp.push({date: formattedDate, visits: row.metrics[0].values[0]});
        if(formattedDate >= initialFrom) {
          if(formattedDate <= initialTo) {
            labels.push(moment(formattedDate).format('DD-MM-YYYY'));
            data.push(row.metrics[0].values[0]);
          }
        }
      });
      chartData.labels = labels;
      chartData.datasets[0].data = data;
    setState({
      fromDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      dataset: chartData,
      overallDataSet: temp,
    })

    };

    

    queryReport();
  }, []);


  const filterData = (from, to) => {
    const {overallDataSet, dataset} = state;
    var chartData = dataset;
    var data = [];
    var labels = [];
    var tempDate = "";
    var formattedFrom = moment(from, "YYYY-MM-DD").format();
    var formattedTo = moment(to, "YYYY-MM-DD").format();
    var tempArray = Object.values(overallDataSet).filter(obj => obj.date >= formattedFrom && obj.date <= formattedTo);
    if(tempArray.length > 0) {
      tempArray.map(x => (
        data.push(x.visits),
        labels.push(moment(x.date).format('DD-MM-YYYY'))
      ))
    }
    chartData.labels = labels;
    chartData.datasets[0].data = data;
    console.log(chartData)
    setState({
      ...state,
      dataset: chartData
    })

  } 

  const handleDateChange = (e, otherDate) => {
    var temp = '';
    var from = state.fromDate;
    var to = state.toDate;
    console.log(e.target.name);
    console.log(state.fromDate, state.toDate)
   
    if(e.target.name === 'fromDate') {
      from = e.target.value;   
      filterData(from, to);
    }

    else if(e.target.name === 'toDate') {
      to = e.target.value;   
      filterData(from, to);
    }

     setState({
       ...state,
      [e.target.name]: e.target.value
    })
  
  };

console.log(state.fromDate, state.toDate)
  return (
    <div>
      <div style={{float: 'left', width: '100%'}}>
      <h5 style={{textAlign: 'left', marginTop: '1.5rem', color: '#599b9e'}}>SE Hub user visits</h5>
        <table style={{marginTop: '-1rem', marginBottom: '1.5rem'}}>
          <tbody>
            <tr>
              <td style={{width: 'fit-content'}}><div className="uk-form-label" style={{fontSize: '0.9rem'}}> Timeline: </div></td>
              <td style={{width: 'fit-content'}}>
                  <input
                    value={state.fromDate}
                    className="uk-textarea"
                    rows="2"
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    onChange={(e) => handleDateChange(e,)}
                    style={{fontSize: '0.8rem', width: '10rem', marginLeft: '5px'}}></input>
              </td>
              <td style={{width: 'fit-content'}}><div className="uk-form-label" style={{fontSize: '1.1rem'}}>{" "} - {" "}</div></td>

              <td style={{width: 'fit-content'}}>
                  <input
                    value={state.toDate}
                    className="uk-textarea"
                    rows="2"
                    id="toDate"
                    name="toDate"
                    type="date"
                    onChange={(e) => handleDateChange(e)}
                    style={{fontSize: '0.8rem', width: '10rem', marginLeft: '5px'}}></input>
              </td>
              
            </tr>
          </tbody>
        </table>
        
          
        </div>
      <Line data={state.dataset} legend={{ display: false }} options={state.options} />
    </div>
  );
};

export default AccessedUsers;
