import * as React from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

import InfoIcon from "../../../images/info_white.png";

class NoDataFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { langDict } = this.state;
    const { message, fontSize } = this.props;

    return (
      <div className="contentUnavailableMessage" style={styles.messageMainDiv}>
          <div className="messageSubDiv">
            <img src={InfoIcon} className="messageInfoIcon" />
          </div>
          <div className="messageSubDiv">
            <text style={{fontSize: fontSize,}}>{message}</text>
          </div>
      </div>
    );
  }
}

const styles = {
  messageMainDiv: {
    margin: "0 auto",
    marginTop: '2rem'
  },

};

export default withRouter(withApollo(NoDataFound));
