import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { withNamespaces } from 'react-i18next';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

  }

  redirectToLogin = () => {
    this.props.history.push("/")

  }

  render() {
    const { t } = this.props;
    console.log(localStorage.LANG)
    return (
      <div>
        <div
          uk-height-viewport="offset-top: true; offset-bottom: true"
          className="uk-flex uk-flex-middle uk-margin"
          uk-scrollspy="target: > .welc-holder; cls:uk-animation-slide-bottom-small; delay: 200"
        >
          {/* uk-width-1-2@m uk-width-1-2@s class used in below div */} 
          <div className=" uk-margin-auto welc-holder">
            <div style={{display:"block"}} className="uk-margin-medium-top">
              <Form >
                <h4 className="uk-text-bold uk-margin-medium-top"> <div dangerouslySetInnerHTML={{ __html: t("welcome_page_para") }} /> </h4>
                <br />
                {localStorage.LANG=="ar" ? (
                  <button  className="uk-button uk-button-primary uk-align-left" onClick={() => this.redirectToLogin()}> {t('click_here')} </button>
                ):(
                  <button  className="uk-button uk-button-primary uk-align-right" onClick={() => this.redirectToLogin()}> {t('click_here')} </button>
                ) }
              </Form>
              {this.state.loading ? (<div class="spinner"></div>) : ("")}
            </div>
            {/* <br />
            <button className="uk-button uk-button-primary uk-align-right" onClick={() => this.redirectToLogin()}> {t('click_here')} </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(withNamespaces()(Welcome));
