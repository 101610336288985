import React, { useState, useEffect } from "react";
import moment from "moment";

const SessionDurationReport = ({ fromDate, toDate }) => {
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    totalTime: 0,
    totalDays: 0,
  });
  useEffect(() => {
    const queryReport = () => {
      //(1)
      window.gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: "210656968", //enter your view ID here
                dateRanges: [
                  {
                    // startDate: '2020-01-01',
                    startDate: "2daysago",
                    endDate: "today",
                  },
                ],
                metrics: [
                  {
                    expression: "ga:avgSessionDuration",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:date",
                  },
                ],
              },
            ],
          },
        })
        .then(displayResults, console.error.bind(console));
    };

    const formatTime = (str) => {
      //   var seconds = str;
      //   var duration = moment.duration(seconds, "seconds");
      //   var formatted = duration.format("HH:mm:ss");
      //   return formatted;
      var totalSec = Math.round(str);

      var hours = parseInt(totalSec / 3600) % 24;
      var minutes = parseInt(totalSec / 60) % 60;
      var seconds = totalSec % 60;

      var result =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);
      return result;
    };

    const displayResults = (response) => {
      //(2)
      const queryResult = response.result.reports[0].data.rows;
      let totalTime = 0;
      const days = queryResult.length;
      const result = queryResult.map((row) => {
        const dateSting = row.dimensions[0];
        const formattedDate = `${dateSting.substring(0, 4)}
        -${dateSting.substring(4, 6)}-${dateSting.substring(6, 8)}`;
        totalTime = totalTime + Math.round(row.metrics[0].values[0]);
        return {
          date: formattedDate,
          time: row.metrics[0].values[0],
        };
      });
      console.log(days, totalTime);
      setData(result);
      const averageSessionDuration = formatTime(totalTime / days);
      setState({
        ...state,
        totalDays: days,
        totalTime: totalTime,
        averageSessionDuration: averageSessionDuration,
      });
    };
    queryReport();
  }, []);

  

  return (
        <div
          style={{ width: "fit-content", padding: "1rem", margin: '0 auto' }}
        >
          <div>
            <text style={styles.time}>
              {state.averageSessionDuration || "N/A"}
            </text>
          </div>
          <div style={{marginTop: '-1rem', color: 'white'}}>
            <text style={styles.text}>Hours : Minutes : Seconds</text>
          </div>
          <div style={{paddingTop: '0.8rem'}}>
            <text style={styles.text1}>Average Session Duration</text>
          </div>
        </div>
  );
};

export default SessionDurationReport;

const styles = {
  time: {
    fontSize: "3rem",
    color: 'white',
  },
  text: {
    fontSize: "0.85rem",
    fontWeight: '300',  
    color: 'white'
  },
  text1: {
    fontSize: "1rem",
    fontWeight: '300',  
    color: 'black'

  },
};
