import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import moment from "moment";
//queries
import {
  GET_EXAMS,
  GET_EXAM_SUBMISSIONS,
} from "../../../queries/common_queries";
import { async } from "q";
import { UPDATE_EXAM } from "../../../queries/admin_queries";
import NoDataMessage from "../../../components/messages/contentNotFound/NoDataFoundColored";
import Loader from 'react-loader-spinner'

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class ExamSubmissionList extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID === undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE === "MAUSER") {
        this.props.history.push("/");
      }
    }
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");

    this.state = {
      loading: false,
      submissions: [],
      examId: query.get("id"),
      exam: "",
      submissionCount: "",
    };
  }

  componentDidMount() {
    this.setState({
      submissionsLoading: true,
    })
    this.get_all_exam_submissions().then((result) => {
      console.log(result);
      this.setState({
        submissionsLoading: false,
        submissions: result.submissions,
        exam: result.exam,
        submissionCount: result.count,
      });
    });
  }

  viewSubmission(id) {
    this.props.history.push("/admin_exam_submission?id=" + id);
  }

  get_all_exam_submissions = async () => {
    const result = await this.props.client.query({
      query: GET_EXAM_SUBMISSIONS,
      variables: {
        examId: this.state.examId,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getExamSubmissions;
  };

  updateExam = async (id, status) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_EXAM,
      variables: {
        examId: id,
        lang: "en",
        isActive: status,
      },
    });
    console.log(result.data);
    return result.data.updateExam;
  };

  changeStatus = (id, status) => {
    console.log(id, status);
    this.updateExam(id, status).then((result) => {
      console.log(result);
      this.get_all_exams().then((result) => {
        if (result.length > 0) {
          this.setState({
            exams: result,
          });
        }
      });
    });
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/admin_home");
    } else if (path == "KTC") {
      this.props.history.push("/admin_knowledge_testing_center");
    } else {
      return false;
    }
  };

  findReviewer = (commentArray) => {
    console.log(commentArray);
    const reviewer = commentArray.filter(obj => obj.addedBy.userType === "Reviewer");
    console.log(reviewer)
    if (reviewer.length > 0) {
      return "by" + " "+ reviewer[0].addedBy.name;
    }
    else {
      return "";
    }

  };

  render() {
    const { submissions, loading, exam, submissionCount } = this.state;

    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>Home</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("KTC")}>
                Manage SBL
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-list icon-large uk-margin-right"></i>
          <h4 className="uk-margin-remove"> Knowledge Testing Centre </h4>
        </div> */}
        {/* <br /> */}
        {/* <br /><br /> */}
        <div className="uk-flex-inline uk-flex-middle">
          <text className="uk-margin-remove">
            {" "}
            SBL Name: <b style={{color: 'black'}}>{exam ? exam.examName : ""}</b>
          </text>
        </div>
        <div className="uk-flex-inline uk-float-right">
        <text className="uk-margin-remove">
            {" "}
            No of Submissions: <b>{submissionCount ? submissionCount : 0}</b>
          </text>
        </div>
        <br />
        <br />
        {
          this.state.submissionsLoading === true ?
          <div style={{width: 'fit-content', margin: '0 auto', marginTop: '2rem'}}>
            <Loader
                type="ThreeDots"
                color="#00A9E0"
                height={10}
                width={50}
                // timeout={3000} //3 secs
            />
          </div>
            :
<div className="uk-overflow-auto">
          <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead>
              <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                <th>Submitted by</th>
                <th style={{textAlign: 'center'}}>Country</th>
                <th style={{textAlign: 'center'}}>Date</th>
                {/* <th style={{textAlign: 'center'}}>Status</th> */}
                <th style={{textAlign: 'center'}}>Review Status</th>
                <th style={{textAlign: 'center', width: '5.5rem'}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {submissions ? (
                submissions.length > 0 ? (
                  submissions.slice(0).reverse().map((sub, i) => (
                    <tr key={i}>
                      <td>
                        {" "}
                        {sub.user.firstName} {sub.user.lastName}{" "}
                      </td>
                      <td style={{textAlign: 'center'}}> {sub.user.country} </td>
                      <td style={{textAlign: 'center'}}> {moment(sub.createdAt).format("DD-MMM-YYYY")} </td>
                      {/* <td>
                        {" "}
                        {sub.isActive ? (
                          <p style={{ color: "green" }}>ACTIVE</p>
                        ) : (
                          <p style={{ color: "red" }}>INACTIVE</p>
                        )}{" "}
                      </td> */}
                      <td style={{textAlign: 'center'}}>
                        {sub.comments ? (
                          sub.comments.comments.length > 0 ? (
                            <p style={{ color: "grey" }}><span style={{ color: "green" }}>Reviewed</span> {this.findReviewer(sub.comments.comments)} </p>
                          ) : (
                            <p style={{ color: "red" }}>Pending</p>
                          )
                        ) : (
                          <p style={{ color: "red" }}>Pending</p>
                        )}
                      </td>
                      <td>
                        {sub.comments ? (
                          sub.comments.comments.length > 0 ? (
                            <button
                              onClick={() => this.viewSubmission(sub.id)}
                              className="uk-button admin-table-btn editReviewButtonExamSub"
                              uk-tooltip="title: Edit Review; delay: 500; pos: top; animation: uk-animation-scale-up"
                            >
                              Edit Review
                            </button>
                          ) : (
                            <button
                              onClick={() => this.viewSubmission(sub.id)}
                              className="uk-button admin-table-btn reviewButtonExamSub"
                              uk-tooltip="title: Review; delay: 500; pos: top; animation: uk-animation-scale-up"
                            >
                              Review
                            </button>
                          )
                        ) : (
                          <button
                            onClick={() => this.viewSubmission(sub.id)}
                            className="uk-button admin-table-btn reviewButtonExamSub"
                            uk-tooltip="title: Review; delay: 500; pos: top; animation: uk-animation-scale-up"
                          >
                            Review
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  null
                )
              ) : (
                null
              )}
            </tbody>
          </table>
          {!submissions.length > 0 ? (
            <div style={{width: 'fit-content', margin: '0 auto'}}>
                <NoDataMessage message="There are no submissions for this SBL yet" />
            </div>
          ) : null}
        </div>
        }
        
      </div>
    );
  }
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(ExamSubmissionList))
);
