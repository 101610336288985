import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  setFormStatus,
  setSuccessStatus,
  setBlurrModalStatus,
} from "../../../actions/user-actions";
import moment from "moment";
//queries
import { GET_EXAMS } from "../../../queries/common_queries";
import { async } from "q";
import { GET_ALL_EXAM_SUBMISSIONS, UPDATE_EXAM } from "../../../queries/admin_queries";
import BlurrScreen from "../../../components/modals/blurrScreen";
// import SblDeleteModal from "../../../components/modals/sblDeleteModal";
import { Button } from "react-uikit";
import ConfirmationModal from "../../../components/adminModals/confirmationModal";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    blurrModalOpen: state.custom_reducer.blurrModalOpen,
    blurrModalOpenId: state.custom_reducer.blurrModalOpenId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
    setBlurModalStatus: (status) => {
      dispatch(setBlurrModalStatus(status));
    },
  };
};

class ExamList extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID === undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE === "MAUSER") {
        this.props.history.push("/");
      }
    }
    this.state = {
      loading: false,
      exams: [],
      blurrModal: { status: false, id: "" },
    };
    props.setBlurModalStatus({ status: false, category: "" });
  }

  componentDidMount() {
    var temp = [];
    var submissionCounts = [];
    this.get_all_exams().then((result) => {
      if (result.length > 0) {
        this.get_all_exam_submissions().then((result1) => {
          if (result1.submissions.length > 0) {
            result.slice(0).reverse().map(exam => (
              temp = Object.values(result1.submissions).filter(obj => obj.examId === exam.id),
              submissionCounts.push(temp.length)
            ))   
            this.setState({
              exams: result,
              submissionCounts: submissionCounts
            });         
          }
          else {
            result.slice(0).reverse().map(exam => (
              submissionCounts.push(0)
            ))   
            this.setState({
              exams: result,
              submissionCounts: submissionCounts
            });   
          }
        });
        
      }
    });
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  addNewExam() {
    this.props.history.push("/admin_add_exam");
  }

  editExam(id) {
    this.props.history.push("/admin_add_exam?id=" + id);
  }

  viewSubmissions(id) {
    this.props.history.push("/admin_exam_submissions?id=" + id);
  }

  get_all_exams = async () => {
    const result = await this.props.client.query({
      query: GET_EXAMS,
      variables: {
        lang: "en",
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getExams.exams;
  };

  get_all_exam_submissions = async () => {
    const result = await this.props.client.query({
      query: GET_ALL_EXAM_SUBMISSIONS,
      variables: {},
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getAllExamSubmissions;
  };


  updateExam = async (id, status) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_EXAM,
      variables: {
        examId: id,
        lang: "en",
        isActive: status,
      },
    });
    console.log(result.data);
    return result.data.updateExam;
  };

  changeStatus = (id, status) => {
    this.updateExam(id, status).then((result) => {
      console.log(result);
      this.get_all_exams().then((result) => {
        if (result.length > 0) {
          this.setState({
            exams: result,
          });
        }
        this.props.setBlurModalStatus({ status: false, category: '' });
        this.setState({
          confirmationModal: false,
          confirmationModal2: false,
        })
      });
    });
  };

  deactivateModal = (id) => {
    // this.setState({ blurrModal: {status: true, Id:id},  });
    this.props.setBlurModalStatus({ status: true, category: id });
  };

  closeModal = () => {
    this.props.setBlurModalStatus({ status: false, category: '' });
  };

  render() {
    const { exams, loading } = this.state;
    const { blurrModalOpen, blurrModalOpenId } = this.props;
    console.log(blurrModalOpen,);
    return (
      <div className="admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
          <i className="far fa-newspaper uk-margin-right" style={{fontSize: '2.5rem'}}></i>
          <h4 className="uk-margin-remove adminSectionTitles"> Manage SBL </h4>
        </div>
        <div className="uk-flex-inline uk-float-right">
          <button
            className="uk-button uk-button-primary admin-btn"
            onClick={() => this.addNewExam()}
          >
            Add SBL
          </button>
        </div>
        <div
          style={{ backgroundColor: "#edeff0", paddingTop: "1rem" }}
          className="uk-background-default uk-margin-top"
        >
          <div className="uk-grid"></div>
        </div>

        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead>
              <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                <th>Title</th>
                {/* <th>Added by</th> */}
                <th>Date added</th>
                <th style={{ textAlign: "center" }}>Questions</th>
                <th style={{ textAlign: "center" }}>Submissions</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th colSpan='3' style={{textAlign: 'center'}}>Actions</th>
              
              </tr>
            </thead>
            <tbody>
              {exams
                ? exams.length > 0
                  ? exams
                  .slice(0)
                  .reverse().map((exam, i) => (
                        <tr key={i}>
                          <td style={{ width: "20%" }}> {exam.examName} </td>
                          {/* <td> {exam.createdByUserName} </td> */}
                          <td>
                            {" "}
                            {moment(exam.createdAt).format("DD-MM-YYYY")}{" "}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {exam.questions.questions.length}{" "}
                          </td>
                          <td style={{textAlign: 'center'}}>
                            {this.state.submissionCounts[i]}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {exam.isActive ? (
                              <p style={{ color: "green" }}>ACTIVE</p>
                            ) : (
                              <p style={{ color: "red" }}>INACTIVE</p>
                            )}{" "}
                          </td>
                          
                          <td style={{ textAlign: "center" }}>
                            <button
                              disabled={loading ? true : false}
                              className="uk-button uk-button-warning admin-table-btn"
                              uk-tooltip="Edit"
                              onClick={() => this.editExam(exam.id)}
                            >
                              <i class="fas fa-pencil-alt"></i>
                            </button>
                          </td>
                          <td style={{ width: "fit-content", padding: '0rem' }}>
                            <button
                              onClick={() => this.viewSubmissions(exam.id)}
                              className="uk-button uk-button-primary admin-table-btn blueButton"
                              uk-tooltip="title: View Submissions; delay: 500; pos: top; animation: uk-animation-scale-up"
                            >
                              Submissions
                            </button>
                          </td>
                          <td style={{width: 'fit-content', padding: '0rem'}}>
                            {exam.isActive ? (
                              <button
                                onClick={() => 
                                  // this.deactivateModal(exam.id)
                                  this.setState({confirmationModal: true, clickedSblId: exam.id})
                                }
                                className="uk-button uk-button-danger admin-table-btn tableButtonAnnouncement"
                                uk-tooltip="title: Deactivate SBL; delay: 500; pos: top; animation: uk-animation-scale-up"
                              >
                                Deactivate
                              </button>
                            ) : (
                              <button
                                onClick={() => 
                                  // this.changeStatus(exam.id, true)
                                  this.setState({confirmationModal2: true, clickedSblId: exam.id})
                                }
                                className="uk-button uk-button-success admin-table-btn button_Success tableButtonAnnouncement"
                                uk-tooltip="title: Activate SBL; delay: 500; pos: top; animation: uk-animation-scale-up"
                              >
                                Activate
                              </button>
                            )}
                            {blurrModalOpen.status === true ? (
                              <div style={styles.background}>
                                <div style={styles.background1}>
                                  Are you sure you want to deactivate this SBL?
                                  <br />
                                  <br />
                                  <Button
                                    className="uk-button submitFeedbackButton1"
                                    type="button"
                                    style={styles.confirmButton}
                                    // //   disabled={rating === ""}
                                    onClick={() =>this.changeStatus(blurrModalOpen.category,false)}
                                  >
                                    Confirm
                                  </Button>
                                  <Button
                                    className="uk-button uk-button-primary submitFeedbackButton"
                                    type="button"
                                    style={styles.cancelButton}
                                    // disabled={rating === ""}
                                    onClick={() => this.closeModal()}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            ) : null}
                            
                          </td>
                        </tr>
                      ))
                  : null
                : null}
            </tbody>
          </table>
        </div>
        {
                              this.state.confirmationModal === true ?
                                <ConfirmationModal message="Are you sure you want to deactivate this SBL?" confirmDelete={() => this.changeStatus(this.state.clickedSblId,false)} closeModal={() => this.setState({confirmationModal: false})} />
                              : null
                            }
                            {
                              this.state.confirmationModal2 === true ?
                                <ConfirmationModal message="Are you sure you want to activate this SBL?" confirmDelete={() => this.changeStatus(this.state.clickedSblId,true)} closeModal={() => this.setState({confirmationModal2: false})} />
                              : null
                            }
      </div>
    );
  }
}


export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(ExamList))
);

const styles = {
  background: {
    backgroundColor: "#0000005e",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  background1: {
    backgroundColor: "white",
    position: "absolute",
    // top: '0',
    right: "0",
    width: "40%",
    padding: "1rem",
    zindex: "100",
    marginRight: "20%",
    marginTop: "15%",
    borderRadius: "5px",
    textAlign: "center",
  },

  cancelButton: {
    width: "7rem",
    float: "right",
    marginRight: "0.8rem",
  },

  confirmButton: {
    width: "7rem",
    float: "right",
  },
};
