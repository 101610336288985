import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus } from '../../../actions/user-actions';
import LogoEncyte from '../../../assets/images/logo.png'

import logo1 from '../../../images/1_SE_IPPF_logo_1.png';
import logo2 from '../../../images/2_SE_IPPF_logo_old_edited_color.png';

//ui-kit components

import { Button } from 'react-uikit';

//Spinner
import Loader from 'react-loader-spinner'

//queries
import { LOGIN_QUERY } from '../../../queries/user_queries';
import { Image } from 'semantic-ui-react';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        }
    }
}

class Admin_login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: {
                form_state: 'default',
                title: '',
                message: '',
            },
            email: "",
            password: "",
            loading: false
        };
        props.setFormStatus({ status: false, title: '', message: '' });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        this.props.setFormStatus({ status: false, title: '', message: '' });
        const { email, password } = this.state;

        if (!email == '' || !password == '') {
            this.loginUser().then(result => {
                console.log(result)

                if (result.data.login.user.isActive) {
                    if (result.data.login.user.userType == "MAUSER") {
                        this.props.setFormStatus({ status: true, title: 'Oops!', message: "Authentication failed!" });
                        this.setState({ email: "", password: "", loading: false });
                        return;
                    } else {
                        localStorage.ippfauthtoken = result.data.login.token;
                        localStorage.USER_ID = result.data.login.user.id;
                        localStorage.EMAIL = result.data.login.user.email;
                        localStorage.FIRST_NAME = result.data.login.user.firstName;
                        localStorage.LAST_NAME = result.data.login.user.lastName;
                        localStorage.USER_TYPE = result.data.login.user.userType;
                        // localStorage.LANG = "en"
                        console.log(localStorage.LANG);

                        this.setState({ loading: false });
                        this.props.history.push("/admin_home");
                    }

                } else {
                    this.props.setFormStatus({ status: true, title: 'Oops!', message: <div>
                                                                                        Your account is not approved yet! 
                                                                                        <br/>
                                                                                        You will be notified via email when your account is approved.
                                                                                      </div> });
                    this.setState({ email: "", password: "", loading: false });
                    return;
                }

            })
                .catch(error => {
                    if (error) {
                        console.log(error);
                        this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Your Email or Password appears to be wrong!' });
                    }
                    this.setState({ loading: false });

                });

        } else {
            this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Please make sure you have filled out all required fields!' });
            this.setState({ email: "", password: "", loading: false });
            return;
        }
    }

    loginUser = async () => {
        const { email, password } = this.state;
        const result = this.props.client.mutate(
            {
                mutation: LOGIN_QUERY,
                variables: { email, password },
            })
        return result;
    }

    render() {
        const { loading } = this.state;
        const { formErrorStatus } = this.props;

        console.log(formErrorStatus)

        return (
            <div>
                <div class="tm-header uk-visible@m tm-header-transparent">
                    <div media="768" animation="uk-animation-slide-top" cls-active="uk-navbar-sticky uk-nav-dark" sel-target=".uk-navbar-container" top=".tm-header ~ [class*=&quot;uk-section&quot;], .tm-header ~ * > [class*=&quot;uk-section&quot;]" cls-inactive="uk-navbar-transparent   uk-dark" class="uk-sticky uk-navbar-container">
                        <div class="uk-container  uk-position-relative">
                            <nav class="uk-navbar paddingHeader uk-navbar-transparent uk-nav-padding">

                                <div class="uk-navbar-left">
                                {/* <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo"   src={ require('../../../images/SE_IPPF_logo.png') } fluid /> */}

                                <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo"   
                                // src={ require('../../../images/1_SE_IPPF_logo_1.png') } 
                                src={logo1}
                                fluid />
                                <Image float="right" className="ui fluid image uk-display-inline-block ippf-logo adm-logo" 
                                // src={ require('../../../images/2_SE_IPPF_logo_old_edited_color.png') } 
                                src={logo2}
                                fluid />
                                </div>
                                {/* <div class="lineHeight uk-navbar-right">
                                    <ul class=" uk-navbar-nav toolbar-nav">
                                                                       
                                        <li>
                                            <a href="/faq">FAQs</a>
                                        </li>
                                        <li>
                                            <a href="/about">ABOUT US</a>
                                        </li>
                                        <li>
                                            <a href="/contact">CONTACT US</a>
                                        </li>

                                    </ul>
                                    
                                </div> */}
                            </nav>
                        </div>
                    </div>
                </div>
                <div uk-height-viewport="offset-top: true; offset-bottom: true" className="uk-flex uk-flex-middle">
                    <div className="uk-width-1-3@m uk-width-1-2@s uk-margin-auto">
                        <div className="uk-padding">
                            <br />
                            <form onSubmit={this.handleSubmit}>
                                <div className=" ">
                                    <h2 className="uk-text-bold uk-text-center"> Log in </h2>

                                    <div className="uk-form-label">Official Email Address</div>
                                    {/* <div className="uk-inline"> */}
                                    {/* <span className="uk-form-icon"><i className="far fa-user icon-medium"></i></span> */}
                                    <input className="uk-input uk-width-1-1" id="email" name="email" placeholder="Official Email Address" type="email" onChange={this.handleChange} required />
                                    {/* </div> */}

                                    <div className="uk-form-label">Password</div>
                                    {/* <div className="uk-inline uk-margin-bottom"> */}
                                    {/* <span className="uk-form-icon"><i className="fas fa-key icon-medium"></i></span> */}
                                    <input className="uk-input uk-width-1-1" id="password" name="password" placeholder="Password" type="password" onChange={this.handleChange} required />
                                    {/* </div> */}

                                    <Button
                                        className="uk-button uk-button-success uk-width-1-1 uk-margin"
                                        style={{ height: 40 }}
                                        type="submit"
                                        disabled={loading ? (true) : (false)}
                                    >
                                        {loading ? (
                                            <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                        ) : ("Sign in")}
                                    </Button>
                                    <br />

                                    {formErrorStatus.status == true ? (
                                        <div className="danger_alert">{formErrorStatus.message}</div>
                                    ) : ('')}
{/* 
                                    <p className="uk-text-right">
                                        <button class="uk-button uk-button-text"> Forgot Password </button>
                                    </p> */}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Begin Footer */}
                
                <div  className="fontFamily footerCopyright uk-section-small">
                    <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative" >
                        <div style={ {fontSize:"14px"}} className="uk-grid uk-margin-small"  uk-grid="true">
                            <div className="copyRightText uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                                <p> Copyright (C) 2020 - <span className="uk-text-bold">  IPPF Social Enterprise Hub</span>. All rights reserved. </p>
                            </div>
                                <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                                    <p>Version 1 . 3 . 0</p>
                                    {/* <p>Version 1 . 1 . 0</p> */}

                                </div>
                            <div className="image uk-width-expand@m uk-width-1-2@s">
                                <p style={{textAlign:"right"}}> Powered By <a href="https://encyte.io" target="_blank"> <img src={LogoEncyte} /> </a></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Footer */}
            </div>
        );
    }

}
const styles = {
    darkBack: {
        backgroundColor: 'rgba(0, 0, 0, 0.52)',
        backgroundBlendMode: 'color-burn',
    },
    poweredBy: {
        bottom: '8px',
        right: '60px',

    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Admin_login))); 